// eslint-disable-next-line import/no-named-as-default
import clsx from 'clsx';
import gql from 'graphql-tag';
import React, { useState, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';

import {
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  GetTalentAdminAndTalentsQueryVariables,
  CurrentUser,
  GetOrganizationQueryVariables,
  GetKeychainTalentQuery,
  GetKeychainTalentQueryVariables,
  GetUserAudienceExpirationDateQuery,
  GetUserAudienceExpirationDateQueryVariables,
  GetTalentAdminAndTalentsQuery,
} from '../../API';
import {
  getCurrentUser as getCurrentUserQuery,
  getKeychainTalent,
  getOrganization,
  getTalentAdminAndTalents,
  getUserAudienceExpirationDate,
} from '../../graphql/queries';
import { Organization, PlatformUserType } from '../../interfaces/props';
import { useAuthValue } from '../../services/Auth/Auth';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

import AppVersionBanner from './AppVersionBanner';
import styles from './Navbar.module.scss';
import Navigator from './Navigator';
import Notifications from './Notifications';
import ProfilePreview from './ProfilePreview';
import UserSettings from './UserSettings';
import { WithApolloClient } from '@apollo/client/react/hoc';
import { userIsAdmin, userIsSales } from '../../utils/hooks';

interface NavbarProps extends WithApolloClient<RouteComponentProps> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentUser: CurrentUser | null;
  isSignedIn: boolean;
}

const Navbar: FC<NavbarProps> = ({
  currentUser,
  history,
  isSignedIn,
  client,
}): JSX.Element => {
  const { t } = useTranslation();
  const { dbUser, setCurrentUser } = useAuthValue();
  const isKeyAdmin = userIsAdmin();
  const isSales = userIsSales();

  const loggedInUser = dbUser
    ? {
      UserID: dbUser.UserID,
      OrganizationID: dbUser.OrganizationID,
      IsTalentAdmin: dbUser.UserIsAdmin,
      IsManager: dbUser.UserIsManager,
      IsKeyAdmin: isKeyAdmin,
      IsTalent: dbUser.UserIsTalent,
      UserName: `${dbUser.UserFirstName} ${dbUser.UserLastName}`,
      IsTalentAdminOrManager: dbUser.UserIsAdmin || dbUser.UserIsManager,
      IsSales: isSales,
    }
    : null;
  const [organizationName, setOrganizationName] = useState<
    string | undefined
  >();

  // from URL parameter
  const [talentID, setTalentID] = useState<number | undefined>(undefined);

  const [showLogoutConfirm, setShowLogoutConfirm] = useState<boolean>(false);

  const location = useLocation();

  const params = new URLSearchParams(window.location.search);
  const talentIDParam = Number(params.get('talentID'));
  const [hasKeychain, setHasKeychain] = useState<boolean>(false);
  const [audienceExpirationDate, setAudienceExpirationDate] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getUserAudienceExpirationDateField = async (
    id: number,
  ): Promise<void> => {
    try {
      if(client) {
        const { data } = await client.query<
        GetUserAudienceExpirationDateQuery,
        GetUserAudienceExpirationDateQueryVariables
      >({
        query: gql(getUserAudienceExpirationDate),
        fetchPolicy: 'network-only',
        variables: {
          UserID: id,
        },
      });

      if (data) {
        setAudienceExpirationDate(
          data.getUserAudienceExpirationDate?.AudienceExpirationDate,
        );

      }

      setIsLoading(false);
      }
    } catch (error) {
      window.Rollbar.error('Failed to get User First Name and Last Name', {
        error,
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (client) {
          const { data, error } = await client.query<
          GetKeychainTalentQuery,
          GetKeychainTalentQueryVariables
        >(
          {
          query: gql(getKeychainTalent),
          fetchPolicy: 'network-only',
          variables: {
            TalentID: talentID,
          },
        });

        if (error) {
          window.Rollbar.error('GetKeychainTalentQuery', {
            data,
            error,
          });
        }

        if (data?.getKeychainTalent) {
          setHasKeychain(true);
        } else {
          setHasKeychain(false);
        }

        if (talentIDParam) {
          getUserAudienceExpirationDateField(talentIDParam);
        }
        }
        
      } catch (err) {
        console.error('Current User not Found.', err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentID, client]);

  useEffect((): void => {
    if (isSignedIn && !currentUser && client) {
      (async () => {
        try {
          const { data } = await client.query<
            GetCurrentUserQuery,
            GetCurrentUserQueryVariables
          >(
          { query: gql(getCurrentUserQuery),
            fetchPolicy: 'network-only'
          });

          if (data) {
            const { getCurrentUser } = data;

            if (!dbUser || dbUser !== getCurrentUser) {
              setCurrentUser(getCurrentUser);
            }

            if (!talentIDParam) {
              getUserAudienceExpirationDateField(getCurrentUser.UserID);
            }

          }
          
        } catch (error) {
          window.Rollbar.error('Failed to load current user from NavBar', {
            error,
          });
        }
      })();
    }
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!talentIDParam) {
      setTalentID(undefined);

      return;
    }

    if (loggedInUser && loggedInUser.IsTalentAdminOrManager) {
      (async () => {
        if (client) {
          const { data } = await client.query<
          GetTalentAdminAndTalentsQuery,
          GetTalentAdminAndTalentsQueryVariables
        >(
          {
          query: gql(getTalentAdminAndTalents),
          fetchPolicy: 'network-only',
          variables: {
            OrganizationID: loggedInUser.OrganizationID,
          },
        });

        if (data) {
          const talentAdminAndTalents = data?.getTalentAdminAndTalents?.map(user => {
            return {
              UserID: user?.UserID || null,
              OrganizationID: user?.OrganizationID || null,
            }
          }) || [];

          const isPartOfOrganization = talentAdminAndTalents.find(
            user =>
              user.UserID === talentIDParam &&
              user.OrganizationID === loggedInUser.OrganizationID,
          );
  
          if (isPartOfOrganization) {
            setTalentID(talentIDParam);
          }

        }
        }
        
      })();
    }
  }, [loggedInUser, talentIDParam, client]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect((): void => {
    if (loggedInUser && loggedInUser.OrganizationID) {
      (async () => {
        try {
          if (client) {
            const { data, error } = await client.query<
            { getOrganization: Organization },
            GetOrganizationQueryVariables
          >({
            query: gql(getOrganization),
            fetchPolicy: 'network-only',
            variables: {
              OrganizationID: loggedInUser.OrganizationID as number,
            },
          });

          if (error || !data) {
            window.Rollbar.error('From GetOrganizationQuery', {
              error,
              data,
            });
          }

          if (data) {
            const { OrganizationName } = data.getOrganization;
            setOrganizationName(OrganizationName);

          }
            
          }
          
        } catch (error) {
          window.Rollbar.error('From GetOrganizationQuery', {
            error,
          });
        }
      })();
    }
  }, [loggedInUser, client]); // eslint-disable-line react-hooks/exhaustive-deps

  const shouldHideNavbar =
    location.pathname.includes('keychain/profile') ||
    location.pathname.includes('login') ||
    location.pathname.includes('capturelink/');

  const shouldShowNotificationsButton =
    !!loggedInUser &&
    (loggedInUser.IsTalentAdminOrManager || loggedInUser?.IsTalent);

  const shouldShowUserName =
    loggedInUser &&
    (loggedInUser.IsKeyAdmin || loggedInUser.IsTalentAdminOrManager || loggedInUser.IsSales);

  const shouldShowUserSettingsButton =
    loggedInUser &&
    (loggedInUser.IsKeyAdmin || loggedInUser.IsTalentAdminOrManager || loggedInUser.IsSales);

  const shouldShowLogoutButton = loggedInUser && loggedInUser.IsTalent;

  const shouldShowProfilePreview = loggedInUser && !!talentID;

  const shouldShowNavigator =
    (loggedInUser && loggedInUser.IsTalent) || !!talentID;

  if (shouldHideNavbar) {
    return <></>;
  }

  if (!loggedInUser) {
    return (
      <div className={styles.container}>
        <div className={styles.appBar}>
          <div className={styles.leftSide}>
            <img
              alt={t('alt-text.key-logo')}
              src="/assets/images/logo_white.svg"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <AppVersionBanner />
      <div className={styles.content}>
        <div className={styles.appBar}>
          <div className={styles.leftSide}>
            <img
              alt={t('alt-text.key-logo')}
              src="/assets/images/logo_white.svg"
              onClick={() => history.push('/')}
              className={styles.logo}
            />
            <div
              className={clsx(
                styles.navbarText,
                styles.organizationName,
                styles.organizationNameDisplayBanner,
              )}
            >
              {organizationName ? organizationName : ''}
            </div>
          </div>
          <div className={styles.rightSide}>
            {shouldShowNotificationsButton && client && (
              <Notifications
                history={history}
                userID={loggedInUser.UserID}
                client={client}
              />
            )}
            {shouldShowUserName && (
              <div className={styles.navbarText}>
                {loggedInUser ? `${loggedInUser.UserName}` : ''}
              </div>
            )}
            {shouldShowUserSettingsButton && (
              <UserSettings
                history={history}
                userType={
                  loggedInUser.IsKeyAdmin
                    ? PlatformUserType.KEY_ADMIN
                    : loggedInUser.IsTalentAdmin
                      ? PlatformUserType.TALENT_ADMIN
                      : loggedInUser.IsManager
                        ? PlatformUserType.MANAGER
                        : loggedInUser.IsTalent
                          ? PlatformUserType.TALENT
                          : loggedInUser.IsSales
                            ? PlatformUserType.SALES
                            : null
                }
                setShowLogoutConfirmationDialog={() =>
                  setShowLogoutConfirm(true)
                }
              />
            )}
            {shouldShowLogoutButton && (
              <button
                className={styles.logoutButton}
                onClick={() => setShowLogoutConfirm(true)}
              >
                <img
                  src="/assets/images/exit_to_app_light.svg"
                  alt={t('alt-text.logout')}
                />
                <span>{t('navigation.logout')}</span>
              </button>
            )}
          </div>
        </div>
        {shouldShowProfilePreview && (
          <ProfilePreview
            talentID={Number(talentID)}
            history={history}
            client={client}
          />
        )}
        {isLoading ? (
          <></>
        ) : (
          shouldShowNavigator &&
          !audienceExpirationDate && (
            <Navigator
              talentID={Number(talentID || loggedInUser?.UserID)}
              loggedInUserIsTalent={loggedInUser?.IsTalent}
              history={history}
              hasKeychain={hasKeychain}
            />
          )
        )}
      </div>
      <ConfirmationDialog
        confirmText={t('confirmation-dialog.ok')}
        data={{
          title: t('navigation.logout'),
          content: t('navigation.logout-confirm'),
        }}
        isOpen={showLogoutConfirm}
        onClose={(): void => setShowLogoutConfirm(false)}
        onConfirm={(): void => {
          setShowLogoutConfirm(false);
          history.push('/logout');
        }}
      />
    </div>
  );
};

export default withRouter(Navbar);
