import { ReactText } from 'react';
import { toast, ToastOptions } from 'react-toastify';

export const displayToastMessage = (
  messageContent: string,
  opts: ToastOptions = {
    autoClose: 5000,
    position: toast.POSITION.TOP_RIGHT,
    type: 'success',
    progressClassName: 'key-progress-bar',
  },
  overwriteID?: false | string,
): ReactText | null => {
  if (!messageContent) return null;

  if (overwriteID !== false) {
    const toastId = overwriteID || messageContent;

    if (toast.isActive(toastId)) {
      return null;
    }

    return toast(messageContent, { ...opts, toastId });
  }

  return toast(messageContent, opts);
};

export const displayGQLMessages = (
  messageContents: { message: string }[],
  opts: ToastOptions = {
    autoClose: 5000,
    position: toast.POSITION.TOP_CENTER,
    type: 'success',
  },
): ReactText[] | null => {
  if (!messageContents || messageContents.length === 0) return null;

  return messageContents.map(
    (messageContent): ReactText => toast(messageContent.message, opts),
  );
};

export const isPushApiSupported = 'PushManager' in window;
