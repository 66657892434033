
// eslint-disable-next-line import/named
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { SignUpOutput, confirmSignUp, signIn, signOut, updatePassword, getCurrentUser, resendSignUpCode, signUp, fetchAuthSession, GetCurrentUserOutput, JWT, AuthUser, autoSignIn, fetchUserAttributes } from 'aws-amplify/auth';
import { } from 'aws-amplify/api';
import React, { FC, useEffect, useReducer, useContext } from 'react';

import { CurrentUser } from '../../API';
import { IVerifyArgs } from '../../interfaces/auth';
import { IAuthContext } from '../../interfaces/context';
import { IAuthContextState } from '../../interfaces/state';
import { UserPersonas } from '../../types';
import { SignUpFormValues } from '../../ui/EmailSignIn/EmailSignIn';
import { getBaseApiUrl } from '../../utils';

interface AuthSession { // TODO - attempted to import "AuthSession" type from aws-amplify/auth but no such type was found, created own here
  tokens?: {
    idToken?: JWT;
    accessToken?: JWT;
  },
  credentials?: {
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken?: string;
    expiration?: Date;
  }
  identityId?: string;
  userSub?: string;
}
export interface CognitoUser extends AuthSession, GetCurrentUserOutput {

};

export const AuthContext = React.createContext<IAuthContext>({
  isSignedIn: false,
  isSigningIn: false,
  finishSignUp: false,
  loading: true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loggingIn: (_isLoggingIn: boolean): Promise<void> => Promise.resolve(),
  login: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _session?: CognitoUserSession,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _email?: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _password?: string,
  ): Promise<string> => Promise.resolve(''),
  logout: (): Promise<void> => Promise.resolve(),
  register: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _creds: SignUpFormValues,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _persona: UserPersonas,
  ): Promise<SignUpOutput | number> =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Promise.resolve({} as any),
  user: null,
  dbUser: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setCurrentUser: (_user: CurrentUser): Promise<void> => Promise.resolve(),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  confirmSignUp: (_verification: IVerifyArgs): Promise<void> =>
    Promise.resolve(),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resendVerificationCode: (_email: string): Promise<void> => Promise.resolve(),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFinishSignUp: (_finishSignUp: boolean): Promise<void> => Promise.resolve(),
});

enum AuthActionType {
  AUTH_SUCCESS = 'AUTH_SUCCESS',
  AUTH_ERROR = 'AUTH_ERROR',
  CURRENT_USER = 'CURRENT_USER',
  FINISH_SIGNUP = 'FINISH_SIGNUP',
  LOGGING_IN = 'LOGGING_IN',
  UNAUTH_SUCCESS = 'UNAUTH_SUCCESS',
}

interface IAuthSucceed {
  type: AuthActionType.AUTH_SUCCESS;
  payload: CognitoUser;
}

interface IAuthFailure {
  type: AuthActionType.AUTH_ERROR | AuthActionType.UNAUTH_SUCCESS;
}

interface IAuthPending {
  type: AuthActionType.LOGGING_IN;
  payload: boolean;
}

interface ICurrentUser {
  type: AuthActionType.CURRENT_USER;
  payload: CurrentUser;
}

interface IAuthFinishSignUp {
  type: AuthActionType.FINISH_SIGNUP;
  payload: boolean;
}

type AuthActions =
  | IAuthFailure
  | IAuthSucceed
  | IAuthPending
  | ICurrentUser
  | IAuthFinishSignUp;

interface AuthProviderProps {
  children?: React.ReactNode;
}
export const AuthProvider: FC<AuthProviderProps> = ({
  children,
}): JSX.Element => {
  const initialState: IAuthContextState = {
    dbUser: null,
    finishSignUp: false,
    isSignedIn: false,
    isSigningIn: false,
    loading: true,
    user: null,
  };

  const reducer = (
    state: IAuthContextState,
    action: AuthActions,
  ): IAuthContextState => {
    switch (action.type) {
      case AuthActionType.AUTH_ERROR:
        return { ...state, isSignedIn: false, loading: false, user: null };
      case AuthActionType.AUTH_SUCCESS:
        return {
          ...state,
          isSignedIn: true,
          isSigningIn: false,
          loading: false,
          user: action.payload,
        };
      case AuthActionType.LOGGING_IN:
        return { ...state, isSigningIn: action.payload };
      case AuthActionType.UNAUTH_SUCCESS:
        return {
          ...state,
          isSignedIn: false,
          isSigningIn: false,
          loading: false,
          user: null,
          dbUser: null,
        };
      case AuthActionType.CURRENT_USER:
        return {
          ...state,
          dbUser: action.payload,
        };
      case AuthActionType.FINISH_SIGNUP:
        return {
          ...state,
          finishSignUp: action.payload,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect((): void => {
    getCurrentUser()
      .then((user: AuthUser): void => {
        fetchAuthSession().then((session: AuthSession): void => {
          return dispatch({ type: AuthActionType.AUTH_SUCCESS, payload: {...user, ...session} });
        })
        .catch( () => {
          return dispatch({ type: AuthActionType.AUTH_ERROR });
        });
      })
      .catch((message: string): void => {
        if (message !== 'not authenticated')
          return dispatch({ type: AuthActionType.AUTH_ERROR });
      });
  }, []);

  const loggingIn = async (isLogginIn: boolean): Promise<void> => {
    dispatch({ type: AuthActionType.LOGGING_IN, payload: isLogginIn });
  };

  const login = async (
    session?: CognitoUserSession,
    email?: string,
    password?: string,
  ): Promise<string> => {
    const username = session
      ? session.getAccessToken().decodePayload().username
      : email;

    try {
      const result = await signIn({username, password});

      const user = await getCurrentUser();
      const authSession = await fetchAuthSession();

      if (result.isSignedIn) {
        dispatch({ payload: {
          ...user, ...authSession
        }, type: AuthActionType.AUTH_SUCCESS });
      }

      return user.username;
    } catch (err) {
      dispatch({ type: AuthActionType.AUTH_ERROR });
      throw err;
    }
  };

  const logout = (): Promise<void> =>
    signOut().then((): void =>
      dispatch({ type: AuthActionType.UNAUTH_SUCCESS }),
    );

  const register = async (
    {
      email,
      firstName,
      instagramHandle,
      lastName,
      password,
      zipCode,
    }: SignUpFormValues,
    persona: UserPersonas,
    isKeychainSignUp = false,
    OrganizationID,
  ): Promise<SignUpOutput | number> => {
    let signUpResult: SignUpOutput;
    let encodedParams = '';

    if (email !== '') {
        signUpResult = await signUp({
          username: email,
          password,
          options: {
            userAttributes : {
              email,
              name: `${firstName} ${lastName}`,
            },
          },
        });
  
        if (signUpResult.userId) {
          const res = await fetch(`${getBaseApiUrl()}/auth/confirm-user?userName=${signUpResult.userId}`, {
            method: 'POST',
            referrerPolicy: 'unsafe-url',
          });
          
          const result = await res.json();
          if (result && result.message === 'User confirmed') {
              encodedParams = `?sub=${
                signUpResult.userId // TODO - check if correct user sub
              }&userType=${persona}&firstName=${encodeURIComponent(
                firstName || '',
              )}&lastName=${encodeURIComponent(
                lastName || '',
              )}&email=${encodeURIComponent(email)}&zipCode=${zipCode ||
                ''}&instagram=${encodeURIComponent(
                instagramHandle || '',
              )}'&isKeychainSignUp=${isKeychainSignUp}'&OrganizationID=${OrganizationID}`;
        
              await fetch(`${getBaseApiUrl()}/auth/register-user${encodedParams}`, {
                method: 'GET',
                referrerPolicy: 'unsafe-url',
              });
        
              return signUpResult;
          }
        }
    }

    encodedParams = `?sub=${''}&userType=${persona}&firstName=${encodeURIComponent(
      firstName || '',
    )}&lastName=${encodeURIComponent(
      lastName || '',
    )}&email=${''}&zipCode=${zipCode || ''}&instagram=${encodeURIComponent(
      instagramHandle || '',
    )}'&isKeychainSignUp=${isKeychainSignUp}'&OrganizationID=${OrganizationID}`;

    const res = await fetch(
      `${getBaseApiUrl()}/auth/register-user${encodedParams}`,
      {
        method: 'GET',
        referrerPolicy: 'unsafe-url',
      },
    );

    const data = await res.json();
    return data.userID;
  };

  const confirmSignUpFunc = async ({ email, code }: IVerifyArgs): Promise<void> => {
    await confirmSignUp({
      username: email,
      confirmationCode: code
    });

    return;
  };

  const resendVerificationCode = async (email: string): Promise<void> => {
    try {
      await resendSignUpCode({
        username: email
      });

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const setCurrentUser = async (user: CurrentUser): Promise<void> => {
    dispatch({ type: AuthActionType.CURRENT_USER, payload: user });
  };

  const setFinishSignUp = async (finishSignUp: boolean): Promise<void> => {
    dispatch({ type: AuthActionType.FINISH_SIGNUP, payload: finishSignUp });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        confirmSignUp: confirmSignUpFunc,
        loggingIn,
        login,
        setCurrentUser,
        setFinishSignUp,
        logout,
        register,
        resendVerificationCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
export const useAuthValue = (): IAuthContext => useContext(AuthContext);

// eslint-disable-next-line react/display-name
export const withAuth = <P extends object>(Comp): FC<P> => (
  props,
): JSX.Element => (
  <AuthContext.Consumer>
    {(authContext): JSX.Element => {
      return <Comp {...props} authContext={authContext} />;
    }}
  </AuthContext.Consumer>
);

export const changePassword = async (
  oldPassword,
  newPassword,
): Promise<void> => {

  return updatePassword({
    oldPassword, newPassword
  });
};
