import { createBrowserHistory, Location } from 'history';
import ReactGA from 'react-ga';

import { displayToastMessage } from '../../src/utils/notifications';

const _history = createBrowserHistory();

const displayURLErrorParams = (_: Event | null, location?: Location): void => {
  const params = new URLSearchParams(
    location ? location.search : _history.location.search,
  );
  const error = params.get('error');

  if (error) {
    displayToastMessage(error, { type: 'error' });
  }
};

const reportToGoogleAnalytics = (
  location: Location = _history.location,
): void => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname + location.search);
};

window.onload = (event: Event | null, location?: Location): void => {
  reportToGoogleAnalytics(location);
  displayURLErrorParams(event, location);
};

_history.listen((location: Location): void => {
  reportToGoogleAnalytics(location);
  displayURLErrorParams(null, location);
});

export const history = _history;
