/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const updateUser = /* GraphQL */ `mutation UpdateUser($updateUserInput: UpdateUserInput!) {
  updateUser(updateUserInput: $updateUserInput) {
    UserID
    UserFirstName
    UserLastName
    UserAvatar
    UserBackgroundImage
    UserAdded
    UserIsAdmin
    UserIsManager
    UserIsTalent
    UserAcceptedInvitation
    UserIsVerifiedForEventCreation
    UserIsTeam
    UserEmail
    UserSMS
    UserCognitoUID
    UserBio
    UserCompletedOnboarding
    OrganizationID
    AudienceID
    IsMediaOptIn
    BirthDate
    HasFinishedQuestions
    IsActive
    TemporaryEmail
    TotalTalents
    ProfileCompletion
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const saveCardForUser = /* GraphQL */ `mutation SaveCardForUser($tokenID: String!, $zipCode: String!, $Env: String!) {
  saveCardForUser(tokenID: $tokenID, zipCode: $zipCode, Env: $Env) {
    data {
      account
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SaveCardForUserMutationVariables,
  APITypes.SaveCardForUserMutation
>;
export const deleteCardsForUser = /* GraphQL */ `mutation DeleteCardsForUser {
  deleteCardsForUser
}
` as GeneratedMutation<
  APITypes.DeleteCardsForUserMutationVariables,
  APITypes.DeleteCardsForUserMutation
>;
export const createKeyLink = /* GraphQL */ `mutation CreateKeyLink($createKeyLinkInput: CreateKeyLinkInput!) {
  createKeyLink(createKeyLinkInput: $createKeyLinkInput) {
    KeyLinkID
    TalentID
    KeyLinkType
    KeyLinkName
    KeyLinkDescription
    KeyLinkImageURL
    isHidden
    StartDate
    EndDate
    DisplayRank
    PhoneNumberRequired
    KeyLinkAdded
    KeyLinkUpdated
    KeyLinkDeleted
    UpdatedByUser
    LinkDetailsID
    LinkDetailsDescription
    LinkDetailsURL
    KeyLinkPixel
    CustomConsentHyperlinkLabel
    CustomConsentURL
    CustomConsentCallToAction
    CustomConsentUpdated
    IsDarkMode
    ProfilePageColor
    KeyLinkRegistrationEmail
    URLFriendlyName
    RedirectURL
    TalentName
    BackgroundType
    ProfilePageSecondColor
    FontID
    FontColor
    BackgroundImageURL
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateKeyLinkMutationVariables,
  APITypes.CreateKeyLinkMutation
>;
export const createLinkDetails = /* GraphQL */ `mutation CreateLinkDetails($createLinkDetailsInput: CreateLinkDetailsInput) {
  createLinkDetails(createLinkDetailsInput: $createLinkDetailsInput) {
    LinkDetailsID
    KeyLinkID
    LinkDetailsDescription
    LinkDetailsURL
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLinkDetailsMutationVariables,
  APITypes.CreateLinkDetailsMutation
>;
export const createSocialMedia = /* GraphQL */ `mutation CreateSocialMedia($createSocialMediaInput: CreateSocialMediaInput!) {
  createSocialMedia(createSocialMediaInput: $createSocialMediaInput) {
    KeychainSocialMediaID
    UserID
    SocialMediaType
    SocialMediaUserID
    DisplayRank
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSocialMediaMutationVariables,
  APITypes.CreateSocialMediaMutation
>;
export const updateKeyCaptureLink = /* GraphQL */ `mutation UpdateKeyCaptureLink(
  $updateKeyCaptureLinkInput: UpdateKeyCaptureLinkInput!
) {
  updateKeyCaptureLink(updateKeyCaptureLinkInput: $updateKeyCaptureLinkInput) {
    KeyCaptureLinkID
    UserID
    CaptureLinkURL
    CaptureLinkCustomDomain
    RedirectURL
    RebrandlyLinkID
    AudienceID
    CapturePagePixel
    KeyCaptureLinkAdded
    KeyCaptureLinkAddedBy
    KeyCaptureLinkDeleted
    KeyCaptureLinkDeletedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateKeyCaptureLinkMutationVariables,
  APITypes.UpdateKeyCaptureLinkMutation
>;
export const updateKeyLink = /* GraphQL */ `mutation UpdateKeyLink($updateKeyLinkInput: UpdateKeyLinkInput!) {
  updateKeyLink(updateKeyLinkInput: $updateKeyLinkInput) {
    KeyLinkID
    TalentID
    KeyLinkType
    KeyLinkName
    KeyLinkDescription
    KeyLinkImageURL
    isHidden
    StartDate
    EndDate
    DisplayRank
    PhoneNumberRequired
    KeyLinkAdded
    KeyLinkUpdated
    KeyLinkDeleted
    UpdatedByUser
    LinkDetailsID
    LinkDetailsDescription
    LinkDetailsURL
    KeyLinkPixel
    CustomConsentHyperlinkLabel
    CustomConsentURL
    CustomConsentCallToAction
    CustomConsentUpdated
    IsDarkMode
    ProfilePageColor
    KeyLinkRegistrationEmail
    URLFriendlyName
    RedirectURL
    TalentName
    BackgroundType
    ProfilePageSecondColor
    FontID
    FontColor
    BackgroundImageURL
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateKeyLinkMutationVariables,
  APITypes.UpdateKeyLinkMutation
>;
export const updateKeyLinkIsHiddenStatus = /* GraphQL */ `mutation UpdateKeyLinkIsHiddenStatus(
  $updateKeyLinkIsHiddenInput: UpdateKeyLinkIsHiddenInput!
) {
  updateKeyLinkIsHiddenStatus(
    updateKeyLinkIsHiddenInput: $updateKeyLinkIsHiddenInput
  ) {
    KeyLinkID
    TalentID
    KeyLinkType
    KeyLinkName
    KeyLinkDescription
    KeyLinkImageURL
    isHidden
    StartDate
    EndDate
    DisplayRank
    PhoneNumberRequired
    KeyLinkAdded
    KeyLinkUpdated
    KeyLinkDeleted
    UpdatedByUser
    LinkDetailsID
    LinkDetailsDescription
    LinkDetailsURL
    KeyLinkPixel
    CustomConsentHyperlinkLabel
    CustomConsentURL
    CustomConsentCallToAction
    CustomConsentUpdated
    IsDarkMode
    ProfilePageColor
    KeyLinkRegistrationEmail
    URLFriendlyName
    RedirectURL
    TalentName
    BackgroundType
    ProfilePageSecondColor
    FontID
    FontColor
    BackgroundImageURL
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateKeyLinkIsHiddenStatusMutationVariables,
  APITypes.UpdateKeyLinkIsHiddenStatusMutation
>;
export const updateLinkDetails = /* GraphQL */ `mutation UpdateLinkDetails($updateLinkDetailsInput: UpdateLinkDetailsInput!) {
  updateLinkDetails(updateLinkDetailsInput: $updateLinkDetailsInput) {
    LinkDetailsID
    KeyLinkID
    LinkDetailsDescription
    LinkDetailsURL
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLinkDetailsMutationVariables,
  APITypes.UpdateLinkDetailsMutation
>;
export const updateSocialMedia = /* GraphQL */ `mutation UpdateSocialMedia($updateSocialMediaInput: UpdateSocialMediaInput!) {
  updateSocialMedia(updateSocialMediaInput: $updateSocialMediaInput) {
    KeychainSocialMediaID
    UserID
    SocialMediaType
    SocialMediaUserID
    DisplayRank
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSocialMediaMutationVariables,
  APITypes.UpdateSocialMediaMutation
>;
export const createKeychainTalentAnswer = /* GraphQL */ `mutation CreateKeychainTalentAnswer(
  $createKeychainTalentAnswerInput: CreateKeychainTalentAnswerInput!
) {
  createKeychainTalentAnswer(
    createKeychainTalentAnswerInput: $createKeychainTalentAnswerInput
  ) {
    KeychainTalentAnswerID
    KeychainTalentID
    KeychainTalentQuestionID
    OtherAnswer
    KeychainTalentPrimaryAnswerID
    KeychainTalentSecondaryAnswerID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateKeychainTalentAnswerMutationVariables,
  APITypes.CreateKeychainTalentAnswerMutation
>;
export const createKeychainTalentAnswerWithUserID = /* GraphQL */ `mutation CreateKeychainTalentAnswerWithUserID(
  $createKeychainTalentAnswerWithUserIDInput: CreateKeychainTalentAnswerWithUserIDInput!
) {
  createKeychainTalentAnswerWithUserID(
    createKeychainTalentAnswerWithUserIDInput: $createKeychainTalentAnswerWithUserIDInput
  ) {
    KeychainTalentAnswerID
    TalentID
    KeychainTalentQuestionID
    OtherAnswer
    KeychainTalentPrimaryAnswerID
    KeychainTalentSecondaryAnswerID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateKeychainTalentAnswerWithUserIDMutationVariables,
  APITypes.CreateKeychainTalentAnswerWithUserIDMutation
>;
export const deleteKeyLink = /* GraphQL */ `mutation DeleteKeyLink($KeyLinkID: Int!, $TalentID: Int) {
  deleteKeyLink(KeyLinkID: $KeyLinkID, TalentID: $TalentID) {
    KeyLinkID
    TalentID
    KeyLinkType
    KeyLinkName
    KeyLinkDescription
    KeyLinkImageURL
    isHidden
    StartDate
    EndDate
    DisplayRank
    PhoneNumberRequired
    KeyLinkAdded
    KeyLinkUpdated
    KeyLinkDeleted
    UpdatedByUser
    LinkDetailsID
    LinkDetailsDescription
    LinkDetailsURL
    KeyLinkPixel
    CustomConsentHyperlinkLabel
    CustomConsentURL
    CustomConsentCallToAction
    CustomConsentUpdated
    IsDarkMode
    ProfilePageColor
    KeyLinkRegistrationEmail
    URLFriendlyName
    RedirectURL
    TalentName
    BackgroundType
    ProfilePageSecondColor
    FontID
    FontColor
    BackgroundImageURL
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteKeyLinkMutationVariables,
  APITypes.DeleteKeyLinkMutation
>;
export const registerForExclusiveContent = /* GraphQL */ `mutation RegisterForExclusiveContent(
  $firstName: String!
  $lastName: String!
  $email: String!
  $phoneNumber: String!
) {
  registerForExclusiveContent(
    firstName: $firstName
    lastName: $lastName
    email: $email
    phoneNumber: $phoneNumber
  ) {
    KeychainFanRegistrationID
    Email
    FirstName
    LastName
    PhoneNumber
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RegisterForExclusiveContentMutationVariables,
  APITypes.RegisterForExclusiveContentMutation
>;
export const addToKeyLinkFanGroup = /* GraphQL */ `mutation AddToKeyLinkFanGroup(
  $keyLinkID: Int!
  $talentID: Int!
  $birthday: String
  $birthdayEnabled: Boolean
  $instagram: String
  $instagramEnabled: Boolean
  $city: String
  $cityEnabled: Boolean
  $customQuestion: String
  $customQuestionID: Int
  $customQuestionEnabled: Boolean
  $keychainFanRegistrationID: Int!
  $customConsentRequired: Boolean!
  $keyLinkRegistrationEmail: Boolean!
  $Env: String!
) {
  addToKeyLinkFanGroup(
    keyLinkID: $keyLinkID
    talentID: $talentID
    birthday: $birthday
    birthdayEnabled: $birthdayEnabled
    instagram: $instagram
    instagramEnabled: $instagramEnabled
    city: $city
    cityEnabled: $cityEnabled
    customQuestion: $customQuestion
    customQuestionID: $customQuestionID
    customQuestionEnabled: $customQuestionEnabled
    keychainFanRegistrationID: $keychainFanRegistrationID
    customConsentRequired: $customConsentRequired
    keyLinkRegistrationEmail: $keyLinkRegistrationEmail
    Env: $Env
  )
}
` as GeneratedMutation<
  APITypes.AddToKeyLinkFanGroupMutationVariables,
  APITypes.AddToKeyLinkFanGroupMutation
>;
export const deleteSocialMedia = /* GraphQL */ `mutation DeleteSocialMedia($KeychainSocialMediaID: Int!) {
  deleteSocialMedia(KeychainSocialMediaID: $KeychainSocialMediaID) {
    KeychainSocialMediaID
    UserID
    SocialMediaType
    SocialMediaUserID
    DisplayRank
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSocialMediaMutationVariables,
  APITypes.DeleteSocialMediaMutation
>;
export const updateKeychainTalentProfile = /* GraphQL */ `mutation UpdateKeychainTalentProfile(
  $updateKeychainProfileInput: UpdateKeychainProfileInput!
) {
  updateKeychainTalentProfile(
    updateKeychainProfileInput: $updateKeychainProfileInput
  ) {
    UserID
    TalentDisplayName
    TalentDisplayBio
    IsDarkMode
    KeyLinkRegistrationEmail
    TalentDisplayPhoto
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateKeychainTalentProfileMutationVariables,
  APITypes.UpdateKeychainTalentProfileMutation
>;
export const updateTalentProfileBio = /* GraphQL */ `mutation UpdateTalentProfileBio($UserBio: String!, $UserID: Int!) {
  updateTalentProfileBio(UserBio: $UserBio, UserID: $UserID) {
    UserBio
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTalentProfileBioMutationVariables,
  APITypes.UpdateTalentProfileBioMutation
>;
export const updateKeyLinkDisplayRank = /* GraphQL */ `mutation UpdateKeyLinkDisplayRank($SqlQuery: String!, $TalentID: Int!) {
  updateKeyLinkDisplayRank(SqlQuery: $SqlQuery, TalentID: $TalentID) {
    KeyLinkID
    DisplayRank
    isHidden
    TalentID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateKeyLinkDisplayRankMutationVariables,
  APITypes.UpdateKeyLinkDisplayRankMutation
>;
export const updateSocialMediaDisplayRank = /* GraphQL */ `mutation UpdateSocialMediaDisplayRank($SqlQuery: String!, $UserID: Int!) {
  updateSocialMediaDisplayRank(SqlQuery: $SqlQuery, UserID: $UserID) {
    KeychainSocialMediaID
    UserID
    SocialMediaType
    SocialMediaUserID
    DisplayRank
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSocialMediaDisplayRankMutationVariables,
  APITypes.UpdateSocialMediaDisplayRankMutation
>;
export const createKeychainTalentProfile = /* GraphQL */ `mutation CreateKeychainTalentProfile(
  $profileURL: String!
  $domainURL: String!
  $displayName: String!
  $subscriptionID: String
  $isTrialUser: Boolean
  $TalentID: Int
  $Env: String!
) {
  createKeychainTalentProfile(
    profileURL: $profileURL
    domainURL: $domainURL
    displayName: $displayName
    subscriptionID: $subscriptionID
    isTrialUser: $isTrialUser
    TalentID: $TalentID
    Env: $Env
  )
}
` as GeneratedMutation<
  APITypes.CreateKeychainTalentProfileMutationVariables,
  APITypes.CreateKeychainTalentProfileMutation
>;
export const createKeyCaptureLink = /* GraphQL */ `mutation CreateKeyCaptureLink(
  $TalentID: Int
  $CaptureLinkURL: String!
  $CaptureLinkCustomDomain: String!
  $RedirectURL: String!
  $Env: String!
) {
  createKeyCaptureLink(
    TalentID: $TalentID
    CaptureLinkURL: $CaptureLinkURL
    CaptureLinkCustomDomain: $CaptureLinkCustomDomain
    RedirectURL: $RedirectURL
    Env: $Env
  )
}
` as GeneratedMutation<
  APITypes.CreateKeyCaptureLinkMutationVariables,
  APITypes.CreateKeyCaptureLinkMutation
>;
export const createTalentBrandAffinity = /* GraphQL */ `mutation CreateTalentBrandAffinity(
  $TalentID: Int
  $BrandCategoryID: Int!
  $BrandID: Int!
) {
  createTalentBrandAffinity(
    TalentID: $TalentID
    BrandCategoryID: $BrandCategoryID
    BrandID: $BrandID
  ) {
    TalentBrandAffinityID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTalentBrandAffinityMutationVariables,
  APITypes.CreateTalentBrandAffinityMutation
>;
export const deleteTalentBrandAffinity = /* GraphQL */ `mutation DeleteTalentBrandAffinity($TalentBrandAffinityID: Int!) {
  deleteTalentBrandAffinity(TalentBrandAffinityID: $TalentBrandAffinityID) {
    TalentBrandAffinityID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTalentBrandAffinityMutationVariables,
  APITypes.DeleteTalentBrandAffinityMutation
>;
export const createKeychainSubscription = /* GraphQL */ `mutation CreateKeychainSubscription(
  $talentEmail: String
  $tokenID: String!
  $userID: Int
  $talentName: String
  $promocodeID: String
  $Env: String!
) {
  createKeychainSubscription(
    talentEmail: $talentEmail
    tokenID: $tokenID
    userID: $userID
    talentName: $talentName
    promocodeID: $promocodeID
    Env: $Env
  ) {
    subscriptionID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateKeychainSubscriptionMutationVariables,
  APITypes.CreateKeychainSubscriptionMutation
>;
export const generateFanGroupReport = /* GraphQL */ `mutation GenerateFanGroupReport(
  $KeyLinkID: Int!
  $TimezoneAbbreviation: String!
  $TimezoneOffset: Int!
  $UserIdentityID: String!
  $TalentID: Int
  $Env: String!
) {
  generateFanGroupReport(
    KeyLinkID: $KeyLinkID
    TimezoneAbbreviation: $TimezoneAbbreviation
    TimezoneOffset: $TimezoneOffset
    UserIdentityID: $UserIdentityID
    TalentID: $TalentID
    Env: $Env
  ) {
    DateCreated
    EventID
    FansAdded
    SignedUpForKeychain
    UserIsTalent
    UserIsVerifiedForEventCreation
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GenerateFanGroupReportMutationVariables,
  APITypes.GenerateFanGroupReportMutation
>;
export const generateUploadedFanGroupReport = /* GraphQL */ `mutation GenerateUploadedFanGroupReport(
  $UploadGroupID: Int!
  $TimezoneAbbreviation: String!
  $TimezoneOffset: Int!
  $UserIdentityID: String!
  $TalentID: Int
  $Env: String!
) {
  generateUploadedFanGroupReport(
    UploadGroupID: $UploadGroupID
    TimezoneAbbreviation: $TimezoneAbbreviation
    TimezoneOffset: $TimezoneOffset
    UserIdentityID: $UserIdentityID
    TalentID: $TalentID
    Env: $Env
  ) {
    UploadGroupID
    UserIdentityID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GenerateUploadedFanGroupReportMutationVariables,
  APITypes.GenerateUploadedFanGroupReportMutation
>;
export const generateBehaviorReport = /* GraphQL */ `mutation GenerateBehaviorReport(
  $TalentID: Int
  $TimezoneOffset: Int!
  $UserIdentityID: String!
  $Env: String!
) {
  generateBehaviorReport(
    TalentID: $TalentID
    TimezoneOffset: $TimezoneOffset
    UserIdentityID: $UserIdentityID
    Env: $Env
  ) {
    UserIdentityID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GenerateBehaviorReportMutationVariables,
  APITypes.GenerateBehaviorReportMutation
>;
export const generateTotalFanGroupReport = /* GraphQL */ `mutation GenerateTotalFanGroupReport(
  $TimezoneAbbreviation: String!
  $TimezoneOffset: Int!
  $UserIdentityID: String!
  $TalentID: Int
  $Env: String!
) {
  generateTotalFanGroupReport(
    TimezoneAbbreviation: $TimezoneAbbreviation
    TimezoneOffset: $TimezoneOffset
    UserIdentityID: $UserIdentityID
    TalentID: $TalentID
    Env: $Env
  ) {
    DateCreated
    EventID
    FansAdded
    SignedUpForKeychain
    UserIsTalent
    UserIsVerifiedForEventCreation
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GenerateTotalFanGroupReportMutationVariables,
  APITypes.GenerateTotalFanGroupReportMutation
>;
export const updateCustomConsentAccepted = /* GraphQL */ `mutation UpdateCustomConsentAccepted(
  $KeychainFanRegistrationID: Int!
  $KeyLinkID: Int!
) {
  updateCustomConsentAccepted(
    KeychainFanRegistrationID: $KeychainFanRegistrationID
    KeyLinkID: $KeyLinkID
  )
}
` as GeneratedMutation<
  APITypes.UpdateCustomConsentAcceptedMutationVariables,
  APITypes.UpdateCustomConsentAcceptedMutation
>;
export const updateIsTrialUser = /* GraphQL */ `mutation UpdateIsTrialUser(
  $KeychainTalentID: String!
  $subscriptionID: String!
) {
  updateIsTrialUser(
    KeychainTalentID: $KeychainTalentID
    subscriptionID: $subscriptionID
  ) {
    KeychainTalentID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIsTrialUserMutationVariables,
  APITypes.UpdateIsTrialUserMutation
>;
export const triggerEmailProcessing = /* GraphQL */ `mutation TriggerEmailProcessing(
  $S3Key: String!
  $UserCognitoUID: String
  $TalentID: Int!
  $EmailUploadGroupID: Int
  $FanGroupName: String!
  $Env: String!
  $Headers: String!
) {
  triggerEmailProcessing(
    S3Key: $S3Key
    UserCognitoUID: $UserCognitoUID
    TalentID: $TalentID
    EmailUploadGroupID: $EmailUploadGroupID
    FanGroupName: $FanGroupName
    Env: $Env
    Headers: $Headers
  ) {
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.TriggerEmailProcessingMutationVariables,
  APITypes.TriggerEmailProcessingMutation
>;
export const talentCreatePasswordEmail = /* GraphQL */ `mutation TalentCreatePasswordEmail(
  $TalentEmail: String!
  $TalentAdminID: Int!
  $Env: String!
) {
  talentCreatePasswordEmail(
    TalentEmail: $TalentEmail
    TalentAdminID: $TalentAdminID
    Env: $Env
  )
}
` as GeneratedMutation<
  APITypes.TalentCreatePasswordEmailMutationVariables,
  APITypes.TalentCreatePasswordEmailMutation
>;
export const updateInviteStatusTalent = /* GraphQL */ `mutation UpdateInviteStatusTalent($TalentID: Int) {
  updateInviteStatusTalent(TalentID: $TalentID) {
    UserAcceptedInvitation
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInviteStatusTalentMutationVariables,
  APITypes.UpdateInviteStatusTalentMutation
>;
export const updateTalentAdmin = /* GraphQL */ `mutation UpdateTalentAdmin($UserCognitoUUID: String!, $OrganizationID: Int!) {
  updateTalentAdmin(
    UserCognitoUUID: $UserCognitoUUID
    OrganizationID: $OrganizationID
  ) {
    UserID
    UserFirstName
    UserLastName
    UserAvatar
    UserBackgroundImage
    UserAdded
    UserIsTalent
    UserIsAdmin
    UserIsVerifiedForEventCreation
    UserIsTeam
    OrganizationID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTalentAdminMutationVariables,
  APITypes.UpdateTalentAdminMutation
>;
export const updateTalentManager = /* GraphQL */ `mutation UpdateTalentManager($UserCognitoUUID: String!, $OrganizationID: Int!) {
  updateTalentManager(
    UserCognitoUUID: $UserCognitoUUID
    OrganizationID: $OrganizationID
  ) {
    UserID
    UserFirstName
    UserLastName
    UserAvatar
    UserBackgroundImage
    UserAdded
    UserIsAdmin
    UserIsManager
    UserIsTalent
    UserAcceptedInvitation
    UserIsVerifiedForEventCreation
    UserIsTeam
    UserEmail
    UserSMS
    UserCognitoUID
    UserBio
    UserCompletedOnboarding
    OrganizationID
    AudienceID
    IsMediaOptIn
    BirthDate
    HasFinishedQuestions
    IsActive
    TemporaryEmail
    TotalTalents
    ProfileCompletion
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTalentManagerMutationVariables,
  APITypes.UpdateTalentManagerMutation
>;
export const createOrganization = /* GraphQL */ `mutation CreateOrganization($OrganizationName: String!) {
  createOrganization(OrganizationName: $OrganizationName) {
    OrganizationID
    OrganizationName
    OrganizationAdded
    OrganizationUpdated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationMutationVariables,
  APITypes.CreateOrganizationMutation
>;
export const saveAppliedCohortFilters = /* GraphQL */ `mutation SaveAppliedCohortFilters($CohortID: Int!, $Payload: String!) {
  saveAppliedCohortFilters(CohortID: $CohortID, Payload: $Payload)
}
` as GeneratedMutation<
  APITypes.SaveAppliedCohortFiltersMutationVariables,
  APITypes.SaveAppliedCohortFiltersMutation
>;
export const deleteCaptureLinkVanityURL = /* GraphQL */ `mutation DeleteCaptureLinkVanityURL(
  $CaptureLinkID: Int!
  $RebrandlyLinkID: String!
  $Env: String
) {
  deleteCaptureLinkVanityURL(
    CaptureLinkID: $CaptureLinkID
    RebrandlyLinkID: $RebrandlyLinkID
    Env: $Env
  )
}
` as GeneratedMutation<
  APITypes.DeleteCaptureLinkVanityURLMutationVariables,
  APITypes.DeleteCaptureLinkVanityURLMutation
>;
export const createFrmAudience = /* GraphQL */ `mutation CreateFrmAudience($TalentID: Int, $Env: String!) {
  createFrmAudience(TalentID: $TalentID, Env: $Env) {
    createFrmAudience
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFrmAudienceMutationVariables,
  APITypes.CreateFrmAudienceMutation
>;
export const sendEmailFailedCall = /* GraphQL */ `mutation SendEmailFailedCall(
  $TalentID: Int
  $Env: String!
  $Error: String!
  $OrganizationID: Int
  $MiscData: String
) {
  sendEmailFailedCall(
    TalentID: $TalentID
    Env: $Env
    Error: $Error
    OrganizationID: $OrganizationID
    MiscData: $MiscData
  )
}
` as GeneratedMutation<
  APITypes.SendEmailFailedCallMutationVariables,
  APITypes.SendEmailFailedCallMutation
>;
export const updateTalentBrandAffinity = /* GraphQL */ `mutation UpdateTalentBrandAffinity(
  $EngagementStatus: String
  $TalentBrandAffinityID: Int!
) {
  updateTalentBrandAffinity(
    EngagementStatus: $EngagementStatus
    TalentBrandAffinityID: $TalentBrandAffinityID
  ) {
    TalentBrandAffinityID
    BrandName
    BrandCategoryName
    EngagementStatus
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTalentBrandAffinityMutationVariables,
  APITypes.UpdateTalentBrandAffinityMutation
>;
export const updateIsMediaOptIn = /* GraphQL */ `mutation UpdateIsMediaOptIn($IsMediaOptIn: Boolean, $UserID: Int!) {
  updateIsMediaOptIn(IsMediaOptIn: $IsMediaOptIn, UserID: $UserID) {
    UserID
    UserFirstName
    UserLastName
    UserAvatar
    UserBackgroundImage
    UserAdded
    UserCompletedOnboarding
    UserIsTalent
    UserIsVerifiedForEventCreation
    UserIsTeam
    UserCognitoUID
    UserBio
    IsMediaOptIn
    OrganizationID
    UserEmail
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIsMediaOptInMutationVariables,
  APITypes.UpdateIsMediaOptInMutation
>;
export const createSocialBrandSearchRequest = /* GraphQL */ `mutation CreateSocialBrandSearchRequest(
  $OrganizationID: Int!
  $RequestName: String!
  $Description: String!
) {
  createSocialBrandSearchRequest(
    OrganizationID: $OrganizationID
    RequestName: $RequestName
    Description: $Description
  )
}
` as GeneratedMutation<
  APITypes.CreateSocialBrandSearchRequestMutationVariables,
  APITypes.CreateSocialBrandSearchRequestMutation
>;
export const triggerCSVFileProcessing = /* GraphQL */ `mutation TriggerCSVFileProcessing(
  $SocialBrandSearchRequestID: Int!
  $S3Key: String!
  $ActionType: String!
  $UserCognitoUID: String
  $RequestorID: Int
  $RequestName: String
  $RequestDescription: String
  $OrganizationName: String
  $Env: String!
) {
  triggerCSVFileProcessing(
    SocialBrandSearchRequestID: $SocialBrandSearchRequestID
    S3Key: $S3Key
    ActionType: $ActionType
    UserCognitoUID: $UserCognitoUID
    RequestorID: $RequestorID
    RequestName: $RequestName
    RequestDescription: $RequestDescription
    OrganizationName: $OrganizationName
    Env: $Env
  ) {
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.TriggerCSVFileProcessingMutationVariables,
  APITypes.TriggerCSVFileProcessingMutation
>;
export const sendSocialBrandSearchRequest = /* GraphQL */ `mutation SendSocialBrandSearchRequest(
  $talentID: Int!
  $requestName: String!
  $requestDescription: String
  $organizationName: String
  $Env: String!
) {
  sendSocialBrandSearchRequest(
    talentID: $talentID
    requestName: $requestName
    requestDescription: $requestDescription
    organizationName: $organizationName
    Env: $Env
  ) {
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SendSocialBrandSearchRequestMutationVariables,
  APITypes.SendSocialBrandSearchRequestMutation
>;
export const sendEmailToTalentAdminAfterSignup = /* GraphQL */ `mutation SendEmailToTalentAdminAfterSignup($Email: String!, $Env: String!) {
  sendEmailToTalentAdminAfterSignup(Email: $Email, Env: $Env)
}
` as GeneratedMutation<
  APITypes.SendEmailToTalentAdminAfterSignupMutationVariables,
  APITypes.SendEmailToTalentAdminAfterSignupMutation
>;
export const generateSocialAudienceOverlapReport = /* GraphQL */ `mutation GenerateSocialAudienceOverlapReport(
  $RequestID: Int!
  $TimezoneOffset: Int!
  $UserIdentityID: String!
  $RequestorID: Int!
  $TalentID: Int!
  $Env: String!
) {
  generateSocialAudienceOverlapReport(
    RequestID: $RequestID
    TimezoneOffset: $TimezoneOffset
    UserIdentityID: $UserIdentityID
    RequestorID: $RequestorID
    TalentID: $TalentID
    Env: $Env
  ) {
    UploadGroupID
    UserIdentityID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GenerateSocialAudienceOverlapReportMutationVariables,
  APITypes.GenerateSocialAudienceOverlapReportMutation
>;
export const updateSocialHandles = /* GraphQL */ `mutation UpdateSocialHandles(
  $talentID: Int!
  $KeychainTalentQuestionID: Int!
  $OtherAnswer: String!
) {
  updateSocialHandles(
    talentID: $talentID
    KeychainTalentQuestionID: $KeychainTalentQuestionID
    OtherAnswer: $OtherAnswer
  )
}
` as GeneratedMutation<
  APITypes.UpdateSocialHandlesMutationVariables,
  APITypes.UpdateSocialHandlesMutation
>;
export const generateSocialMediaReachReport = /* GraphQL */ `mutation GenerateSocialMediaReachReport(
  $TalentID: Int!
  $TimezoneOffset: Int!
  $UserIdentityID: String!
  $Env: String!
) {
  generateSocialMediaReachReport(
    TalentID: $TalentID
    TimezoneOffset: $TimezoneOffset
    UserIdentityID: $UserIdentityID
    Env: $Env
  )
}
` as GeneratedMutation<
  APITypes.GenerateSocialMediaReachReportMutationVariables,
  APITypes.GenerateSocialMediaReachReportMutation
>;
export const createTalentGoodPurposeInitiativeAffinity = /* GraphQL */ `mutation CreateTalentGoodPurposeInitiativeAffinity(
  $TalentID: Int
  $GoodPurposeInitiativeID: Int!
) {
  createTalentGoodPurposeInitiativeAffinity(
    TalentID: $TalentID
    GoodPurposeInitiativeID: $GoodPurposeInitiativeID
  ) {
    TalentGoodPurposeInitiativeID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTalentGoodPurposeInitiativeAffinityMutationVariables,
  APITypes.CreateTalentGoodPurposeInitiativeAffinityMutation
>;
export const deleteTalentGoodPurposeInitiativeAffinity = /* GraphQL */ `mutation DeleteTalentGoodPurposeInitiativeAffinity(
  $TalentID: Int
  $GoodPurposeInitiativeID: Int!
) {
  deleteTalentGoodPurposeInitiativeAffinity(
    TalentID: $TalentID
    GoodPurposeInitiativeID: $GoodPurposeInitiativeID
  ) {
    TalentGoodPurposeInitiativeID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTalentGoodPurposeInitiativeAffinityMutationVariables,
  APITypes.DeleteTalentGoodPurposeInitiativeAffinityMutation
>;
export const updateTemporaryEmailInUsers = /* GraphQL */ `mutation UpdateTemporaryEmailInUsers(
  $updateTemporaryEmailInUsersInput: UpdateTemporaryEmailInUsersInput!
) {
  updateTemporaryEmailInUsers(
    updateTemporaryEmailInUsersInput: $updateTemporaryEmailInUsersInput
  ) {
    UserID
    UserFirstName
    UserLastName
    UserAvatar
    UserBackgroundImage
    UserAdded
    UserIsAdmin
    UserIsManager
    UserIsTalent
    UserAcceptedInvitation
    UserIsVerifiedForEventCreation
    UserIsTeam
    UserEmail
    UserSMS
    UserCognitoUID
    UserBio
    UserCompletedOnboarding
    OrganizationID
    AudienceID
    IsMediaOptIn
    BirthDate
    HasFinishedQuestions
    IsActive
    TemporaryEmail
    TotalTalents
    ProfileCompletion
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTemporaryEmailInUsersMutationVariables,
  APITypes.UpdateTemporaryEmailInUsersMutation
>;
export const deleteTemporaryEmailInUsers = /* GraphQL */ `mutation DeleteTemporaryEmailInUsers(
  $deleteTemporaryEmailInUsersInput: DeleteTemporaryEmailInUsersInput!
) {
  deleteTemporaryEmailInUsers(
    deleteTemporaryEmailInUsersInput: $deleteTemporaryEmailInUsersInput
  ) {
    UserID
    UserFirstName
    UserLastName
    UserAvatar
    UserBackgroundImage
    UserAdded
    UserIsAdmin
    UserIsManager
    UserIsTalent
    UserAcceptedInvitation
    UserIsVerifiedForEventCreation
    UserIsTeam
    UserEmail
    UserSMS
    UserCognitoUID
    UserBio
    UserCompletedOnboarding
    OrganizationID
    AudienceID
    IsMediaOptIn
    BirthDate
    HasFinishedQuestions
    IsActive
    TemporaryEmail
    TotalTalents
    ProfileCompletion
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTemporaryEmailInUsersMutationVariables,
  APITypes.DeleteTemporaryEmailInUsersMutation
>;
export const activateDeactivateSingleUser = /* GraphQL */ `mutation ActivateDeactivateSingleUser($IsActive: Boolean!, $UserID: Int!) {
  activateDeactivateSingleUser(IsActive: $IsActive, UserID: $UserID) {
    IsActive
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ActivateDeactivateSingleUserMutationVariables,
  APITypes.ActivateDeactivateSingleUserMutation
>;
export const activateDeactivateOrgAccount = /* GraphQL */ `mutation ActivateDeactivateOrgAccount(
  $IsActive: Boolean!
  $OrganizationID: Int!
) {
  activateDeactivateOrgAccount(
    IsActive: $IsActive
    OrganizationID: $OrganizationID
  ) {
    IsActive
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ActivateDeactivateOrgAccountMutationVariables,
  APITypes.ActivateDeactivateOrgAccountMutation
>;
export const upsertKeychainTalentAnswer = /* GraphQL */ `mutation UpsertKeychainTalentAnswer(
  $keychainTalentAnswerInput: KeychainTalentAnswerInput!
) {
  upsertKeychainTalentAnswer(
    keychainTalentAnswerInput: $keychainTalentAnswerInput
  ) {
    KeychainTalentAnswerID
    KeychainTalentPrimaryAnswerID
    KeychainTalentSecondaryAnswerID
    OtherAnswer
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpsertKeychainTalentAnswerMutationVariables,
  APITypes.UpsertKeychainTalentAnswerMutation
>;
export const refreshInsights = /* GraphQL */ `mutation RefreshInsights(
  $UserID: Int
  $TalentID: Int!
  $ReOnboardAll: Boolean
  $OrganizationID: Int
  $IsKeyAdminActivity: Boolean!
  $Env: String!
) {
  refreshInsights(
    UserID: $UserID
    TalentID: $TalentID
    ReOnboardAll: $ReOnboardAll
    OrganizationID: $OrganizationID
    IsKeyAdminActivity: $IsKeyAdminActivity
    Env: $Env
  )
}
` as GeneratedMutation<
  APITypes.RefreshInsightsMutationVariables,
  APITypes.RefreshInsightsMutation
>;
export const removeAssignedTalentsFromManager = /* GraphQL */ `mutation RemoveAssignedTalentsFromManager($ManagerID: Int!) {
  removeAssignedTalentsFromManager(ManagerID: $ManagerID)
}
` as GeneratedMutation<
  APITypes.RemoveAssignedTalentsFromManagerMutationVariables,
  APITypes.RemoveAssignedTalentsFromManagerMutation
>;
export const assignTalentsToManager = /* GraphQL */ `mutation AssignTalentsToManager(
  $TalentIDs: [Int!]!
  $OrganizationID: Int!
  $ManagerID: Int!
) {
  assignTalentsToManager(
    TalentIDs: $TalentIDs
    OrganizationID: $OrganizationID
    ManagerID: $ManagerID
  )
}
` as GeneratedMutation<
  APITypes.AssignTalentsToManagerMutationVariables,
  APITypes.AssignTalentsToManagerMutation
>;
export const sendCompleteUserAccountEmail = /* GraphQL */ `mutation SendCompleteUserAccountEmail($UserID: Int!, $Env: String!) {
  sendCompleteUserAccountEmail(UserID: $UserID, Env: $Env)
}
` as GeneratedMutation<
  APITypes.SendCompleteUserAccountEmailMutationVariables,
  APITypes.SendCompleteUserAccountEmailMutation
>;
export const unassignTalentFromManager = /* GraphQL */ `mutation UnassignTalentFromManager(
  $OrganizationID: Int!
  $ManagerID: Int!
  $TalentID: Int!
) {
  unassignTalentFromManager(
    OrganizationID: $OrganizationID
    ManagerID: $ManagerID
    TalentID: $TalentID
  ) {
    TalentID
    AccountManagerID
    UserFirstName
    UserLastName
    UserEmail
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UnassignTalentFromManagerMutationVariables,
  APITypes.UnassignTalentFromManagerMutation
>;
export const changeUserType = /* GraphQL */ `mutation ChangeUserType($UserID: Int!, $UserType: String!) {
  changeUserType(UserID: $UserID, UserType: $UserType)
}
` as GeneratedMutation<
  APITypes.ChangeUserTypeMutationVariables,
  APITypes.ChangeUserTypeMutation
>;
export const assignManagersToTalent = /* GraphQL */ `mutation AssignManagersToTalent(
  $ManagerIDs: [Int!]!
  $OrganizationID: Int!
  $TalentID: Int!
) {
  assignManagersToTalent(
    ManagerIDs: $ManagerIDs
    OrganizationID: $OrganizationID
    TalentID: $TalentID
  )
}
` as GeneratedMutation<
  APITypes.AssignManagersToTalentMutationVariables,
  APITypes.AssignManagersToTalentMutation
>;
export const updateBrandContacts = /* GraphQL */ `mutation UpdateBrandContacts(
  $UserID: Int!
  $BrandID: Int!
  $TalentID: Int!
  $RequestType: String!
  $Email: String
  $RequestMoreContacts: String
  $Titles: [String!]
  $Env: String!
) {
  updateBrandContacts(
    UserID: $UserID
    BrandID: $BrandID
    TalentID: $TalentID
    RequestType: $RequestType
    Email: $Email
    RequestMoreContacts: $RequestMoreContacts
    Titles: $Titles
    Env: $Env
  ) {
    ContactsAdded
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBrandContactsMutationVariables,
  APITypes.UpdateBrandContactsMutation
>;
export const upsertKeychainAdditionalQuestions = /* GraphQL */ `mutation UpsertKeychainAdditionalQuestions(
  $questions: [KeychainAdditionalQuestionsInput!]!
  $IsDisabled: Boolean
) {
  upsertKeychainAdditionalQuestions(
    questions: $questions
    IsDisabled: $IsDisabled
  )
}
` as GeneratedMutation<
  APITypes.UpsertKeychainAdditionalQuestionsMutationVariables,
  APITypes.UpsertKeychainAdditionalQuestionsMutation
>;
export const upsertKeychainCustomQuestion = /* GraphQL */ `mutation UpsertKeychainCustomQuestion(
  $customQuestion: KeychainCustomQuestionInput
) {
  upsertKeychainCustomQuestion(customQuestion: $customQuestion)
}
` as GeneratedMutation<
  APITypes.UpsertKeychainCustomQuestionMutationVariables,
  APITypes.UpsertKeychainCustomQuestionMutation
>;
export const refreshCohortMetrics = /* GraphQL */ `mutation RefreshCohortMetrics($CohortID: Int!, $Env: String!) {
  refreshCohortMetrics(CohortID: $CohortID, Env: $Env)
}
` as GeneratedMutation<
  APITypes.RefreshCohortMetricsMutationVariables,
  APITypes.RefreshCohortMetricsMutation
>;
export const updateFanPhoneNumber = /* GraphQL */ `mutation UpdateFanPhoneNumber(
  $KeychainFanRegistrationID: Int!
  $PhoneNumber: String!
) {
  updateFanPhoneNumber(
    KeychainFanRegistrationID: $KeychainFanRegistrationID
    PhoneNumber: $PhoneNumber
  )
}
` as GeneratedMutation<
  APITypes.UpdateFanPhoneNumberMutationVariables,
  APITypes.UpdateFanPhoneNumberMutation
>;
export const tieTalentsToAudienceOverlapReport = /* GraphQL */ `mutation TieTalentsToAudienceOverlapReport(
  $TalentIDs: [Int!]!
  $SocialBrandSearchRequestID: Int!
) {
  tieTalentsToAudienceOverlapReport(
    TalentIDs: $TalentIDs
    SocialBrandSearchRequestID: $SocialBrandSearchRequestID
  )
}
` as GeneratedMutation<
  APITypes.TieTalentsToAudienceOverlapReportMutationVariables,
  APITypes.TieTalentsToAudienceOverlapReportMutation
>;
export const deleteTalentsTiedToAudienceOverlapReport = /* GraphQL */ `mutation DeleteTalentsTiedToAudienceOverlapReport(
  $SocialAudienceSearchRequestID: Int!
) {
  deleteTalentsTiedToAudienceOverlapReport(
    SocialAudienceSearchRequestID: $SocialAudienceSearchRequestID
  )
}
` as GeneratedMutation<
  APITypes.DeleteTalentsTiedToAudienceOverlapReportMutationVariables,
  APITypes.DeleteTalentsTiedToAudienceOverlapReportMutation
>;
export const generateCohortMetrics = /* GraphQL */ `mutation GenerateCohortMetrics(
  $CohortID: Int!
  $Users: [Int!]!
  $Segments: [Int!]!
  $Env: String!
) {
  generateCohortMetrics(
    CohortID: $CohortID
    Users: $Users
    Segments: $Segments
    Env: $Env
  )
}
` as GeneratedMutation<
  APITypes.GenerateCohortMetricsMutationVariables,
  APITypes.GenerateCohortMetricsMutation
>;
export const generateFacebookToken = /* GraphQL */ `mutation GenerateFacebookToken($AccessToken: String!, $Env: String!) {
  generateFacebookToken(AccessToken: $AccessToken, Env: $Env) {
    accessToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GenerateFacebookTokenMutationVariables,
  APITypes.GenerateFacebookTokenMutation
>;
export const updateTalentWithoutEmail = /* GraphQL */ `mutation UpdateTalentWithoutEmail(
  $updateTalentWithoutEmailInput: UpdateTalentWithoutEmailInput
) {
  updateTalentWithoutEmail(
    updateTalentWithoutEmailInput: $updateTalentWithoutEmailInput
  ) {
    UserID
    UserFirstName
    UserLastName
    UserAvatar
    UserBackgroundImage
    UserAdded
    UserIsAdmin
    UserIsManager
    UserIsTalent
    UserAcceptedInvitation
    UserIsVerifiedForEventCreation
    UserIsTeam
    UserEmail
    UserSMS
    UserCognitoUID
    UserBio
    UserCompletedOnboarding
    OrganizationID
    AudienceID
    IsMediaOptIn
    BirthDate
    HasFinishedQuestions
    IsActive
    TemporaryEmail
    TotalTalents
    ProfileCompletion
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTalentWithoutEmailMutationVariables,
  APITypes.UpdateTalentWithoutEmailMutation
>;
export const addToActivityLog = /* GraphQL */ `mutation AddToActivityLog(
  $TalentID: Int!
  $UserID: Int!
  $OrganizationID: Int
  $ActivityName: String
  $ActivityDetail: String
  $ActivitySubDetail: String
  $Category: String
) {
  addToActivityLog(
    TalentID: $TalentID
    UserID: $UserID
    OrganizationID: $OrganizationID
    ActivityName: $ActivityName
    ActivityDetail: $ActivityDetail
    ActivitySubDetail: $ActivitySubDetail
    Category: $Category
  )
}
` as GeneratedMutation<
  APITypes.AddToActivityLogMutationVariables,
  APITypes.AddToActivityLogMutation
>;
export const updateHeroStatus = /* GraphQL */ `mutation UpdateHeroStatus(
  $CohortID: Int!
  $UserID: Int
  $HeroStatus: Boolean!
  $SegmentID: Int
) {
  updateHeroStatus(
    CohortID: $CohortID
    UserID: $UserID
    HeroStatus: $HeroStatus
    SegmentID: $SegmentID
  ) {
    CohortID
    UserID
    HeroStatus
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHeroStatusMutationVariables,
  APITypes.UpdateHeroStatusMutation
>;
export const updateCohortName = /* GraphQL */ `mutation UpdateCohortName($CohortID: Int!, $CohortName: String!) {
  updateCohortName(CohortID: $CohortID, CohortName: $CohortName)
}
` as GeneratedMutation<
  APITypes.UpdateCohortNameMutationVariables,
  APITypes.UpdateCohortNameMutation
>;
export const createInitialCohortRow = /* GraphQL */ `mutation CreateInitialCohortRow {
  createInitialCohortRow
}
` as GeneratedMutation<
  APITypes.CreateInitialCohortRowMutationVariables,
  APITypes.CreateInitialCohortRowMutation
>;
export const createNotification = /* GraphQL */ `mutation CreateNotification(
  $NotificationType: String!
  $UserID: Int!
  $TalentID: Int!
  $Event: String
  $ClickRoute: String
  $SeenByUser: Boolean
  $Timestamp: String
) {
  createNotification(
    NotificationType: $NotificationType
    UserID: $UserID
    TalentID: $TalentID
    Event: $Event
    ClickRoute: $ClickRoute
    SeenByUser: $SeenByUser
    Timestamp: $Timestamp
  ) {
    NotificationID
    NotificationType
    UserID
    TalentID
    Event
    ClickRoute
    SeenByUser
    Timestamp
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationMutationVariables,
  APITypes.CreateNotificationMutation
>;
export const updateSegmentDetails = /* GraphQL */ `mutation UpdateSegmentDetails(
  $SegmentID: Int!
  $Name: String
  $Description: String
  $Industries: String
  $SegmentImage: String
) {
  updateSegmentDetails(
    SegmentID: $SegmentID
    Name: $Name
    Description: $Description
    Industries: $Industries
    SegmentImage: $SegmentImage
  ) {
    Name
    Description
    SegmentImageURL
    AudienceReach
    Industries
    Tags
    IncludedItems
    SegmentStatus
    DateUpdated
    FiltersPayload
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSegmentDetailsMutationVariables,
  APITypes.UpdateSegmentDetailsMutation
>;
export const updateCohortDetails = /* GraphQL */ `mutation UpdateCohortDetails(
  $CohortID: Int!
  $Description: String
  $CohortImage: String
  $ExportName: String
) {
  updateCohortDetails(
    CohortID: $CohortID
    Description: $Description
    CohortImage: $CohortImage
    ExportName: $ExportName
  ) {
    CohortID
    Name
    ExportName
    TotalSocialMediaReach
    InstagramFollowers
    InstagramLikes
    InstagramTotalPosts
    TiktokFollowers
    TiktokTotalShares
    TiktokTotalVideos
    TwitterFollowers
    TwitterLikes
    TwitterTotalTweets
    FacebookFollowers
    YoutubeSubscribers
    TotalEmails
    ProjectedMaxMediaSpend
    EstimatedTakeHomeProfit
    ConfidenceScore
    Version
    DateAdded
    DateLastUpdated
    TotalAudienceReach
    Description
    CohortImage
    InDashboard
    ShowKeyScores
    ShowTopDomains
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCohortDetailsMutationVariables,
  APITypes.UpdateCohortDetailsMutation
>;
export const saveIndustryPrimaryAnswer = /* GraphQL */ `mutation SaveIndustryPrimaryAnswer(
  $KeychainTalentAnswerID: Int
  $TalentID: Int!
  $KeychainTalentPrimaryAnswerID: Int!
  $OtherPrimaryAnswer: String
) {
  saveIndustryPrimaryAnswer(
    KeychainTalentAnswerID: $KeychainTalentAnswerID
    TalentID: $TalentID
    KeychainTalentPrimaryAnswerID: $KeychainTalentPrimaryAnswerID
    OtherPrimaryAnswer: $OtherPrimaryAnswer
  )
}
` as GeneratedMutation<
  APITypes.SaveIndustryPrimaryAnswerMutationVariables,
  APITypes.SaveIndustryPrimaryAnswerMutation
>;
export const saveIndustrySecondaryAnswer = /* GraphQL */ `mutation SaveIndustrySecondaryAnswer(
  $KeychainTalentAnswerID: Int!
  $KeychainTalentSecondaryAnswerID: Int
  $OtherSecondaryAnswer: String
) {
  saveIndustrySecondaryAnswer(
    KeychainTalentAnswerID: $KeychainTalentAnswerID
    KeychainTalentSecondaryAnswerID: $KeychainTalentSecondaryAnswerID
    OtherSecondaryAnswer: $OtherSecondaryAnswer
  )
}
` as GeneratedMutation<
  APITypes.SaveIndustrySecondaryAnswerMutationVariables,
  APITypes.SaveIndustrySecondaryAnswerMutation
>;
export const saveIndustryTertiaryAnswer = /* GraphQL */ `mutation SaveIndustryTertiaryAnswer(
  $KeychainTalentAnswerID: Int!
  $KeychainTalentTertiaryAnswerID: Int
  $OtherTertiaryAnswer: String
) {
  saveIndustryTertiaryAnswer(
    KeychainTalentAnswerID: $KeychainTalentAnswerID
    KeychainTalentTertiaryAnswerID: $KeychainTalentTertiaryAnswerID
    OtherTertiaryAnswer: $OtherTertiaryAnswer
  )
}
` as GeneratedMutation<
  APITypes.SaveIndustryTertiaryAnswerMutationVariables,
  APITypes.SaveIndustryTertiaryAnswerMutation
>;
export const removeIndustryAnswer = /* GraphQL */ `mutation RemoveIndustryAnswer($KeychainTalentAnswerID: Int!) {
  removeIndustryAnswer(KeychainTalentAnswerID: $KeychainTalentAnswerID)
}
` as GeneratedMutation<
  APITypes.RemoveIndustryAnswerMutationVariables,
  APITypes.RemoveIndustryAnswerMutation
>;
export const saveMultipleIndustrySecondaryAnswers = /* GraphQL */ `mutation SaveMultipleIndustrySecondaryAnswers(
  $KeychainTalentAnswerID: Int!
  $KeychainTalentPrimaryAnswerID: Int!
) {
  saveMultipleIndustrySecondaryAnswers(
    KeychainTalentAnswerID: $KeychainTalentAnswerID
    KeychainTalentPrimaryAnswerID: $KeychainTalentPrimaryAnswerID
  )
}
` as GeneratedMutation<
  APITypes.SaveMultipleIndustrySecondaryAnswersMutationVariables,
  APITypes.SaveMultipleIndustrySecondaryAnswersMutation
>;
export const markNotificationsAsSeen = /* GraphQL */ `mutation MarkNotificationsAsSeen(
  $UserID: Int!
  $Limit: Int
  $NotificationIDs: String
) {
  markNotificationsAsSeen(
    UserID: $UserID
    Limit: $Limit
    NotificationIDs: $NotificationIDs
  ) {
    NotificationID
    TalentName
    Notification
    Timestamp
    SeenByUser
    ClickRoute
    __typename
  }
}
` as GeneratedMutation<
  APITypes.MarkNotificationsAsSeenMutationVariables,
  APITypes.MarkNotificationsAsSeenMutation
>;
export const insertIndustryPrimaryAnswer = /* GraphQL */ `mutation InsertIndustryPrimaryAnswer(
  $TalentID: Int!
  $KeychainTalentPrimaryAnswerID: Int!
  $KeychainTalentOtherPrimaryAnswer: String
) {
  insertIndustryPrimaryAnswer(
    TalentID: $TalentID
    KeychainTalentPrimaryAnswerID: $KeychainTalentPrimaryAnswerID
    KeychainTalentOtherPrimaryAnswer: $KeychainTalentOtherPrimaryAnswer
  )
}
` as GeneratedMutation<
  APITypes.InsertIndustryPrimaryAnswerMutationVariables,
  APITypes.InsertIndustryPrimaryAnswerMutation
>;
export const insertIndustrySecondaryAnswer = /* GraphQL */ `mutation InsertIndustrySecondaryAnswer(
  $TalentID: Int!
  $KeychainTalentSecondaryAnswerID: Int!
  $KeychainTalentAnswerID: Int!
  $KeychainTalentOtherSecondaryAnswer: String
) {
  insertIndustrySecondaryAnswer(
    TalentID: $TalentID
    KeychainTalentSecondaryAnswerID: $KeychainTalentSecondaryAnswerID
    KeychainTalentAnswerID: $KeychainTalentAnswerID
    KeychainTalentOtherSecondaryAnswer: $KeychainTalentOtherSecondaryAnswer
  )
}
` as GeneratedMutation<
  APITypes.InsertIndustrySecondaryAnswerMutationVariables,
  APITypes.InsertIndustrySecondaryAnswerMutation
>;
export const insertIndustryTertiaryAnswer = /* GraphQL */ `mutation InsertIndustryTertiaryAnswer(
  $TalentID: Int!
  $KeychainTalentTertiaryAnswerID: Int!
  $KeychainTalentAnswerID: Int!
  $KeychainTalentOtherTertiaryAnswer: String
) {
  insertIndustryTertiaryAnswer(
    TalentID: $TalentID
    KeychainTalentTertiaryAnswerID: $KeychainTalentTertiaryAnswerID
    KeychainTalentAnswerID: $KeychainTalentAnswerID
    KeychainTalentOtherTertiaryAnswer: $KeychainTalentOtherTertiaryAnswer
  )
}
` as GeneratedMutation<
  APITypes.InsertIndustryTertiaryAnswerMutationVariables,
  APITypes.InsertIndustryTertiaryAnswerMutation
>;
export const insertMultipleIndustrySecondaryAnswers = /* GraphQL */ `mutation InsertMultipleIndustrySecondaryAnswers(
  $KeychainTalentAnswerID: Int!
  $KeychainTalentPrimaryAnswerID: Int!
) {
  insertMultipleIndustrySecondaryAnswers(
    KeychainTalentAnswerID: $KeychainTalentAnswerID
    KeychainTalentPrimaryAnswerID: $KeychainTalentPrimaryAnswerID
  )
}
` as GeneratedMutation<
  APITypes.InsertMultipleIndustrySecondaryAnswersMutationVariables,
  APITypes.InsertMultipleIndustrySecondaryAnswersMutation
>;
export const createCohort = /* GraphQL */ `mutation CreateCohort($CohortName: String!) {
  createCohort(CohortName: $CohortName)
}
` as GeneratedMutation<
  APITypes.CreateCohortMutationVariables,
  APITypes.CreateCohortMutation
>;
export const addCohortsToDashboard = /* GraphQL */ `mutation AddCohortsToDashboard($CohortIDs: String!) {
  addCohortsToDashboard(CohortIDs: $CohortIDs) {
    CohortID
    Name
    ExportName
    TotalSocialMediaReach
    InstagramFollowers
    InstagramLikes
    InstagramTotalPosts
    TiktokFollowers
    TiktokTotalShares
    TiktokTotalVideos
    TwitterFollowers
    TwitterLikes
    TwitterTotalTweets
    FacebookFollowers
    YoutubeSubscribers
    TotalEmails
    ProjectedMaxMediaSpend
    EstimatedTakeHomeProfit
    ConfidenceScore
    Version
    DateAdded
    DateLastUpdated
    TotalAudienceReach
    Description
    CohortImage
    InDashboard
    ShowKeyScores
    ShowTopDomains
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddCohortsToDashboardMutationVariables,
  APITypes.AddCohortsToDashboardMutation
>;
export const saveTalentBrandRelationships = /* GraphQL */ `mutation SaveTalentBrandRelationships(
  $TalentID: Int!
  $SelectedItemIDs: [Int]!
) {
  saveTalentBrandRelationships(
    TalentID: $TalentID
    SelectedItemIDs: $SelectedItemIDs
  )
}
` as GeneratedMutation<
  APITypes.SaveTalentBrandRelationshipsMutationVariables,
  APITypes.SaveTalentBrandRelationshipsMutation
>;
export const deleteTalentBrandRelationship = /* GraphQL */ `mutation DeleteTalentBrandRelationship($TalentID: Int!, $BrandID: Int!) {
  deleteTalentBrandRelationship(TalentID: $TalentID, BrandID: $BrandID)
}
` as GeneratedMutation<
  APITypes.DeleteTalentBrandRelationshipMutationVariables,
  APITypes.DeleteTalentBrandRelationshipMutation
>;
export const updateInstagramInsightsCredentials = /* GraphQL */ `mutation UpdateInstagramInsightsCredentials(
  $TalentID: Int!
  $FacebookToken: String!
  $InstagramID: String!
) {
  updateInstagramInsightsCredentials(
    TalentID: $TalentID
    FacebookToken: $FacebookToken
    InstagramID: $InstagramID
  )
}
` as GeneratedMutation<
  APITypes.UpdateInstagramInsightsCredentialsMutationVariables,
  APITypes.UpdateInstagramInsightsCredentialsMutation
>;
export const updateUpdatedByUserInKeylink = /* GraphQL */ `mutation UpdateUpdatedByUserInKeylink(
  $TalentID: Int!
  $DisplayRank: Int!
  $KeyLinkType: String!
) {
  updateUpdatedByUserInKeylink(
    TalentID: $TalentID
    DisplayRank: $DisplayRank
    KeyLinkType: $KeyLinkType
  )
}
` as GeneratedMutation<
  APITypes.UpdateUpdatedByUserInKeylinkMutationVariables,
  APITypes.UpdateUpdatedByUserInKeylinkMutation
>;
export const saveProfileTagsToUser = /* GraphQL */ `mutation SaveProfileTagsToUser(
  $Tags: [String!]!
  $TalentID: Int!
  $UserTagIDs: [Int!]
) {
  saveProfileTagsToUser(
    Tags: $Tags
    TalentID: $TalentID
    UserTagIDs: $UserTagIDs
  )
}
` as GeneratedMutation<
  APITypes.SaveProfileTagsToUserMutationVariables,
  APITypes.SaveProfileTagsToUserMutation
>;
export const saveCustomProfileTagToUser = /* GraphQL */ `mutation SaveCustomProfileTagToUser($Tags: [String!]!, $TalentID: Int!) {
  saveCustomProfileTagToUser(Tags: $Tags, TalentID: $TalentID)
}
` as GeneratedMutation<
  APITypes.SaveCustomProfileTagToUserMutationVariables,
  APITypes.SaveCustomProfileTagToUserMutation
>;
export const removeTagFromUser = /* GraphQL */ `mutation RemoveTagFromUser($TagID: Int!, $TalentID: Int!) {
  removeTagFromUser(TagID: $TagID, TalentID: $TalentID)
}
` as GeneratedMutation<
  APITypes.RemoveTagFromUserMutationVariables,
  APITypes.RemoveTagFromUserMutation
>;
export const updateTalentReportAvailability = /* GraphQL */ `mutation UpdateTalentReportAvailability(
  $UserID: Int!
  $ReportAvailability: Boolean!
) {
  updateTalentReportAvailability(
    UserID: $UserID
    ReportAvailability: $ReportAvailability
  ) {
    ReportAvailability
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTalentReportAvailabilityMutationVariables,
  APITypes.UpdateTalentReportAvailabilityMutation
>;
export const saveTagsForKeyLink = /* GraphQL */ `mutation SaveTagsForKeyLink(
  $Tags: [String!]!
  $KeyLinkID: Int!
  $KeyLinkTagIDs: [Int!]
  $KeyLinkTagType: String!
) {
  saveTagsForKeyLink(
    Tags: $Tags
    KeyLinkID: $KeyLinkID
    KeyLinkTagIDs: $KeyLinkTagIDs
    KeyLinkTagType: $KeyLinkTagType
  )
}
` as GeneratedMutation<
  APITypes.SaveTagsForKeyLinkMutationVariables,
  APITypes.SaveTagsForKeyLinkMutation
>;
export const saveCustomKeyLinkTagsToKeyLink = /* GraphQL */ `mutation SaveCustomKeyLinkTagsToKeyLink(
  $Tags: [String!]!
  $KeyLinkID: Int!
  $TalentID: Int!
) {
  saveCustomKeyLinkTagsToKeyLink(
    Tags: $Tags
    KeyLinkID: $KeyLinkID
    TalentID: $TalentID
  )
}
` as GeneratedMutation<
  APITypes.SaveCustomKeyLinkTagsToKeyLinkMutationVariables,
  APITypes.SaveCustomKeyLinkTagsToKeyLinkMutation
>;
export const removeTagFromKeyLinkByTagID = /* GraphQL */ `mutation RemoveTagFromKeyLinkByTagID($KeyLinkTagID: Int!, $KeyLinkID: Int!) {
  removeTagFromKeyLinkByTagID(
    KeyLinkTagID: $KeyLinkTagID
    KeyLinkID: $KeyLinkID
  )
}
` as GeneratedMutation<
  APITypes.RemoveTagFromKeyLinkByTagIDMutationVariables,
  APITypes.RemoveTagFromKeyLinkByTagIDMutation
>;
export const removeAllTagsOnKeyLink = /* GraphQL */ `mutation RemoveAllTagsOnKeyLink($KeyLinkID: Int!) {
  removeAllTagsOnKeyLink(KeyLinkID: $KeyLinkID)
}
` as GeneratedMutation<
  APITypes.RemoveAllTagsOnKeyLinkMutationVariables,
  APITypes.RemoveAllTagsOnKeyLinkMutation
>;
export const updateCohortSettings = /* GraphQL */ `mutation UpdateCohortSettings(
  $CohortID: Int!
  $ShowKeyScores: Boolean!
  $ShowTopDomains: Boolean!
) {
  updateCohortSettings(
    CohortID: $CohortID
    ShowKeyScores: $ShowKeyScores
    ShowTopDomains: $ShowTopDomains
  )
}
` as GeneratedMutation<
  APITypes.UpdateCohortSettingsMutationVariables,
  APITypes.UpdateCohortSettingsMutation
>;
export const removeSegmentProfiles = /* GraphQL */ `mutation RemoveSegmentProfiles($SegmentID: Int!, $Profiles: String!) {
  removeSegmentProfiles(SegmentID: $SegmentID, Profiles: $Profiles)
}
` as GeneratedMutation<
  APITypes.RemoveSegmentProfilesMutationVariables,
  APITypes.RemoveSegmentProfilesMutation
>;
export const removeSegmentCaptureLinks = /* GraphQL */ `mutation RemoveSegmentCaptureLinks($SegmentID: Int!, $CaptureLinks: String!) {
  removeSegmentCaptureLinks(SegmentID: $SegmentID, CaptureLinks: $CaptureLinks)
}
` as GeneratedMutation<
  APITypes.RemoveSegmentCaptureLinksMutationVariables,
  APITypes.RemoveSegmentCaptureLinksMutation
>;
export const addSegmentProfiles = /* GraphQL */ `mutation AddSegmentProfiles($SegmentID: Int!, $Profiles: String!) {
  addSegmentProfiles(SegmentID: $SegmentID, Profiles: $Profiles)
}
` as GeneratedMutation<
  APITypes.AddSegmentProfilesMutationVariables,
  APITypes.AddSegmentProfilesMutation
>;
export const addSegmentCaptureLinks = /* GraphQL */ `mutation AddSegmentCaptureLinks($SegmentID: Int!, $CaptureLinks: String!) {
  addSegmentCaptureLinks(SegmentID: $SegmentID, CaptureLinks: $CaptureLinks)
}
` as GeneratedMutation<
  APITypes.AddSegmentCaptureLinksMutationVariables,
  APITypes.AddSegmentCaptureLinksMutation
>;
export const createSegmentParameters = /* GraphQL */ `mutation CreateSegmentParameters(
  $SegmentID: Int
  $ExcludedTalents: [Int]
  $ExcludedKeylinks: [Int]
  $Env: String!
) {
  createSegmentParameters(
    SegmentID: $SegmentID
    ExcludedTalents: $ExcludedTalents
    ExcludedKeylinks: $ExcludedKeylinks
    Env: $Env
  )
}
` as GeneratedMutation<
  APITypes.CreateSegmentParametersMutationVariables,
  APITypes.CreateSegmentParametersMutation
>;
export const createSegment = /* GraphQL */ `mutation CreateSegment(
  $Name: String!
  $Industries: String!
  $FiltersPayload: String!
  $IncludedItems: String!
) {
  createSegment(
    Name: $Name
    Industries: $Industries
    FiltersPayload: $FiltersPayload
    IncludedItems: $IncludedItems
  )
}
` as GeneratedMutation<
  APITypes.CreateSegmentMutationVariables,
  APITypes.CreateSegmentMutation
>;
export const refreshSegment = /* GraphQL */ `mutation RefreshSegment($SegmentID: Int!, $Env: String!) {
  refreshSegment(SegmentID: $SegmentID, Env: $Env)
}
` as GeneratedMutation<
  APITypes.RefreshSegmentMutationVariables,
  APITypes.RefreshSegmentMutation
>;
export const deleteCohort = /* GraphQL */ `mutation DeleteCohort($CohortID: Int!) {
  deleteCohort(CohortID: $CohortID) {
    CohortID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCohortMutationVariables,
  APITypes.DeleteCohortMutation
>;
export const updateProfilePageLastVisited = /* GraphQL */ `mutation UpdateProfilePageLastVisited($TalentID: Int!) {
  updateProfilePageLastVisited(TalentID: $TalentID)
}
` as GeneratedMutation<
  APITypes.UpdateProfilePageLastVisitedMutationVariables,
  APITypes.UpdateProfilePageLastVisitedMutation
>;
export const updateKeychainPageOrder = /* GraphQL */ `mutation UpdateKeychainPageOrder(
  $KeychainTalentID: Int!
  $KeychainOrder: [KeychainOrderInput!]
) {
  updateKeychainPageOrder(
    KeychainTalentID: $KeychainTalentID
    KeychainOrder: $KeychainOrder
  ) {
    KeychainTalentID
    Section
    Rank
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateKeychainPageOrderMutationVariables,
  APITypes.UpdateKeychainPageOrderMutation
>;
export const createKeychainSectionDisplayRank = /* GraphQL */ `mutation CreateKeychainSectionDisplayRank(
  $KeychainTalentID: Int!
  $Section: String!
  $Rank: Int!
) {
  createKeychainSectionDisplayRank(
    KeychainTalentID: $KeychainTalentID
    Section: $Section
    Rank: $Rank
  )
}
` as GeneratedMutation<
  APITypes.CreateKeychainSectionDisplayRankMutationVariables,
  APITypes.CreateKeychainSectionDisplayRankMutation
>;
export const deleteEmbedLink = /* GraphQL */ `mutation DeleteEmbedLink($KeychainEmbedsID: Int!) {
  deleteEmbedLink(KeychainEmbedsID: $KeychainEmbedsID) {
    KeychainEmbedsID
    KeychainTalentID
    KeychainEmbedSourceID
    URL
    Label
    StartDate
    EndDate
    isHidden
    DisplayRank
    MediaType
    Description
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEmbedLinkMutationVariables,
  APITypes.DeleteEmbedLinkMutation
>;
export const updateEmbedLink = /* GraphQL */ `mutation UpdateEmbedLink($updateEmbedLinkInput: UpdateEmbedLinkInput!) {
  updateEmbedLink(updateEmbedLinkInput: $updateEmbedLinkInput) {
    KeychainEmbedsID
    KeychainTalentID
    KeychainEmbedSourceID
    URL
    Label
    StartDate
    EndDate
    isHidden
    DisplayRank
    MediaType
    Description
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEmbedLinkMutationVariables,
  APITypes.UpdateEmbedLinkMutation
>;
export const createEmbedLink = /* GraphQL */ `mutation CreateEmbedLink($createEmbedLinkInput: CreateEmbedLinkInput!) {
  createEmbedLink(createEmbedLinkInput: $createEmbedLinkInput) {
    KeychainEmbedsID
    KeychainTalentID
    KeychainEmbedSourceID
    URL
    Label
    StartDate
    EndDate
    isHidden
    DisplayRank
    MediaType
    Description
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEmbedLinkMutationVariables,
  APITypes.CreateEmbedLinkMutation
>;
export const updateEmbedLinksDisplayRank = /* GraphQL */ `mutation UpdateEmbedLinksDisplayRank(
  $SqlQuery: String!
  $KeychainTalentID: Int!
) {
  updateEmbedLinksDisplayRank(
    SqlQuery: $SqlQuery
    KeychainTalentID: $KeychainTalentID
  ) {
    KeychainEmbedsID
    KeychainTalentID
    KeychainEmbedSourceID
    URL
    Label
    StartDate
    EndDate
    isHidden
    DisplayRank
    MediaType
    Description
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEmbedLinksDisplayRankMutationVariables,
  APITypes.UpdateEmbedLinksDisplayRankMutation
>;
export const updateTalentSocialReachSummary = /* GraphQL */ `mutation UpdateTalentSocialReachSummary(
  $TalentID: Int!
  $FieldNameDynamicOne: String!
  $FieldNameDynamicTwo: String!
) {
  updateTalentSocialReachSummary(
    TalentID: $TalentID
    FieldNameDynamicOne: $FieldNameDynamicOne
    FieldNameDynamicTwo: $FieldNameDynamicTwo
  ) {
    SocialReachSummaryID
    OrganizationID
    UserID
    FacebookFollowers
    FacebookLikes
    InstagramFollowers
    InstagramLikes
    TiktokFollowers
    TiktokLikes
    TwitterFollowers
    TwitterLikes
    YoutubeSubscribers
    YoutubeTotalViews
    LastUpdated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTalentSocialReachSummaryMutationVariables,
  APITypes.UpdateTalentSocialReachSummaryMutation
>;
export const updateHideSegment = /* GraphQL */ `mutation UpdateHideSegment($SegmentID: Int!) {
  updateHideSegment(SegmentID: $SegmentID)
}
` as GeneratedMutation<
  APITypes.UpdateHideSegmentMutationVariables,
  APITypes.UpdateHideSegmentMutation
>;
export const deleteMailchimpKlaviyoAPIKey = /* GraphQL */ `mutation DeleteMailchimpKlaviyoAPIKey($UserID: Int!, $Type: String!) {
  deleteMailchimpKlaviyoAPIKey(UserID: $UserID, Type: $Type)
}
` as GeneratedMutation<
  APITypes.DeleteMailchimpKlaviyoAPIKeyMutationVariables,
  APITypes.DeleteMailchimpKlaviyoAPIKeyMutation
>;
export const updateMailchimpKlaviyoAPIKey = /* GraphQL */ `mutation UpdateMailchimpKlaviyoAPIKey(
  $UserID: Int!
  $Type: String!
  $ApiKey: String!
) {
  updateMailchimpKlaviyoAPIKey(UserID: $UserID, Type: $Type, ApiKey: $ApiKey)
}
` as GeneratedMutation<
  APITypes.UpdateMailchimpKlaviyoAPIKeyMutationVariables,
  APITypes.UpdateMailchimpKlaviyoAPIKeyMutation
>;
export const triggerMailchimpProcessing = /* GraphQL */ `mutation TriggerMailchimpProcessing(
  $TalentID: Int!
  $EmailUploadGroupID: Int
  $FanGroupName: String!
  $Env: String!
) {
  triggerMailchimpProcessing(
    TalentID: $TalentID
    EmailUploadGroupID: $EmailUploadGroupID
    FanGroupName: $FanGroupName
    Env: $Env
  ) {
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.TriggerMailchimpProcessingMutationVariables,
  APITypes.TriggerMailchimpProcessingMutation
>;
export const initiateUploadToMailchimp = /* GraphQL */ `mutation InitiateUploadToMailchimp(
  $TalentID: Int
  $UploadType: UploadType
  $ListID: String
  $EmailUploadGroupID: Int
  $KeyLinkID: Int
  $Env: String!
) {
  initiateUploadToMailchimp(
    TalentID: $TalentID
    UploadType: $UploadType
    ListID: $ListID
    EmailUploadGroupID: $EmailUploadGroupID
    KeyLinkID: $KeyLinkID
    Env: $Env
  ) {
    status
    lists {
      id
      name
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.InitiateUploadToMailchimpMutationVariables,
  APITypes.InitiateUploadToMailchimpMutation
>;
export const triggerKlaviyoProcessing = /* GraphQL */ `mutation TriggerKlaviyoProcessing(
  $TalentID: Int!
  $ListIDs: String!
  $Env: String!
  $EmailUploadGroupID: Int!
  $FanGroupName: String!
  $UserCognitoUID: String!
) {
  triggerKlaviyoProcessing(
    TalentID: $TalentID
    ListIDs: $ListIDs
    Env: $Env
    EmailUploadGroupID: $EmailUploadGroupID
    FanGroupName: $FanGroupName
    UserCognitoUID: $UserCognitoUID
  )
}
` as GeneratedMutation<
  APITypes.TriggerKlaviyoProcessingMutationVariables,
  APITypes.TriggerKlaviyoProcessingMutation
>;
export const getMetaDataFromURL = /* GraphQL */ `mutation GetMetaDataFromURL($Env: String!, $TalentID: Int!, $URL: String!) {
  getMetaDataFromURL(Env: $Env, TalentID: $TalentID, URL: $URL) {
    title
    description
    image
    imageBuffer
    keywords
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GetMetaDataFromURLMutationVariables,
  APITypes.GetMetaDataFromURLMutation
>;
export const getCreatorsData = /* GraphQL */ `mutation GetCreatorsData(
  $Env: String!
  $RequestType: String!
  $PhylloParams: String!
) {
  getCreatorsData(
    Env: $Env
    RequestType: $RequestType
    PhylloParams: $PhylloParams
  ) {
    work_platform {
      id
      name
      logo_url
      __typename
    }
    platform_username
    full_name
    introduction
    follower_count
    engagement_rate
    average_likes
    image_url
    url
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GetCreatorsDataMutationVariables,
  APITypes.GetCreatorsDataMutation
>;
export const getCreatorsAutoCompleteData = /* GraphQL */ `mutation GetCreatorsAutoCompleteData(
  $Env: String!
  $RequestType: String!
  $PhylloParams: String!
) {
  getCreatorsAutoCompleteData(
    Env: $Env
    RequestType: $RequestType
    PhylloParams: $PhylloParams
  ) {
    work_platform {
      id
      name
      logo_url
      __typename
    }
    platform_username
    full_name
    introduction
    follower_count
    engagement_rate
    average_likes
    image_url
    url
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GetCreatorsAutoCompleteDataMutationVariables,
  APITypes.GetCreatorsAutoCompleteDataMutation
>;
export const deleteTalentLookALikeResultMatches = /* GraphQL */ `mutation DeleteTalentLookALikeResultMatches($TalentLookALikeResultID: Int!) {
  deleteTalentLookALikeResultMatches(
    TalentLookALikeResultID: $TalentLookALikeResultID
  ) {
    UserID
    ImageURL
    FollowerCount
    TalentLookALikeResultID
    UserFirstName
    UserLastName
    MatchHandle
    TotalEmails
    IsMediaOptIn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTalentLookALikeResultMatchesMutationVariables,
  APITypes.DeleteTalentLookALikeResultMatchesMutation
>;
export const createTalentLookALikeResultMatches = /* GraphQL */ `mutation CreateTalentLookALikeResultMatches(
  $createTalentLookALikeResultMatchesInput: CreateTalentLookALikeResultMatchesInput!
) {
  createTalentLookALikeResultMatches(
    createTalentLookALikeResultMatchesInput: $createTalentLookALikeResultMatchesInput
  ) {
    UserID
    ImageURL
    FollowerCount
    TalentLookALikeResultID
    UserFirstName
    UserLastName
    MatchHandle
    TotalEmails
    IsMediaOptIn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTalentLookALikeResultMatchesMutationVariables,
  APITypes.CreateTalentLookALikeResultMatchesMutation
>;
export const updateTalentLookALikeResultMatches = /* GraphQL */ `mutation UpdateTalentLookALikeResultMatches(
  $updateTalentLookALikeResultMatchesInput: UpdateTalentLookALikeResultMatchesInput!
) {
  updateTalentLookALikeResultMatches(
    updateTalentLookALikeResultMatchesInput: $updateTalentLookALikeResultMatchesInput
  ) {
    UserID
    ImageURL
    FollowerCount
    TalentLookALikeResultID
    UserFirstName
    UserLastName
    MatchHandle
    TotalEmails
    IsMediaOptIn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTalentLookALikeResultMatchesMutationVariables,
  APITypes.UpdateTalentLookALikeResultMatchesMutation
>;
export const deleteTalentLookALikeResults = /* GraphQL */ `mutation DeleteTalentLookALikeResults($TalentLookALikeResultID: Int!) {
  deleteTalentLookALikeResults(
    TalentLookALikeResultID: $TalentLookALikeResultID
  ) {
    TalentLookALikeResultID
    CreatorName
    ProfileURL
    Instagram
    Status
    TalentLookALikeID
    Matches {
      UserID
      ImageURL
      FollowerCount
      TalentLookALikeResultID
      UserFirstName
      UserLastName
      MatchHandle
      TotalEmails
      IsMediaOptIn
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTalentLookALikeResultsMutationVariables,
  APITypes.DeleteTalentLookALikeResultsMutation
>;
export const createTalentLookALikeResults = /* GraphQL */ `mutation CreateTalentLookALikeResults(
  $LookALikeSearchID: Int!
  $SearchItems: [TalentLookALikePersonInput]!
) {
  createTalentLookALikeResults(
    LookALikeSearchID: $LookALikeSearchID
    SearchItems: $SearchItems
  )
}
` as GeneratedMutation<
  APITypes.CreateTalentLookALikeResultsMutationVariables,
  APITypes.CreateTalentLookALikeResultsMutation
>;
export const updateTalentLookALikeResults = /* GraphQL */ `mutation UpdateTalentLookALikeResults(
  $updateTalentLookALikeResultsInput: UpdateTalentLookALikeResultsInput!
) {
  updateTalentLookALikeResults(
    updateTalentLookALikeResultsInput: $updateTalentLookALikeResultsInput
  ) {
    TalentLookALikeResultID
    CreatorName
    ProfileURL
    Instagram
    Status
    TalentLookALikeID
    Matches {
      UserID
      ImageURL
      FollowerCount
      TalentLookALikeResultID
      UserFirstName
      UserLastName
      MatchHandle
      TotalEmails
      IsMediaOptIn
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTalentLookALikeResultsMutationVariables,
  APITypes.UpdateTalentLookALikeResultsMutation
>;
export const deleteTalentLookALikes = /* GraphQL */ `mutation DeleteTalentLookALikes($TalentLookALikeID: Int!) {
  deleteTalentLookALikes(TalentLookALikeID: $TalentLookALikeID) {
    TalentLookALikeID
    TalentLookALikeName
    Active
    DateAdded
    AddedBy
    AddedByFirstName
    AddedByLastName
    TotalResults
    MatchedResults
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTalentLookALikesMutationVariables,
  APITypes.DeleteTalentLookALikesMutation
>;
export const createTalentLookALikes = /* GraphQL */ `mutation CreateTalentLookALikes(
  $TalentLookAlikeInput: TalentLookAlikeInput!
  $Env: String!
) {
  createTalentLookALikes(TalentLookAlikeInput: $TalentLookAlikeInput, Env: $Env)
}
` as GeneratedMutation<
  APITypes.CreateTalentLookALikesMutationVariables,
  APITypes.CreateTalentLookALikesMutation
>;
export const updateTalentLookALikes = /* GraphQL */ `mutation UpdateTalentLookALikes(
  $updateTalentLookALikesInput: UpdateTalentLookALikesInput!
) {
  updateTalentLookALikes(
    updateTalentLookALikesInput: $updateTalentLookALikesInput
  ) {
    TalentLookALikeID
    TalentLookALikeName
    Active
    DateAdded
    AddedBy
    AddedByFirstName
    AddedByLastName
    TotalResults
    MatchedResults
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTalentLookALikesMutationVariables,
  APITypes.UpdateTalentLookALikesMutation
>;
export const getTopicTags = /* GraphQL */ `mutation GetTopicTags(
  $Env: String!
  $RequestType: String!
  $PhylloParams: String!
) {
  getTopicTags(
    Env: $Env
    RequestType: $RequestType
    PhylloParams: $PhylloParams
  ) {
    data {
      name
      value
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GetTopicTagsMutationVariables,
  APITypes.GetTopicTagsMutation
>;
export const getTopicTagsRelevance = /* GraphQL */ `mutation GetTopicTagsRelevance(
  $Env: String!
  $RequestType: String!
  $PhylloParams: String!
) {
  getTopicTagsRelevance(
    Env: $Env
    RequestType: $RequestType
    PhylloParams: $PhylloParams
  ) {
    name
    distance
    frequency
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GetTopicTagsRelevanceMutationVariables,
  APITypes.GetTopicTagsRelevanceMutation
>;
export const findTalentLookAlikes = /* GraphQL */ `mutation FindTalentLookAlikes(
  $Env: String!
  $LookalikeHandle: String!
  $LookALikeResultID: Int!
) {
  findTalentLookAlikes(
    Env: $Env
    LookalikeHandle: $LookalikeHandle
    LookALikeResultID: $LookALikeResultID
  )
}
` as GeneratedMutation<
  APITypes.FindTalentLookAlikesMutationVariables,
  APITypes.FindTalentLookAlikesMutation
>;
