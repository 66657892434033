import { FC } from 'react';

interface NoEmailProps {
    color?: string;
}

const NoEmailIcon: FC<NoEmailProps> = ({ color = '#FFFFFF' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.02432 0L0 1.02432L2.02261 3.04692C1.96585 3.21332 1.93506 3.39175 1.93506 3.57736V15.0733C1.93506 15.9803 2.67033 16.7156 3.57733 16.7156H15.6912L18.6354 19.6597L19.6597 18.6354L1.02432 0ZM14.049 15.0733L9.1134 10.1377L3.57733 7.3694V15.0733H14.049ZM5.44072 6.46503L3.57733 4.60164V5.53324L5.44072 6.46503ZM18.3578 5.53373L11.8246 8.80031L13.0487 10.0244L18.3578 7.36984V15.0733H18.0976L19.3817 16.3574C19.7586 16.0565 20.0001 15.5931 20.0001 15.0733V3.57736C20.0001 2.67036 19.2648 1.93508 18.3578 1.93508H4.9594L6.60167 3.57735H18.3578V5.53373Z" fill={color} />
        </svg>
    );
};

export default NoEmailIcon;