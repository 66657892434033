import i18next from 'i18next';
import React, { Component, ReactNode } from 'react';

import styles from './ErrorBoundary.module.scss';

type ErrorBoundaryProps = {
  children: ReactNode;
};
type ErrorBoundaryState = {
  hasError: boolean;
};

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  public constructor (props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError (): ErrorBoundaryState {
    return { hasError: true };
  }

  public static componentDidCatch (error: unknown, errorInfo: unknown): void {
    window.Rollbar.error('ErrorBoundary caught error', { error, errorInfo });
  }

  public render (): ReactNode {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <div className={styles.infoWrapper}>
            <p>{i18next.t('errors.error-loading')}</p>
            <p>{i18next.t('errors.refresh-page')}</p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
