import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAuthValue } from '../services/Auth/Auth';

const UnauthedRoute = ({ component: Component, ...rest }): JSX.Element => {
  const context = useAuthValue();

  const { isSignedIn, isSigningIn } = context;

  return (
    <Route
      {...rest}
      render={(props): JSX.Element =>
        isSignedIn && !isSigningIn ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default UnauthedRoute;
