import { ApolloClient } from "@apollo/client";
import { CreateTalentLookALikesMutation, CreateTalentLookALikesMutationVariables, DeleteTalentLookALikesMutation, DeleteTalentLookALikesMutationVariables, GetTalentSearchQuery, GetTalentSearchQueryVariables, GetTalentSearchTitleQuery, GetTalentSearchTitleQueryVariables, ListTalentLookALikesQuery, GetTalentLookALikesQueryVariables, TalentLookALikes, TalentLookAlikeInput, FindTalentLookAlikesMutation, FindTalentLookAlikesMutationVariables } from "API";
import gql from "graphql-tag";
import { createTalentLookALikes, deleteTalentLookALikes, findTalentLookAlikes } from "graphql/mutations";
import { getTalentSearch, listTalentLookALikes, getTalentSearchTitle } from "graphql/queries";
import { debounce } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { getURLEnv } from "utils";

export const retrieveListData = async (
    client: ApolloClient<object>,
    callback: Dispatch<SetStateAction<TalentLookALikes[]>>,
    errorCallBack: Dispatch<SetStateAction<string>>
) => {

    try {

        const { data } = await client.query<ListTalentLookALikesQuery, GetTalentLookALikesQueryVariables>({
            query: gql(listTalentLookALikes),
        });

        if (data === undefined || data === null) {
            errorCallBack('Error fetching past searches. Please try again later.');
        } else {

            let allLookAlikes = data?.listTalentLookALikes;
            callback(allLookAlikes as TalentLookALikes[]);

        }

    } catch (error) {
        errorCallBack('Error fetching past searches. Please try again later.');
    }
};





export const deleteListData = async (
    client: ApolloClient<object>,
    id: number,
) => {

    try {

        let response = await client.mutate<DeleteTalentLookALikesMutation, DeleteTalentLookALikesMutationVariables>({
            mutation: gql(deleteTalentLookALikes),
            variables: {
                TalentLookALikeID: id
            }
        });

        if (response.data?.deleteTalentLookALikes === null || response.data?.deleteTalentLookALikes === undefined) {
            return false;
        }

        return true;


    } catch (error) {
        return false;
    }
}



const makeSearch = async (client: ApolloClient<object>, searchTerm: string, callback: (results: any) => any) => {
    try {

        let result = await client.query<GetTalentSearchQuery, GetTalentSearchQueryVariables>({
            query: gql(getTalentSearch),
            variables: {
                SearchQuery: searchTerm,
                Env: getURLEnv()
            }
        });

        callback(result.data?.getTalentSearch?.data);

    } catch (error) {
        callback([]);
    }
}

export const debouncedSearch = debounce(makeSearch, 700);



export const addToTalentLookALikes = async (client: ApolloClient<object>, Input: TalentLookAlikeInput) => {
    try {
        const result = await client?.mutate<
            CreateTalentLookALikesMutation,
            CreateTalentLookALikesMutationVariables
        >({
            mutation: gql(createTalentLookALikes),
            variables: {
                TalentLookAlikeInput: Input,
                Env: getURLEnv()
            }
        })

        return result.data?.createTalentLookALikes;

    } catch (error) {
        return null;

    }
}


export const getTalentSearchTitleQuery = async (client: ApolloClient<object>, searchID: number) => {
    try {
        let result = await client.query<GetTalentSearchTitleQuery, GetTalentSearchTitleQueryVariables>({
            query: gql(getTalentSearchTitle),
            variables: {
                SearchID: searchID
            }
        });
        return result.data?.getTalentSearchTitle;

    } catch (error) {
        return '';
    }
}


export const searchForLookAlikes = async (client: ApolloClient<object>, searchTerm: string, lookAlikeID: number) => {
    try {
        let result = await client.mutate<FindTalentLookAlikesMutation, FindTalentLookAlikesMutationVariables>({
            mutation: gql(findTalentLookAlikes),
            variables: {
                LookalikeHandle: searchTerm,
                LookALikeResultID: lookAlikeID,
                Env: getURLEnv()
            }
        });

        return result;

    } catch (error) {
        console.log(error);
    }
}