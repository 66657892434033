import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  APP_VERSION,
  clearCacheAndReload,
  getLatestAppVersion,
} from '../../utils/appVersion';

import styles from './AppVersionBanner.module.scss';

interface AppVersionBannerProps {
  setShowAppVersionBanner?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppVersionBanner: FC<AppVersionBannerProps> = ({
  setShowAppVersionBanner,
}): JSX.Element => {
  const { t } = useTranslation();

  const location = useLocation();

  const [displayBanner] = useState<boolean>(false);

  useEffect(() => {
    setShowAppVersionBanner?.(displayBanner);
  }, [displayBanner]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      try {
        const remoteVersion = await getLatestAppVersion();

        const localVersion = localStorage.getItem(APP_VERSION);

        if (!remoteVersion) {
          return;
        }

        if (!localVersion) {
          await clearCacheAndReload();

          return;
        }

        if (localVersion !== remoteVersion) {
          await clearCacheAndReload();

          return;
        }
      } catch (error) {
        window.Rollbar.error('AppVersionCheck', { error });
      }
    })();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!displayBanner) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.displayBannerText}>
        {t('navigation.new-app-version-available')}
        <span
          className={styles.displayBannerLink}
          onClick={clearCacheAndReload}
        >
          {t('navigation.get-it-now')}
        </span>
      </div>
    </div>
  );
};

export default AppVersionBanner;
