import { MILLISECONDS_IN_A_SECOND } from './constants';

export const delayInSeconds = async (seconds: number): Promise<void> => {
  return new Promise((resolve): void => {
    setTimeout(resolve, seconds * MILLISECONDS_IN_A_SECOND);
  });
};

export const getBaseApiUrl = (): string => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return 'https://dv0j0qsga0.execute-api.us-east-1.amazonaws.com/prod';
    case 'quality-assurance':
      return 'https://baf9lh81p1.execute-api.us-east-1.amazonaws.com/qa';
    case 'development':
      return 'https://5q8fivwth3.execute-api.us-east-1.amazonaws.com/dev';
    case 'local':
      return 'http://localhost:8080';
    case 'testing':
      return '';
    default:
      return 'https://5q8fivwth3.execute-api.us-east-1.amazonaws.com/dev';
  }
};

const getBasePaymentUrl = (): string => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return 'https://3hvnpr9lr8.execute-api.us-east-1.amazonaws.com/prod/payments';
    case 'quality-assurance':
      return 'https://e4xpq0d0hh.execute-api.us-east-1.amazonaws.com/qa/payments';
    case 'development':
      return 'https://cvexndt0s9.execute-api.us-east-1.amazonaws.com/dev/payments';
    case 'local':
      return 'http://localhost:8080';
    case 'testing':
      return '';
    default:
      return 'https://cvexndt0s9.execute-api.us-east-1.amazonaws.com/dev/payments';
  }
};

export const getPaymentsAuthUrl = (cognitoUID: string | null): string =>
  `${getBasePaymentUrl()}/auth/stripe?state=${cognitoUID}`;

export const getStripeApiKey = (): string => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return 'pk_live_GzyE2U1txTwPebcsRtgV37uZ00eaWrG5OM';
    case 'quality-assurance':
      return 'pk_test_dgDU67mgCqhNpt33hDuGD8v7007GL5IdXu';
    case 'development':
      return 'pk_test_dgDU67mgCqhNpt33hDuGD8v7007GL5IdXu';
    case 'local':
      return 'pk_test_dgDU67mgCqhNpt33hDuGD8v7007GL5IdXu';
    case 'testing':
      return 'pk_test_dgDU67mgCqhNpt33hDuGD8v7007GL5IdXu';
    default:
      return 'pk_test_dgDU67mgCqhNpt33hDuGD8v7007GL5IdXu';
  }
};

export const isChrome = (): boolean => /Chrome/.test(navigator.userAgent);

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent,
);
export const isiOS = (): boolean =>
  /iPad|iPhone|iPod/.test(navigator.userAgent);

export const isWindows = (): boolean => /Windows/.test(navigator.userAgent);

export const getURLEnv = (): string => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return 'prod';
    case 'quality-assurance':
      return 'qa';
    case 'development':
      return 'dev';
    case 'local':
      return 'dev';
    default:
      return 'dev';
  }
};
