export {};
import XLSX from 'sheetjs-style';
import FileSaver from 'file-saver';
import { getBatchSearchExportData, getTalentIndustriesBio, getTalentLookALikeExportData } from 'graphql/queries';
import { gql } from '@apollo/client';
import { largeNumberFormatter } from './number';

type ExcelExportProps = {
  data?: any;
  fileName?: string;
  columns?: any;
  profiles: number[];
  client: any;
  profileExport?: boolean;
  cohortExport?: boolean;
  behaviors?: string[];
  socials?: any;
};

type DataExport = {
  Profile: any;
  'Profile Bio': string;
  Instagram?: any;
  Status?: string;
  'Match Name'?: string;
  'Match Instagram'?: any;
  'Match URL'?: string;
  Brand?: string;
  Date?: string;
  'Audience Targeting Parameters'?: string;
};

export const ExcelExport = async (props: ExcelExportProps) => {
  const {
    data,
    fileName,
    profiles,
    client,
    profileExport,
    socials,
  } = props;
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const newFileName = fileName + ' Export ' + formattedDate();

  let exportData = await transformData(data, profiles, client);
  if (profileExport) exportData = await cohortProfileExport(data, socials);

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(exportData);
    if (!ws['!cols']) ws['!cols'] = [];

    if (!ws['!cols'][0]) ws['!cols'][0] = { wch: 8 };
    ws['!cols'][0].wpx = 150;

    if (!ws['!cols'][1]) ws['!cols'][1] = { wch: 8 };
    ws['!cols'][1].wpx = 550;

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const outputData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(outputData, newFileName + fileExtension);
  };

  exportToExcel();
};

export const ExcelCohortExport = async (props: ExcelExportProps) => {
  const { data, fileName, behaviors, socials } = props;
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const newFileName = fileName + ' Export ' + formattedDate();

  let exportData = await exportCohort(data, socials);
  let methodology = await exportMethodology();
  let audience = await audienceSize(socials);
  let behaviorsList = await addBehaviors(behaviors);

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(
      [
        ['', ''],
        ['', ''],
        ['', ''],
        ['', ''],
      ],
      { skipHeader: true }
    );
    const ws2 = XLSX.utils.json_to_sheet(methodology, { skipHeader: true });


    XLSX.utils.sheet_add_json(ws, exportData, {
      origin: 'A6',
      skipHeader: true,
    });
    XLSX.utils.sheet_add_aoa(ws, [['']], { origin: 'A5' });
    XLSX.utils.sheet_add_aoa(ws, [[`Audience Size: ${largeNumberFormatter(audience)}`]], {
      origin: 'B5',
    });

    ws['A1'].s = {
      font: {
        name: 'Verdana',
        bold: true,
        sz: 12,
      },
      fill: {
        fgColor: { rgb: 'FFFFFF' },
      },
      alignment: {
        horizontal: 'bottom',
        vertical: 'center',
        wrapText: true,
      },
    };
    ws['A2'].s = {
      font: {
        name: 'Verdana',
        bold: true,
        sz: 12,
      },
      fill: {
        fgColor: { rgb: 'FFFFFF' },
      },
      alignment: {
        horizontal: 'bottom',
        vertical: 'center',
        wrapText: true,
      },
    };
    ws['A3'].s = {
      font: {
        name: 'Verdana',
        bold: true,
        sz: 12,
      },
      fill: {
        fgColor: { rgb: 'FFFFFF' },
      },
      alignment: {
        horizontal: 'bottom',
        vertical: 'center',
      },
    };
    ws['A4'].s = {
      font: {
        name: 'Verdana',
        bold: true,
        sz: 12,
      },
      fill: {
        fgColor: { rgb: 'FFFFFF' },
      },
      alignment: {
        horizontal: 'bottom',
        vertical: 'center',
        wrapText: true,
      },
    };

    ws['B1'].s = {
      font: {
        name: 'Verdana',
        bold: true,
        sz: 12,
      },
      fill: {
        fgColor: { rgb: 'FFFFFF' },
      },
      alignment: {
        horizontal: 'bottom',
        vertical: 'center',
        wrapText: true,
      },
    };
    ws['B2'].s = {
      font: {
        name: 'Verdana',
        bold: true,
        sz: 12,
      },
      fill: {
        fgColor: { rgb: 'FFFFFF' },
      },
      alignment: {
        horizontal: 'bottom',
        vertical: 'center',
      },
    };
    ws['B3'].s = {
      font: {
        name: 'Verdana',
        bold: true,
        sz: 12,
      },
      fill: {
        fgColor: { rgb: 'FFFFFF' },
      },
      alignment: {
        horizontal: 'bottom',
        vertical: 'center',
      },
    };
    ws['B4'].s = {
      font: {
        name: 'Verdana',
        bold: true,
        sz: 12,
      },
      fill: {
        fgColor: { rgb: 'FFFFFF' },
      },
      alignment: {
        horizontal: 'bottom',
        vertical: 'center',
        wrapText: true,
      },
    };

    ws['A5'].s = {
      font: {
        name: 'Verdana',
        bold: true,
        sz: 12,
        color: { rgb: 'FFFFFF' },
    },
      fill: {
        fgColor: { rgb: '808080' },
      },
      alignment: {
        horizontal: 'bottom',
        vertical: 'center',
        wrapText: true,
      },
    };

    ws['B5'].s = {
      font: {
        name: 'Verdana',
        bold: true,
        sz: 12,
        color: { rgb: 'FFFFFF' },
    },
      fill: {
        fgColor: { rgb: '808080' },
      },
      alignment: {
        horizontal: 'bottom',
        vertical: 'center',
        wrapText: true,
      },
    };
    if (!ws['!cols']) ws['!cols'] = [];

    if (!ws['!cols'][0]) ws['!cols'][0] = { wch: 8 };
    ws['!cols'][0].wpx = 150;

    if (!ws['!cols'][1]) ws['!cols'][1] = { wch: 8 };
    ws['!cols'][1].wpx = 550;

    ws2['A1'].s = {
      font: {
        name: 'Verdana',
        bold: true,
        sz: 12,
        italic: true,
      },
      fill: {
        fgColor: { rgb: 'ff44fc' },
      },
      alignment: {
        horizontal: 'bottom',
        vertical: 'center',
        wrapText: true,
      },
    };

    ws['!cols'] = [];

    ws['!cols'] = [{ width: 45 }, { width: 45 }];

    if (!ws2['!cols']) ws2['!cols'] = [];

    if (!ws2['!cols'][0]) ws2['!cols'][0] = { wch: 8 };
    ws2['!cols'][0].wpx = 150;

    if (!ws2['!cols'][1]) ws2['!cols'][1] = { wch: 8 };
    ws2['!cols'][1].wpx = 550;

    ws2['!cols'] = [];
    ws2['!rows'] = [];

    ws2['!cols'] = [{ width: 85 }];

    ws2['!rows'] = [{ hpt: 50 }];

    XLSX.utils.sheet_add_aoa(
      ws2,
      [['Example segments used to validate data set:']],
      { origin: 'A3' }
    );
    XLSX.utils.sheet_add_json(ws2, [behaviorsList], {
      origin: 'A4',
      skipHeader: true,
    });

    ws2['A3'].s = {
      font: {
        name: 'Verdana',
        bold: true,
        sz: 12,
      },
      alignment: {
        horizontal: 'bottom',
        vertical: 'center',
        wrapText: true,
      },
    };

    const wb = { Sheets: { data: ws }, SheetNames: ['Title Page'] };
    XLSX.utils.book_append_sheet(wb, ws, 'Audience Recommendation');
    XLSX.utils.book_append_sheet(wb, ws2, 'Methodology');
    XLSX.utils.book_set_sheet_visibility(wb, 0, 2);
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const outputData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(outputData, newFileName + fileExtension);
  };

  exportToExcel();
};

export const ExcelBatchSearchExport = async ({ data, profiles, client, fileName = "Profile_Export" }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const newFileName = `${fileName} ${formattedDate()}`;

  const batchExportData = await transformDataForBatchExport(data, profiles, client);
  const lookAlikeData = await transformLookAlikeData(data, profiles);

  if (!batchExportData || batchExportData.length === 0) {
    console.error("No data to export.");
    return;
  }

  const exportToExcel = async () => {
   
    const wsPrimary = XLSX.utils.json_to_sheet(batchExportData);
    wsPrimary['!cols'] = [{ wch: 30 }, { wch: 20 }, { wch: 50 }];

    const wsLookAlike = XLSX.utils.json_to_sheet(lookAlikeData);
    wsLookAlike['!cols'] = [{ wch: 30 }, { wch: 20 }, { wch: 50 }];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, wsPrimary, 'Audience Rec');
    if (lookAlikeData && lookAlikeData.length > 0) {
      const wsLookAlike = XLSX.utils.json_to_sheet(lookAlikeData);
      wsLookAlike['!cols'] = [{ wch: 30 }, { wch: 20 }, { wch: 50 }];
      XLSX.utils.book_append_sheet(wb, wsLookAlike, 'Lookalike Mapping');
    }

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const outputData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(outputData, newFileName + fileExtension);
  };

  exportToExcel();
};

const formattedDate = () => {
  const now = new Date();
  return (
    now.getFullYear() +
    '_' +
    String(now.getMonth() + 1).padStart(2, '0') +
    '_' +
    String(now.getDate()).padStart(2, '0') +
    '_' +
    String(now.getHours()).padStart(2, '0') +
    '-' +
    String(now.getMinutes()).padStart(2, '0') +
    '-' +
    String(now.getSeconds()).padStart(2, '0')
  );
};

const transformData = async (data: any, IDs: any[], client: any) => {
  let newData = data.filter((item: any) => {
    return IDs.includes(item.TalentLookALikeResultID);
  });

  const newDataPromises = newData.map(async (item: any) => {
    let match;

    if (item.Status === 'LOCAL') {
      const matchData = await client.query({
        query: gql(getTalentLookALikeExportData),
        variables: { SearchHandle: item.Instagram },
      });

      match = matchData?.data?.getTalentLookALikeExportData;
    }

    let newItem: DataExport = {
      Profile: generateInstaCell(
        item.Instagram,
        item.CreatorName,
        match?.Industry ?? null
      ),
      'Profile Bio': match?.Bio ?? '',
    };

    return newItem;
  });

  const returnData = await Promise.all(newDataPromises);

  return returnData;
};

const transformDataForBatchExport = async (data, profiles, client) => {
  const filteredData = data.filter((item) =>
    profiles.includes("TalentLookALikeResultID" in item ? item.TalentLookALikeResultID : item.UserID)
  );

  const newDataPromises = filteredData.map(async (item) => {
    if ("TalentLookALikeResultID" in item && item.Status !== "PENDING") {
      return {
        "Profile Name": {
          v: item.CreatorName,
          l: { Target: `https://www.instagram.com/${item.Instagram}`, Tooltip: 'Instagram Profile' },
          s: {
            font: {
              color: { rgb: '0000FF' },
              underline: true,
            },
          },
        },
        "Profile Bio": '',
      };
    } else if ("UserID" in item) {
      const matchData = await client.query({
        query: gql(getTalentIndustriesBio),
        variables: { TalentID: item.UserID },
      });

      const fetchedIndustry = matchData?.data?.getTalentIndustriesBio?.[0]?.IndustryHierarchy || '';
      const fetchedBio = matchData?.data?.getTalentIndustriesBio?.[0]?.UserBio || '';

      const industryFormattedName = fetchedIndustry
        ? `Key > ${fetchedIndustry} > ${item.UserFirstName} ${item.UserLastName}`
        : `Key > ${item.UserFirstName} ${item.UserLastName}`;

      return {
        "Profile Name": {
          v: industryFormattedName,
          l: { Target: `https://www.instagram.com/${item.OtherAnswer}`, Tooltip: 'Instagram Profile' },
          s: {
            font: {
              color: { rgb: '0000FF' },
              underline: true,
            },
          },
        },
        "Profile Bio": fetchedBio,
      };
    }
  });

  const returnData = await Promise.all(newDataPromises);
  return returnData;
};


const transformLookAlikeData = async (data, profiles) => {
  const lookAlikeData = data.filter(
    (item) => "TalentLookALikeResultID" in item && item.Status !== "PENDING" && profiles.includes(item.TalentLookALikeResultID)
  );

  return lookAlikeData.map((item) => {
    
    const matches = item.Matches?.map((match) => `${match.UserFirstName} ${match.UserLastName}`).join(", ") || '';
    const matchIDs = item.Matches?.map((match) => match.UserID).join(", ") || '';

    return {
      "Talent Lookalike Name": item.CreatorName,
      "Matched Talent(s)": matches,
      "Matched Talent ID(s)": matchIDs
    };
  });
};

const generateInstaCell = (
  instagram: string,
  name: string,
  industry?: string
) => {
  let cellValue = name;
  if (industry && industry !== '') {
    cellValue = 'Key > ' + industry + ' > ' + name;
  }
  return {
    v: cellValue,
    l: {
      Target: 'https://www.instagram.com/' + instagram,
      Tooltip: 'Open Instagram Profile',
    },
    s: {
      font: {
        color: { rgb: '0000FF' },
        underline: true,
      },
    },
  };
};

const generateLargestURL = (
  type?: string,
  handle?: string,
  name?: string,
  industry?: string
) => {
  let cellValue = 'cellValue';

  if (industry && industry !== '') {
    cellValue = 'Key > ' + industry + ' > ' + name;
  }

  if (type === 'instagram') {
    return {
      v: cellValue,
      l: {
        Target: `https://www.instagram.com/${handle}`,
        Tooltip: 'Open Instagram Profile',
      },
      s: {
        font: {
          color: { rgb: '0000FF' },
          underline: true,
        },
      },
    };
  } else if (type === 'tiktok') {
    return {
      v: cellValue,
      l: {
        Target: `https://www.tiktok.com/@${handle}`,
        Tooltip: 'Open Tiktok Profile',
      },
      s: {
        font: {
          color: { rgb: '0000FF' },
          underline: true,
        },
      },
    };
  } else if (type === 'twitter') {
    return {
      v: cellValue,
      l: {
        Target: `https://www.twitter.com/${handle}`,
        Tooltip: 'Open Twitter Profile',
      },
      s: {
        font: {
          color: { rgb: '0000FF' },
          underline: true,
        },
      },
    };
  } else if (type === 'youtube') {
    return {
      v: cellValue,
      l: {
        Target: `https://www.youtube.com/${handle}`,
        Tooltip: 'Open Youtube Profile',
      },
      s: {
        font: {
          color: { rgb: '0000FF' },
          underline: true,
        },
      },
    };
  } else if (type === 'facebook') {
    return {
      v: cellValue,
      l: {
        Target: `https://www.facebook.com/${handle}`,
        Tooltip: 'Open Facebook Profile',
      },
      s: {
        font: {
          color: { rgb: '0000FF' },
          underline: true,
        },
      },
    };
  } else {
    return {
        v: cellValue,
        s: {
            font: {
            color: { rgb: '000000' },
            underline: false,
            },
    }
  }
}
};


const generateLargestSocial = (followersArr: any) => {

  if (followersArr.length > 0) {
    const largest = followersArr.sort((a, b) => b.followers - a.followers)[0];

    return generateLargestURL(
      largest.type,
      largest.handle,
      largest.name,
      largest.industry
    );
  }
  return '';
};

const cohortProfileExport = async (data: any, socials) => {
    const newDataPromises = data.map(async (item: any) => {
        const socialHandles = socials
          ? socials.find((social: any) => social.UserID === item.UserID)
          : null;

          let newItem: DataExport = {
          Profile:
            generateLargestSocial(
              [
                {
                  type: 'instagram',
                  handle: socialHandles?.instagramHandle || item.instagram,
                  followers:
                    socialHandles?.InstagramFollowers ||
                    item.TotalInstagramFollowers,
                  name: item.TalentName,
                  industry: item.FirstIndustry,
                },
                {
                  type: 'tiktok',
                  handle: socialHandles?.tiktokHandle || item.tiktok,
                  followers:
                    socialHandles?.TiktokFollowers || item.TotalTiktokFollowers,
                  name: item.TalentName,
                  industry: item.FirstIndustry,
                },
                {
                  type: 'twitter',
                  handle: socialHandles?.twitterHandle || item.twitter,
                  followers:
                    socialHandles?.TwitterFollowers || item.TotalTwitterFollowers,
                  name: item.TalentName,
                  industry: item.FirstIndustry,
                },
                {
                  type: 'youtube',
                  handle: socialHandles?.youtubeHandle || item.youtube,
                  followers:
                    socialHandles?.YoutubeSubscribers ||
                    item.TotalYoutubeSubscribers,
                  name: item.TalentName,
                  industry: item.FirstIndustry,
                },
                {
                  type: 'facebook',
                  handle: socialHandles?.facebookHandle || item.facebook,
                  followers:
                    socialHandles?.FacebookFollowers || item.TotalFacebookFollowers,
                  name: item.TalentName,
                  industry: item.FirstIndustry,
                },
                
              ] || null
            ) ?? null,
          'Profile Bio': item.UserBio ? item.UserBio : item.Bio ?? null,
        };
    
        return newItem;
      });
    
      const returnData = await Promise.all(newDataPromises);
    
      return returnData;
};

const exportCohort = async (data: any, socials?: any, segments?: any) => {
  const newDataPromises = data.map(async (item: any) => {
    const socialHandles = socials
      ? socials.find((social: any) => social.UserID === item.UserID)
      : null;
    let newItem: DataExport = {
      Profile:
        generateLargestSocial(
          [
            {
              type: 'instagram',
              handle: socialHandles?.instagramHandle || item.instagram,
              followers:
                socialHandles?.InstagramFollowers ||
                item.TotalInstagramFollowers,
              name: item.TalentName,
              industry: item.FirstIndustry,
            },
            {
              type: 'tiktok',
              handle: socialHandles?.tiktokHandle || item.tiktok,
              followers:
                socialHandles?.TiktokFollowers || item.TotalTiktokFollowers,
              name: item.TalentName,
              industry: item.FirstIndustry,
            },
            {
              type: 'twitter',
              handle: socialHandles?.twitterHandle || item.twitter,
              followers:
                socialHandles?.TwitterFollowers || item.TotalTwitterFollowers,
              name: item.TalentName,
              industry: item.FirstIndustry,
            },
            {
              type: 'youtube',
              handle: socialHandles?.youtubeHandle || item.youtube,
              followers:
                socialHandles?.YoutubeSubscribers ||
                item.TotalYoutubeSubscribers,
              name: item.TalentName,
              industry: item.FirstIndustry,
            },
            {
              type: 'facebook',
              handle: socialHandles?.facebookHandle || item.facebook,
              followers:
                socialHandles?.FacebookFollowers || item.TotalFacebookFollowers,
              name: item.TalentName,
              industry: item.FirstIndustry,
            },
          ] || null
        ),
      'Profile Bio': item.UserBio ? item.UserBio : item.Bio ?? null,
    };

    return newItem;
  });

  const returnData = await Promise.all(newDataPromises);

  return returnData;
};


const exportMethodology = async () => {
  let newItem = {
    Brand:
      'Key has integrated into over 60 different 3p data partners to understand, validate, and prioritize which of our Talent 1p Fan audiences are included in each recommendation.',
  };
  return [newItem];
};

const audienceSize = async (socials: any) => {
  let totalSize = 0;
  socials?.map((item: any) => {
    totalSize += item.TotalReach;
  });
  return totalSize;
};

const addBehaviors = async (behaviors: string[] = []) => {
  if (behaviors.length > 0) {
    behaviors?.map(async (item: string) => {
      let newItem = [
        {
          Behaviors: item ?? ' ',
        },
      ];
      return [newItem];
    });
    const returnData = await Promise.all(behaviors);
    return returnData;
  }
};
