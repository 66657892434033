import { FC } from 'react';

interface MediaOptOutProps {
    color?: string;
}

const MediaOptOutIcon: FC<MediaOptOutProps> = ({ color = '#FFFFFF' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.31526 3.6008L0.266335 1.55187L1.55198 0.266224L19.7338 18.448L18.4482 19.7337L16.3992 17.6847C14.6651 19.1303 12.4341 20 10 20C4.47715 20 0 15.5228 0 10C0 7.56588 0.869678 5.33489 2.31526 3.6008ZM15.107 16.3926L13.9282 15.2137C13.693 16.1106 13.3948 16.9155 13.0413 17.5979C13.7911 17.2975 14.4861 16.8894 15.107 16.3926ZM11.3997 12.6853L12.3978 13.6834C11.8968 16.3643 10.9057 18.1818 10 18.1818C8.97861 18.1818 7.8486 15.8704 7.43537 12.6157C8.25096 12.6892 9.10998 12.7273 10.0001 12.7273C10.4714 12.7273 10.939 12.7129 11.3997 12.6853ZM9.62103 10.9066L7.31836 8.6039C7.28856 9.05697 7.27273 9.52336 7.27273 10C7.27273 10.2609 7.27747 10.5187 7.28665 10.7729C8.02535 10.8501 8.80789 10.8957 9.62103 10.9066ZM5.65302 6.93855C5.52285 7.90523 5.45455 8.93442 5.45455 10C5.45455 10.1677 5.45624 10.3344 5.45959 10.5002C4.93787 10.3961 4.45163 10.2735 4.00765 10.1341C2.8798 9.77991 2.14282 9.34579 1.87788 9.00656C2.06491 7.46142 2.68262 6.04902 3.60741 4.89295L5.65302 6.93855ZM6.95868 2.4021C6.79778 2.71271 6.64835 3.04869 6.51111 3.40716L7.94936 4.84541C8.48885 2.99239 9.27201 1.81818 10 1.81818C11.2428 1.81818 12.6464 5.2404 12.7239 9.61997L15.2256 12.1216C16.3412 11.8485 17.3266 11.4952 18.1101 11.0877C17.97 12.1427 17.6288 13.1342 17.1264 14.0225L18.4516 15.3476C19.4322 13.801 20 11.9668 20 10C20 4.47715 15.5228 0 10 0C8.03318 0 6.19899 0.56781 4.65239 1.54845L5.97755 2.8736C6.29222 2.69561 6.61985 2.53785 6.95868 2.4021ZM14.542 10.4129C14.5443 10.2759 14.5455 10.1383 14.5455 10C14.5455 6.97487 13.9949 4.243 13.0413 2.4021C15.747 3.48611 17.7389 5.97349 18.1168 8.96388C17.6889 9.44404 16.3396 10.0144 14.542 10.4129ZM5.57194 12.3734C5.78105 14.4348 6.26242 16.2538 6.95868 17.5979C4.31522 16.5388 2.35302 14.1401 1.91177 11.2415C2.86038 11.7356 4.1121 12.1197 5.57194 12.3734Z" fill={color} />
        </svg>
    );
};

export default MediaOptOutIcon;