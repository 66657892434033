import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

import Loading from '../ui/Loading/Loading';
import { useAuthValue } from 'services/Auth/Auth';
import { userIsAdmin, userIsSales } from '../utils/hooks';

const AdminRoute = ({ component: Component, ...rest }): JSX.Element => {
  const { pathname, search } = window.location;
  const from = `${pathname}${search}`;
  const isAdmin = userIsAdmin();
  const isSales = userIsSales();

  return (
    <Route
      {...rest}
      render={(props): JSX.Element => {
        if (isAdmin === null) return <Loading />;

        if (isAdmin || isSales) return <Component {...props} />;

        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from },
            }}
          />
        );
      }}
    />
  );
};

export default AdminRoute;
