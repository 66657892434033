import { TImageCompressionDetails } from './images';
import { TConfidenceScoreScale } from './insights';

export type Lit = string | number | boolean | undefined | null | void | {};

export const tuple = <T extends Lit[]>(...args: T): T => args;

export const AFTER_LOGIN_REDIRECT_KEY = 'mykeylive-redirect';

export const AFTER_LOGIN_PARAMS_KEY = 'mykeylive-params';

export const AT_LEAST_ONE_LETTER = /(.*[A-Za-z].*)/;

export const AWS_CODE_LENGTH = 6;

export const BENEFIT_LENGTH = 100;

export const CUSTOM_LABEL_LENGTH = 20;

export const DISCOUNT_CODE_LENGTH = 25;

export const CHAT_DEFAULT_AMOUNT = 50;

export const KEYCHAIN_DEFAULT_DOMAIN = 'keychain.club';

export const CAPTURE_LINK_DEFAULT_DOMAIN = 'keydirect.co';

export const SOCIAL_AUDIENCE_OVERLAP_REDIRECT_PAGE = '/social-engagers';

export const ORGANIZATIONS_REDIRECT_PAGE = '/organizations';

export const MIN_TIME_BETWEEN_SOCIAL_MEDIA_SCRAPES_IN_MINUTES = 5;

export const USER_AVATAR_COMPRESSION_DETAILS_JPG: TImageCompressionDetails = {
  height: 200,
  width: 200,
  quality: 100,
  fileType: 'jpg',
};

export const USER_AVATAR_COMPRESSION_DETAILS_PNG: TImageCompressionDetails = {
  height: 200,
  width: 200,
  quality: 100,
  fileType: 'png',
};

export const USER_AVATAR_COMPRESSION_DETAILS_WEBP: TImageCompressionDetails = {
  height: 200,
  width: 200,
  quality: 100,
  fileType: 'webp',
};

export const COHORT_IMAGE_COMPRESSION_DETAILS: TImageCompressionDetails = {
  height: 300,
  width: 300,
  quality: 100,
  fileType: 'jpg',
};

export const SEGMENT_IMAGE_COMPRESSION_DETAILS: TImageCompressionDetails = {
  height: 300,
  width: 300,
  quality: 100,
  fileType: 'jpg',
};

export const USER_AVATAR_NAVBAR_COMPRESSION_DETAILS: TImageCompressionDetails = {
  height: 60,
  width: 60,
  quality: 100,
  fileType: 'jpg',
};

export const KEY_LINK_THUMBNAIL_COMPRESSION_DETAILS: TImageCompressionDetails = {
  height: 400,
  width: 750,
  quality: 100,
  fileType: 'jpg',
};

export const KEYCHAIN_BACKGROUND_COMPRESSION_DETAILS: TImageCompressionDetails = {
  height: 850,
  width: 390,
  quality: 100,
  fileType: 'jpg',
};

export const WEBPAGE_LINK_THUMBNAIL_COMPRESSION_DETAILS: TImageCompressionDetails = {
  height: 150,
  width: 150,
  quality: 100,
  fileType: 'png',
};

export const COLORS = {
  PRIMARY_DEFAULT: '31D7D8',
  SECONDARY_DEFAULT: '168586',
  DEFAULT_FONT: '000000',
  DEFAULT_LINK: 'rgba(255,255,255,0.3)',
};

export const TALENT_PROFILE_SECTION_REQUIRED_STEPS = {
  DEMOGRAPHICS_QUESTIONS: 12,
  BRAND_QUESTIONNAIRE: 9,
  ABOUT_TALENT: 2,
  SOCIAL_MEDIA: 1,
};

export const DESCRIPTION_LENGTH = 1000;

export const KeyLinkTypes = {
  KEYLINK_CONTENT: 'KEYLINK_CONTENT',
  KEYLINK_GENERAL: 'KEYLINK_GENERAL',
  WEBSITE_LINK: 'WEBSITE_LINK',
};

export const HEX_LENGTH = 6;
export const RGBA_LENGTH = 24;

export const DAYS_IN_A_WEEK = 7;

export const HOURS_IN_A_DAY = 24;

export const KEY_LINK_DESCRIPTION_LENGTH = 1000;

export const KEY_LINK_MESSAGE_TO_FANS_LENGTH = 1000;

export const KEY_LINK_NAME_LENGTH = 80;

export const URL_CHAR_LENGTH = 250;

export const CUSTOM_CONSENT_HYPERLINK_LABEL_LENGTH = 80;

export const CUSTOM_CONSENT_CALL_TO_ACTION_LENGTH = 160;

export const CAPPTURE_LINK_URL_LENGTH = 80;

export const CAPPTURE_LINK_REDIRECT_URL_LENGTH = 1000;

export const KEY_LINK_INSTRUCTIONS_DETAILS_LENGTH = 1000;

export const KEYCHAIN_PROFILE_URL_LENGTH = 40;

export const KEYCHAIN_DEFAULT_AVATAR =
  '/assets/images/default-keychain-avatar.svg';

export const KEYCHAIN_GUIDE_STEPS = 3;

export const MILLISECONDS_IN_A_SECOND = 1000;

export const MINUTES_IN_AN_HOUR = 60;

export const MIN_PASSWORD_LENGTH = 6;

export const MY_KEY_LIVE_URL = 'http://www.mykeylive.com';

export const SECONDS_IN_A_MINUTE = 60;

export const SOCIAL_MEDIA_USER_ID_LENGTH = 250;

export const EMBED_LINK_URL_LENGTH = 250;

export const EMBED_LINK_LABEL_LENGTH = 250;

export const SUBMIT_BUTTON = 'submit-button';

export const TALENT_NAME_MAX_LENGTH = 500;

export const TITLE_LENGTH = 80;

export const UPLOAD_EMAIL_KEY = 'emailupload/Upload_Email_List_Template.csv';

export const UPLOADED_GROUP_NAME_LENGTH = 80;

export const URL_REGEX = /^(?:http(s)?:\/\/)?[/^\S*$/]+$/;

export const APPLE_MUSIC_REGEX = /^https:\/\/music\.apple\.com\/+/;

export const APPLE_PODCAST_REGEX = /^https:\/\/podcasts\.apple\.com\/+/;

export const SPOTIFY_MUSIC_REGEX = /^https:\/\/open\.spotify\.com\/track\/+|^https:\/\/open\.spotify\.com\/artist\/+|^https:\/\/open\.spotify\.com\/album\/+/;

export const SPOTIFY_PODCAST_REGEX = /^https:\/\/open\.spotify\.com\/episode\/+|^https:\/\/open\.spotify\.com\/show+\//;

export const TIDAL_REGEX = /^https:\/\/tidal\.com\/+/;

export const YOUTUBE_REGEX = /^https:\/\/www\.youtube\.com\/+|^https:\/\/youtu\.be\/+/;

export const YOUTU_BE_REGEX = /^https:\/\/youtu\.be\/+/;

export const VIMEO_REGEX = /^https:\/\/vimeo\.com\/+/;

export const TWITCH_REGEX = /^https:\/\/www\.twitch\.tv\/+/;

export const TIKTOK_REGEX = /^https:\/\/www\.tiktok\.com\/+/;

export enum KeychainEmbedSourceIDs {
  APPLE_MUSIC = 5,
  APPLE_PODCAST = 7,
  TIDAL = 8,
  YOUTUBE = 9,
  VIMEO = 10,
  TWITCH = 11,
  TIKTOK = 12,
  SPOTIFY_MUSIC = 13,
  SPOTIFY_PODCAST = 14,
}
export const SIGN_UP_EMAIL = 'sign-up-email';

export const TEMPORARY_PASSWORD = 'temporary-password';

export const TEMPORARY_PASSWORD_LENGTH = 12;

export const WEBINAR_YEAR = 2037;

export const TOP_BEHAVIORS = 'Top Behaviors';

export const BOTTOM_BEHAVIORS = 'Bottom Behaviors';

export const CONFIDENCE_SCORE_SCALE: TConfidenceScoreScale =
  process.env.REACT_APP_ENV === 'production'
    ? {
      breakpoints: [200, 300, 500, 1000, 5000, 15000, 25000, 35000, 45000],
      scores: [0.7, 0.8, 0.85, 0.9, 0.95, 0.96, 0.97, 0.98, 0.99],
    }
    : {
      breakpoints: [10, 30, 50, 100, 500, 1500, 2500, 3500, 4500],
      scores: [0.7, 0.8, 0.85, 0.9, 0.95, 0.96, 0.97, 0.98, 0.99],
    };

export const CONFIDENCE_SCORE_THRESHOLD = CONFIDENCE_SCORE_SCALE.scores[0];

export const MONTHLY_SUBSCRIPTION_VALUE = 250;

export const AUDIENCE_INSIGHTS = {
  GENDER: 'gender',
  AGE: 'age',
  INCOME: 'income',
  EDUCATION: 'education',
  TOP_BEHAVIORS: 'topbehaviors',
  TOP_BEHAVIORS_TAB: {
    CPG: 'CPG',
    AUTOMOTIVE: 'Automotive',
    LIFESTYLE: 'Lifestyle',
  },
  TOP_REGIONS_USDMA: 'topregionsusdma',
  TOP_REGIONS_COUNTRIES: 'topregionscountries',
  TOP_REGIONS_TABS: {
    USDMA: 'US DMAs',
    COUNTRIES: 'Countries',
  },
};

export const TABLE_ROWS_LIMIT = 25;

export const DEMOGRAPHIC_TYPES = [
  { label: 'Age Composition', id: 'Age' },
  { label: 'Ethnicity', id: 'Ethnicity' },
  { label: 'Gender', id: 'Gender' },
];

export const GELOCATION_TYPES = [
  { label: 'Top Regions Countries', id: 'TOPREGIONSCOUNTRIES' },
  { label: 'Top Regions US DMA', id: 'TOPREGIONSUSDMA' },
];

export const INCOME_EDUCATION_TYPES = ['Income', 'Education'];

export const DEMOGRAPHICS_CATEGORIES = [
  {
    name: 'Demographics',
    type: 'DEMOGRAPHICS',
    subCategories: [
      {
        id: 'Age',
        name: 'Age Composition',
      },
      {
        id: 'Ethnicity',
        name: 'Ethnicity',
      },
      {
        id: 'Gender',
        name: 'Gender',
      },
    ],
  },
  {
    name: 'Income/Education',
    type: 'DEMOGRAPHICS',
    subCategories: [
      {
        id: 'Education',
        name: 'Education',
      },
      {
        id: 'Income',
        name: 'Income',
      },
    ],
  },
  {
    name: 'Geolocation',
    type: 'DEMOGRAPHICS',
    subCategories: [
      {
        id: 'City',
        name: 'Cities',
      },
      {
        id: 'State',
        name: 'US States',
      },
      {
        id: 'Country',
        name: 'Countries',
      },
      {
        id: 'DMA',
        name: 'DMAs',
      },
    ],
  },
];

export const DEFAULT_ERROR_MESSAGE =
  'Something went wrong, please refresh the page and try again';

export const EMAIL_UPLOAD_COLUMN_OPTIONS = {
  EMAIL: 'Email',
  FNAME: 'First Name',
  LNAME: 'Last Name',
  PHONE: 'Phone Number',
  IGNORE: 'Ignore Column',
  SELECT: 'Select Header',
};

export const TEXT_COLOR = {
  DARK: 'Dark',
  LIGHT: 'Light',
};

export const PAGE_BACKGROUND_TYPES = {
  GRADIENT: 'Gradient',
  SOLID: 'Solid',
  IMAGE: 'Upload Image',
};

export const KEYLINK_BACKGROUND_TYPES = {
  GRADIENT: 'Gradient',
  SOLID: 'Solid',
  IMAGE: 'Upload Image',
};

export const YES_NO = {
  YES: 'Yes',
  NO: 'No',
};

export const NOW_LATER = {
  NOW: 'Now',
  LATER: 'Later',
};

export const PHOTO_SHAPES = {
  CIRCLE: 'Circle',
  RECTANGLE: 'Rectangle',
};

export const PHOTO_SHAPE_OPTIONS = {
  PROFILE: 'Profile Picture',
  HEADER: 'Header',
};

export const FONT_OPTIONS = {
  CAVEAT: 'Caveat',
  CRIMSON_TEXT: 'Crimson Text',
  DANCING_SCRIPT: 'Dancing Script',
  LOBSTER: 'Lobster',
  MARK_PRO: 'Mark Pro',
  NOTO_SERIF: 'Noto Serif',
  OSWALD: 'Oswald',
  PACIFICO: 'Pacifico',
  RALEWAY: 'Raleway',
  SHADOWS_INTO_LIGHT_TWO: 'Shadows Into Light Two',
  ROBOTO_MONO: 'Roboto Mono',
  ROBOTO_SLAB: 'Roboto Slab',
  ANTON: 'Anton',
  ARCHIVO_BLACK: 'Archivo Black',
};

export enum UPLOAD_TYPES {
  SINGLE = 'SINGLE',
  ALL = 'ALL',
}

export enum LINK_TYPES {
  KEYLINKS = 'KEYLINKS',
  WEBSITES = 'WEBSITE_LINKS',
  SOCIAL_LINKS = 'SOCIAL_LINKS',
  EMBED_LINKS = 'EMBED_LINKS',
}
