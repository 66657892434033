import * as FullStory from '@fullstory/browser';
import React from 'react';
import { render } from 'react-dom';

import App from './App';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import './i18n';
import 'react-toastify/dist/ReactToastify.css';

FullStory.init({ orgId: `${process.env.REACT_APP_FULLSTORY_ORG_ID}` });

(async (): Promise<void> => {
  render(<App />, document.getElementById('root'));

  serviceWorker.register();
})();
