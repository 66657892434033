import { FC } from 'react';

interface VerifiedProps {
    color?: string;
}

const VerifiedIcon: FC<VerifiedProps> = ({ color = '#31D7D8' }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"  style={{marginBottom: '-2px',marginLeft: '6px'}}>
            <path
                id="icon"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.92893 17.0711C2.08874 16.2309 1.8477 14.9493 2.11119 13.2677C0.735797 12.2649 0 11.1882 0 10C0 8.81179 0.735797 7.73511 2.11119 6.73235C1.8477 5.05074 2.08874 3.76913 2.92893 2.92893C3.76913 2.08874 5.05074 1.8477 6.73235 2.11119C7.73511 0.735797 8.81179 0 10 0C11.1882 0 12.2649 0.735797 13.2677 2.11119C14.9493 1.8477 16.2309 2.08874 17.0711 2.92893C17.9113 3.76913 18.1523 5.05074 17.8888 6.73235C19.2642 7.73511 20 8.81179 20 10C20 11.1882 19.2642 12.2649 17.8888 13.2677C18.1523 14.9493 17.9113 16.2309 17.0711 17.0711C16.2309 17.9113 14.9493 18.1523 13.2677 17.8888C12.2649 19.2642 11.1882 20 10 20C8.81179 20 7.73511 19.2642 6.73235 17.8888C5.05074 18.1523 3.76913 17.9113 2.92893 17.0711ZM6.95689 15.9982C7.32592 15.9191 7.7054 16.0763 7.91046 16.3931C8.71115 17.6303 9.42512 18.1818 10 18.1818C10.5749 18.1818 11.2889 17.6303 12.0895 16.3931C12.2946 16.0763 12.6741 15.9191 13.0431 15.9982C14.484 16.3068 15.3789 16.1919 15.7854 15.7854C16.1919 15.3789 16.3068 14.484 15.9982 13.0431C15.9191 12.6741 16.0763 12.2946 16.3931 12.0895C17.6303 11.2889 18.1818 10.5749 18.1818 10C18.1818 9.42512 17.6303 8.71115 16.3931 7.91046C16.0763 7.7054 15.9191 7.32592 15.9982 6.95689C16.3068 5.51595 16.1919 4.62108 15.7854 4.21458C15.3789 3.80808 14.484 3.69324 13.0431 4.00183C12.6741 4.08086 12.2946 3.92368 12.0895 3.60685C11.2889 2.36974 10.5749 1.81818 10 1.81818C9.42512 1.81818 8.71115 2.36974 7.91046 3.60685C7.7054 3.92368 7.32592 4.08086 6.95689 4.00183C5.51595 3.69324 4.62108 3.80808 4.21458 4.21458C3.80808 4.62108 3.69324 5.51595 4.00183 6.95689C4.08086 7.32592 3.92368 7.7054 3.60685 7.91046C2.36974 8.71115 1.81818 9.42512 1.81818 10C1.81818 10.5749 2.36974 11.2889 3.60685 12.0895C3.92368 12.2946 4.08086 12.6741 4.00183 13.0431C3.69324 14.484 3.80808 15.3789 4.21458 15.7854C4.62108 16.1919 5.51595 16.3068 6.95689 15.9982ZM12.9936 6.62997L9.09098 10.5326L7.00653 8.44815L5.72088 9.7338L9.09098 13.1039L14.2793 7.91562L12.9936 6.62997Z"
                fill={color}
            />
        </svg>
    );
};

export default VerifiedIcon;