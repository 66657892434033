export const APP_VERSION = 'APP_VERSION';

export const getLatestAppVersion = async (): Promise<
  string | null | undefined
> => {
  try {
    const response = await fetch('meta.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    const parsedResponse = await response.json();

    return parsedResponse.version;
  } catch (error) {
    window.Rollbar.error('AppVersionGetLatestVersion', { error });
  }
};

export const clearCacheAndReload = async (): Promise<void> => {
  try {
    const remoteVersion = await getLatestAppVersion();

    if (!remoteVersion) {
      return;
    }

    try {
      if ('caches' in window) {
        const cacheKeys = await window.caches.keys();

        await Promise.all(cacheKeys.map(key => window.caches.delete(key)));
      }
    } catch (error) {
      window.Rollbar.error('AppVersionClearCacheAndReload_ClearCache', {
        error,
      });
    }

    localStorage.setItem(APP_VERSION, remoteVersion);

    window.location.replace(window.location.href);
  } catch (error) {
    window.Rollbar.error('AppVersionClearCacheAndReload', { error });
  }
};
