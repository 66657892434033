import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styles from './LookAlike.module.scss';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { TalentLookALikeResults } from 'API';
import { CircularProgress, Dialog } from '@mui/material';
import { getTalentSearchTitleQuery, searchForLookAlikes } from './Utils/DataFetchers';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { getTalentLookALikeMatches, getTalentLookALikeResults } from 'graphql/queries';
import { LookAlikeSettingContext, useLookAlikeSettings } from './LookALikeSettings';
import { ExcelExport } from 'utils/excel';
import NoEmailIcon from 'ui/Icons/NoEmailIcon';
import MediaOptOutIcon from 'ui/Icons/MediaOptOut';

interface LocationState {
    searchName?: string;
}

const LookAlikeResult: React.FC = () => {

    const location = useLocation<LocationState>();
    const history = useHistory();
    const { t } = useTranslation();
    const client = useApolloClient();
    const [selectedProfiles, setSelectedProfiles] = React.useState<number[]>([]);
    const [searchTitleState, setSearchTitleState] = React.useState<string>(location?.state?.searchName || '');
    const searchIDString = useParams<{ id: string }>().id;
    const searchID: number = parseInt(searchIDString);
    const [settingsOpen, setSettingsOpen] = useState<boolean>(false);


    const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

    if (isNaN(searchID)) {
        history.push('/talent-discovery');
    }

    useEffect(() => {
        if (searchTitleState === '') {
            (async () => {
                let dbTitle: string | null | undefined = await getTalentSearchTitleQuery(client, searchID);
                setSearchTitleState(dbTitle ?? "");
            }
            )();
        }
    }, []);


    const { data, error, loading } = useQuery(
        gql(getTalentLookALikeResults), {
        variables: {
            TalentLookALikeID: searchID,
        },
    });


    const handleSelection = (id: number) => {
        if (selectedProfiles.includes(id)) {
            setSelectedProfiles(selectedProfiles.filter(profileId => profileId !== id));
            return;
        } else {
            setSelectedProfiles([...selectedProfiles, id]);
        }
    }

    const handleAllSelection = () => {
        if (selectedProfiles.length === data?.getTalentLookALikeResults?.length) {
            setSelectedProfiles([]);
            return;
        } else {
            setSelectedProfiles(data?.getTalentLookALikeResults?.map(result => result.TalentLookALikeResultID));
        }
    }



    return (
        <LookAlikeSettingContext.Provider value={{ showAdditionalInfo, setShowAdditionalInfo }}>
            <div className={clsx(styles.container, styles.resultContainer)}>


                <div className={styles.titleContainer}>
                    <div className={styles.title}>{t('lookalikes.result.page-title')}</div>
                </div>


                <div className={styles.breadcrumbs}>
                    <div className={clsx(styles.breadcrumb, styles.link)} onClick={() => { history.push("/talent-discovery") }} >Talent Discovery</div>
                    <div className={styles.breadcrumbSeparator}> {`> `}</div>
                    <div className={styles.breadcrumb}> {searchTitleState}</div>
                </div>


                <div className={clsx(styles.header)}>

                    <div className={styles.buttonContainer}>
                        <button disabled={selectedProfiles.length === 0} className={styles.noStyle} onClick={() => { ExcelExport({ profiles: selectedProfiles, fileName: searchTitleState, data: data?.getTalentLookALikeResults, client: client }) }}>
                            {t('lookalikes.result.export')}
                        </button>
                        <div className={styles.divider}>|</div>
                        <button className={clsx(styles.noStyle)} onClick={() => { setSettingsOpen(!settingsOpen) }}>
                            {t('lookalikes.result.page-settings')}
                        </button>
                    </div>
                </div>


                {error ? <>
                    <div className={styles.errorContainer}>
                        {`Error Fetching Results: ${error.message}`}
                    </div>
                </> :
                    loading ?
                        <div className={styles.loadingContainer}>
                            <CircularProgress size={50} thickness={5} sx={{
                                color: "#FFFFFF"
                            }} />
                        </div> :
                        <SearchResultTable
                            searchResults={data?.getTalentLookALikeResults || []}
                            handleSelection={handleSelection}
                            handleAllSelection={handleAllSelection}
                            selectedProfiles={selectedProfiles}
                        />
                }


            </div>
            <Dialog
                open={settingsOpen}
                onClose={() => setSettingsOpen(false)}
            >
                <div className={clsx(styles.settingsModalContainer, styles.modalContainer)}>
                    <p className={styles.settingsModalHeading}>Page Settings</p>
                    <div className={styles.modalToggle}>
                        <div
                            className={clsx(styles.checkbox, showAdditionalInfo ? styles.selected : '')}
                            onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}>
                        </div>
                        <p onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}>Show Matching Handles</p>
                    </div>
                    <div className={styles.ctaContainer}>
                        <button className={styles.primaryButton} onClick={() => setSettingsOpen(false)}>Done</button>
                    </div>
                </div>

            </Dialog>
        </LookAlikeSettingContext.Provider>
    );
};


const SearchResultTable: React.FC<{
    searchResults: TalentLookALikeResults[],
    handleSelection: (id: number) => void,
    handleAllSelection?: () => void,
    selectedProfiles: number[]
}> = ({ searchResults, handleSelection, handleAllSelection, selectedProfiles }) => {

    const { t } = useTranslation();

    return (
        <div className={styles.tableContainer}>
            <div className={styles.searchTableHeader}>
                <div className={styles.talentName}>{t(`lookalikes.result.talent`)}</div>
                <div className={styles.handleName}>{t(`lookalikes.result.handle`)}</div>
                <div className={styles.targetable}>{t(`lookalikes.result.targetable-audience`)}</div>
                <div className={styles.selectRow}><button onClick={handleAllSelection}>{selectedProfiles.length === searchResults.length ? t(`lookalikes.result.unselect`) : t(`lookalikes.result.select-all`)}</button></div>
            </div>
            <div className={styles.searchTableBody}>
                {searchResults.map((item) => {

                    return (
                        <SearchResultRow item={item} key={item.TalentLookALikeResultID} handleSelection={handleSelection} selectedProfiles={selectedProfiles} />
                    )
                })}
            </div>
        </div >
    )
}


const SearchResultRow: React.FC<{
    handleSelection: (id: number) => void,
    handleAllSelection?: () => void,
    selectedProfiles: number[],
    item: TalentLookALikeResults
}> = ({ handleSelection, handleAllSelection, selectedProfiles, item }) => {
    const { t } = useTranslation();

    const [status, setStatus] = useState<string>(item?.Status ?? "");
    const { showAdditionalInfo } = useLookAlikeSettings();

    useEffect(() => {
        setStatus(item?.Status ?? "");
    }, [item]);


    const client = useApolloClient();

    useEffect(() => {
        (async () => {
            if (status === "PENDING" && item.Instagram) {
                let result = await searchForLookAlikes(client, item.Instagram, item.TalentLookALikeResultID);
                if (result?.data?.findTalentLookAlikes) {
                    setStatus(JSON.parse(result?.data?.findTalentLookAlikes).status);
                }

            }
        })()
    }, [status]);

    const numberOfEmails = useMemo(() => item?.Matches?.reduce((acc, match) => acc + (match?.IsMediaOptIn ? match?.TotalEmails ?? 0 : 0), 0),
        [item?.Matches]);

    return (
        <div className={styles.searchTableRow} key={item.TalentLookALikeResultID}>
            <div className={styles.talentName}>
                {item.CreatorName}
            </div>
            <div className={styles.handleContainer}>
                <div className={styles.handleName}>
                    <p>@{item.Instagram}</p>
                    <a target='_blank' href={`https://www.instagram.com/${item.Instagram}`}><img src='/assets/images/open-in-new-window.svg' /></a>
                </div>
                {showAdditionalInfo &&
                    <div className={styles.handleInfo}>
                        {status === "MISSING" ? <p className={styles.missing}>{t(`lookalikes.result.missing`)}</p> :
                            showAdditionalInfo && status === "LOCAL" ? <p>{t(`lookalikes.result.found-key`)} <span>Key's</span> {t(`lookalikes.result.database`)}</p> :
                                showAdditionalInfo && status === "INSIGHTIQ" && item?.Matches ?
                                    <div className={styles.matchContainer}>
                                        <p>{t(`lookalikes.result.match-1`)}&nbsp;</p>

                                        {item?.Matches?.map((match, index) => {
                                            if (match === null) { return <></> }
                                            return <div className={styles.match}><p>{match.UserFirstName} {match.UserLastName}</p>
                                                <div className={styles.iconContainer}>
                                                    {match.IsMediaOptIn ?
                                                        (match.TotalEmails ?? 0) > 0 ?
                                                            <div className={styles.emailCount}><img src='/assets/images/email-grey.svg' /> {match.TotalEmails}</div>
                                                            : <div className={styles.noEmailSVGContainer}><NoEmailIcon /></div>
                                                        : <MediaOptOutIcon />}

                                                </div>
                                            </div>
                                        })}
                                    </div>

                                    : <></>}

                    </div>
                }

            </div>
            <div className={styles.targetable}>
                {
                    status === "PENDING" ? <CircularProgress size={22} thickness={5} sx={{
                        color: "#FFFFFF"
                    }} /> :

                        (status === "INSIGHTIQ" && (numberOfEmails ?? 0 > 0)) || status === "LOCAL" ? <div className={clsx(styles.targetChip, styles.yesChip)}><img src='/assets/images/audio-wave.svg' />{t(`lookalikes.result.targetable-yes`)}</div> :
                            <div className={clsx(styles.targetChip, styles.noChip)}><img src='/assets/images/stop.svg' />{t(`lookalikes.result.targetable-no`)}</div>
                }
            </div>
            <div className={styles.selectRow}>
                <div
                    className={clsx(styles.checkbox, selectedProfiles.includes(item.TalentLookALikeResultID) ? styles.selected : '')}
                    onClick={() => handleSelection(item.TalentLookALikeResultID)}>
                </div>
            </div>
        </div >
    )
}

export default LookAlikeResult;