import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAuthValue } from '../services/Auth/Auth';
import Loading from '../ui/Loading/Loading';
import QueryError from '../ui/QueryError/QueryError';
import { getCurrentUser } from 'graphql/queries';
import { gql, useQuery } from '@apollo/client';
import { GetCurrentUserQuery, GetCurrentUserQueryVariables } from 'API';


const AuthedRoute = ({ component: Component, ...rest }):JSX.Element => {
  const context = useAuthValue();
  const { pathname, search } = window.location;
  const from = `${pathname}${search}`;
  const params = new URLSearchParams(window.location.search);
  const talentIDParam = Number(params.get('talentID'));
  const [talent, setTalent] = useState<any>(null);
  const [talentError, setTalentError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  
  if (talentIDParam) {
    const { data, error, loading } = useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(gql(getCurrentUser), {
      fetchPolicy: 'network-only',
      variables: { UserID: talentIDParam },
    });

    if (loading) {
      setLoading(true);
    }

    if (error || !data) {
      setTalentError(error);
    }

    if (data?.getCurrentUser) {
      setTalent(data.getCurrentUser);
    }

  }

  return (
    <Route
      {...rest}
      render={(props): JSX.Element => {
        if (context.isSignedIn) {
          if (context.dbUser?.UserIsAdmin || context.dbUser?.UserIsManager
            || (!context.dbUser?.UserIsAdmin && 
                !context.dbUser?.UserIsManager && 
                !context.dbUser?.UserIsTalent && 
                !context.dbUser?.OrganizationID)
          ) {
            if (talentIDParam) {
              if (loading) {
                return <Loading />;
              } else if (talent && !talentError) {
                const talentIsPartOfOrganization =
                      context.dbUser?.OrganizationID === talent.OrganizationID;

                    if (!talent.UserIsTalent || !talentIsPartOfOrganization) {
                      return (
                        <>
                          <QueryError
                            error={talentError}
                            parentName="AuthedRoute"
                            privateMessage="From AuthedRoute"
                            defaultMessage="This operation is not permitted!"
                          />
                          <Redirect to="/" />
                        </>
                      );
                    }

                    if (!talent.IsActive) {
                      return (
                        <>
                          <QueryError
                            error={talentError}
                            parentName="AuthedRoute"
                            privateMessage="From AuthedRoute"
                            defaultMessage="User is inactive"
                          />
                          <Redirect to="/" />
                        </>
                      );
                    }

                    return <Component {...props}></Component>;
              } else {
                return (
                  <>
                    <QueryError
                      error={talentError}
                      parentName="AuthedRoute"
                      privateMessage="From GetCurrentUserQuery"
                    />
                    <Redirect to="/" />
                  </>
                );
              }
            } else {
              return <Component {...props}></Component>;
            }
          } else {
            return <Component {...props}></Component>;
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from },
              }}
            />
          );
        }
      }}
    />
  );
};

export default AuthedRoute;
