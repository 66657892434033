import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import clsx from 'clsx';
import i18next from 'i18next';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ConfirmationDialog.module.scss';

export enum CloseAction {
  EscapeKey,
  Backdrop,
  Button,
}

export const DialogType = {
  Light: 'light',
  PrimaryBrand: 'primaryBrand',
  PrimaryBrandYellow: 'primaryBrandYellow',
  Dark: 'dark',
};

type ValueOf<T> = T[keyof T];

interface ConfirmationDialog {
  data: {
    title: string | JSX.Element;
    content: string | JSX.Element;
  };
  cancelText?: string;
  classNames?: { dialogText?: string; cancelText?: string };
  confirmDisabled?: boolean;
  confirmText?: string | JSX.Element;
  imageUrl?: string;
  isOpen: boolean;
  type?: ValueOf<typeof DialogType>;
  onClose: (action: CloseAction) => void;
  onConfirm?: () => void;
  showCancel?: boolean;
  cancelColor?: string;
  showUploadingImage?: boolean;
}

const ConfirmationDialog: FC<ConfirmationDialog> = ({
  cancelText = i18next.t('confirmation-dialog.cancel'),
  confirmText = i18next.t('confirmation-dialog.confirm'),
  confirmDisabled = false,
  data: { title, content },
  imageUrl,
  isOpen,
  type = DialogType.PrimaryBrand,
  onClose,
  onConfirm,
  showCancel = true,
  classNames,
  cancelColor = '#fff',
  showUploadingImage,
}): JSX.Element => {
  const { t } = useTranslation();

  const muiTheme = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
          rounded: {
            borderRadius: 0,
          },
        }
        
      },
      MuiDialog: {
        styleOverrides: {
          root: { backdropFilter: 'blur(10px)' },
        }
        
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: '10px',
            flex: '1 1 auto',
            color: type === DialogType.Dark ? '#fff' : '#141a1c',
            fontFamily: 'MarkPro',
            fontSize: '16px',
            lineHeight: '19px',
            letterSpacing: '.05em',
            cursor: 'pointer',
          },

        }
        
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            justifyContent: showCancel ? 'space-between' : 'flex-end',
          },

        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: 'MarkPro',
            fontSize: '16px',
            lineHeight: '19px',
            letterSpacing: '.05em',
            cursor: 'pointer',
            textTransform: type === DialogType.Dark ? 'uppercase' : 'initial',
            color: type === DialogType.Dark ? '#31D7D8' : '#141a1c',
          },
        }
      },
      
    

    }
      
  });

  return (
    <MuiThemeProvider theme={muiTheme}>
      <Dialog
        open={isOpen}
        onClose={(e: any, reason: string): void => onClose(reason === 'escapeKeyDown' ? CloseAction.EscapeKey : CloseAction.Backdrop)}
      >
        <div
          className={clsx(
            styles.dialogWrapper,
            type === DialogType.Light && styles.lightDialog,
            type === DialogType.PrimaryBrandYellow && styles.primaryBrandYellow,
            type === DialogType.Dark && styles.darkDialog,
          )}
        >
          {/* <img alt="uploading" src="/assets/images/export-uploading.svg" /> */}
          <div
            className={
              type === DialogType.Dark
                ? styles.darkDialogHead
                : styles.dialogHead
            }
          >
            <DialogTitle
              //disableTypography // TODO - disabled typography
              id="alert-dialog-title"
              className={type === DialogType.Dark ? styles.darkDialogHead : ''}
            >
              {showUploadingImage && (
                <img
                  alt="uploading"
                  src="/assets/images/export-uploading.svg"
                  className={styles.uploadingImage}
                />
              )}
              {title}
            </DialogTitle>
          </div>
          <DialogContent>
            {imageUrl && (
              <img
                alt={t('alt-text.dialog-image')}
                src={imageUrl}
                className={styles.dialogImage}
              />
            )}

            <div
              id="alert-dialog-description"
              className={clsx(
                type === DialogType.Dark
                  ? styles.darkDialogText
                  : classNames?.dialogText || styles.dialogText,
              )}
            >
              {content}
            </div>
          </DialogContent>
          <DialogActions>
            {showCancel && (
              <Button
                onClick={(): void => onClose(CloseAction.Button)}
                className={classNames?.cancelText || styles.cancelText}
                style={{ color: cancelColor }}
              >
                {cancelText}
              </Button>
            )}
            {confirmText && onConfirm && (
              <Button
                disabled={confirmDisabled}
                className={clsx(
                  type === DialogType.Dark
                    ? styles.darkDialogButton
                    : confirmDisabled && styles.disabled,
                )}
                autoFocus
                onClick={onConfirm}
                data-testid="confirm-dialog"
              >
                {confirmText}
              </Button>
            )}
          </DialogActions>
        </div>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default ConfirmationDialog;
