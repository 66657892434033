import React, { useState } from 'react';
import styles from './LookAlike.module.scss';

import { useTranslation } from 'react-i18next';
import parentStyles from '../TalentDiscovery.module.scss'
import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';

import { Alert, CircularProgress, Modal } from '@mui/material';
import { useApolloClient } from '@apollo/client';
import { TalentLookAlikeInput } from 'API';
import { addToTalentLookALikes, debouncedSearch } from './Utils/DataFetchers';
import { useAuthValue } from 'services/Auth/Auth';
import { largeNumberFormatter } from 'utils/number';
import VerifiedIcon from 'ui/Icons/VerifiedIcon';



const NewLookAlikeSearch: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedProfiles, setSelectedProfiles] = useState<Profile[]>([]);
    const { t } = useTranslation();
    const history = useHistory();
    const [searchResults, setSearchResults] = useState<Profile[]>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [searchName, setSearchName] = useState<string>('');
    const [searchInProgress, setSearchInProgress] = useState<boolean>(false);
    const [searchError, setSearchError] = useState<string>('');
    const [addingError, setAddingError] = useState<string>('');
    const [phylloSearchInProgress, setPhylloSearchInProgress] = useState<boolean>(false);
    const { dbUser } = useAuthValue();

    const client = useApolloClient();

    const handleClick = (result: any) => {
        if (!selectedProfiles.some(profile => profile.user_id === result.user_id)) {
            setSelectedProfiles((profiles) => [...profiles, result]);
        }

        setSearchTerm('');
        setSearchResults([]);
    }

    const handleSearch = (results: []) => {
        if (results.length > 0) {
            setSearchError('');
            setSearchResults(results);
        } else {
            setSearchError('No results found');
        }
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        setSearchTerm(value);

        if (value === '') {
            setSearchResults([]);
        } else {
            debouncedSearch(client, value, handleSearch);
        }
    };

    const handleSearchClick = async () => {

        setSearchInProgress(true);


        try {
            if (dbUser === null) {
                throw new Error('User not found');
            }

            let loggedInUserID = dbUser.UserID;

            const input: TalentLookAlikeInput = {
                TalentLookALikeName: searchName,
                AddedBy: loggedInUserID,
                PeopleToSearch: selectedProfiles.map((profile) => {
                    return {
                        Instagram: profile.username,
                        CreatorName: profile.fullname,
                        ProfileURL: profile.picture,
                    }
                })
            }

            let searchID = await addToTalentLookALikes(client, input)
            if (!searchID) {
                throw new Error('Error creating search')
            }
            history.push(`/talent-discovery/talent-lookalike/${searchID}`,
                { searchName: searchName }
            )

        } catch (error) {
            setSearchInProgress(false);
            setAddingError('Error creating search');
        }

    }

    return (
        <div className={styles.container}>

            <div className={styles.titleContainer}>
                <div className={styles.title}>{t('lookalikes.search.page-title')}</div>
            </div>

            <div className={styles.breadcrumbs}>
                <div className={clsx(styles.breadcrumb, styles.link)} >
                    <Link to="/talent-discovery">
                        Talent Discovery
                    </Link>
                </div>
                <div className={styles.breadcrumbSeparator}> {`> `}</div>
                <div className={styles.breadcrumb}> {`Lookalike Search`}</div>
            </div>

            <div className={styles.searchContainer}>
                <h3 className={styles.searchTitle}>{t('lookalikes.search.block-title')}</h3>
                <div className={styles.searchBoxContainer}>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder={t('lookalikes.search.search-placeholder')}
                    />
                    {searchError !== '' &&
                        <div className={styles.searchResults}>
                            <div className={styles.searchResult}>
                                {searchError}
                            </div>
                        </div>
                    }
                    {searchResults.length > 0 &&
                        <SearchResults searchResults={searchResults} callback={handleClick} />
                    }
                    {phylloSearchInProgress &&
                        <div className={styles.searchResults}>
                            <div className={styles.searchResult}>
                                <CircularProgress size={14} thickness={6} className={styles.progressIndicator} />
                            </div>
                        </div>
                    }
                </div>



                <div
                    className={
                        clsx(
                            styles.selectedProfiles,
                            selectedProfiles.length > 0 ? styles.selectedProfilesVisible : styles.emptyProfileSet
                        )}>
                    {selectedProfiles.length === 0 && (
                        <div className={styles.emptyProfileSet}>
                            {t('lookalikes.search.no-selected-profiles')}
                        </div>
                    )}
                    {selectedProfiles.map((profile, index) => (
                        <div key={index} className={styles.selectedProfile}
                            onClick={() => {
                                setSelectedProfiles((profiles) =>
                                    profiles.filter((_, i) => i !== index)
                                );
                            }}>
                            {profile.fullname}
                            <img src="/assets/images/close-white-icon.svg" alt="remove" />
                        </div>
                    ))}
                </div>



                <div className={styles.buttonsContainer}>
                    <button
                        disabled={selectedProfiles.length === 0}
                        onClick={() => setSelectedProfiles([])}
                    >
                        {t('lookalikes.search.clear-search')}
                    </button>
                    <button
                        className={styles.searchButton}
                        disabled={selectedProfiles.length === 0}
                        onClick={() => {
                            let searchName = '';
                            selectedProfiles.forEach((profile, index) => {
                                searchName += profile.fullname;
                                if (index < selectedProfiles.length - 1) {
                                    searchName += ', ';
                                }
                            })
                            setSearchName(searchName)
                            setModalOpen(true)
                        }}
                    >
                        {t('lookalikes.search.run-search')}
                    </button>
                </div>
            </div>



            <Modal
                open={modalOpen}
                onClose={() => { }}
            >
                <div className={styles.modalContainer}>
                    <h3>{t('lookalikes.search.search-name')}</h3>
                    <p>{t('lookalikes.search.search-description')}</p>
                    <input type='text'
                        value={searchName}
                        onChange={(e) => { setSearchName(e.target.value) }}
                    />
                    <div className={styles.modalButtons}>
                        <button onClick={() => { setModalOpen(false); setAddingError('') }}>{t('lookalikes.search.return-button')}</button>
                        <button
                            className={styles.searchButton}
                            onClick={handleSearchClick}
                            disabled={searchInProgress}
                        >
                            {searchInProgress ? <CircularProgress size={14} thickness={6} className={styles.progressIndicator} /> : `${t('lookalikes.search.proceed-button')}`}

                        </button>
                    </div>
                    <div className={styles.errorMessageContainer}>
                        {addingError !== '' &&
                            <Alert severity='error' className={styles.errorText}>
                                {addingError}
                            </Alert>
                        }
                    </div>
                </div>
            </Modal>


        </div>
    );
};


const SearchResults: React.FC<{ searchResults: Profile[], callback: (result: Profile) => any }> = ({ searchResults, callback }) => {
    return (
        <div className={styles.searchResults}>
            {searchResults.map((result) => (
                <div className={styles.searchResult} key={result.user_id} onClick={() => {
                    callback(result);
                }}>
                    <img src={result.picture} alt={result.fullname} />
                    <div className={styles.resultText}>
                        <div className={styles.name}>
                            {result.fullname}
                            {result.is_verified && <VerifiedIcon />}
                        </div>
                        <div className={styles.resultMeta}>
                            <div className={styles.handle}>@{result.username}</div>
                            <div className={styles.separator}>|</div>
                            <div className={styles.followCount}>{largeNumberFormatter(result.followers)}</div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )

}

type Profile = {
    user_id: string;
    username: string;
    fullname: string;
    picture: string;
    followers: number;
    is_verified: boolean;
}


export default NewLookAlikeSearch;



