import React, { FC, useContext } from 'react';

import { IKeyLinkContext } from '../../interfaces/context';

export const KeyLinkContext = React.createContext<IKeyLinkContext>({
  keyLinkID: undefined,
  saveKeyLinkID: () => {},
});

interface KeyLinkProviderProps {
  children?: React.ReactNode;
}

  const KeyLinkProvider: FC<KeyLinkProviderProps> = ({ children }): JSX.Element => {
  const [keyLinkID, setKeyLinkID] = React.useState<number>();

  const saveKeyLinkID = (id?: number) => {
    if (id) {
      setKeyLinkID(id);
    }
  };

  return (
    <KeyLinkContext.Provider value={{ keyLinkID, saveKeyLinkID }}>
      {children}
    </KeyLinkContext.Provider>
  );
};

export const KeyLinkConsumer = KeyLinkContext.Consumer;
export const useKeyLink = (): IKeyLinkContext => useContext(KeyLinkContext);

// eslint-disable-next-line react/display-name
export const withKeyLink = <P extends object>(Comp): FC<P> => (
  props,
): JSX.Element => (
  <KeyLinkContext.Consumer>
    {(keyLinkContext): JSX.Element => {
      return <Comp {...props} keylinkContext={keyLinkContext} />;
    }}
  </KeyLinkContext.Consumer>
);

export default KeyLinkProvider;
