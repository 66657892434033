import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import Backend from 'i18next-fs-backend';
import { initReactI18next } from 'react-i18next';
import i18nextMiddleware from 'i18next-http-middleware';
import Fetch from 'i18next-fetch-backend';

export const i18nPromise = i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend) 
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(Fetch)
  .use(i18nextMiddleware.LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.REACT_APP_ENV !== 'production',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    load: 'languageOnly',
  });

export default i18n;
