import { TFunction } from 'i18next';
import React, { CSSProperties, MouseEvent } from 'react';
import { ChildrenFn } from 'react-adopt';
import { FetchResult } from '@apollo/client';
import { RouteComponentProps } from 'react-router-dom';

import {
  CreateKeyLinkMutationVariables,
  CreateLinkDetailsMutationVariables,
  CreateSocialMediaMutationVariables,
  DeleteKeyLinkMutationVariables,
  DeleteSocialMediaMutationVariables,
  UpdateKeyLinkMutationVariables,
  UpdateLinkDetailsMutationVariables,
  UpdateSocialMediaMutationVariables,
  EngagementStatus,
  UpsertKeychainAdditionalQuestionsMutationVariables,
  UpsertKeychainCustomQuestionMutationVariables,
  AddToActivityLogMutationVariables,
  UserTalentStatus,
  DeleteEmbedLinkMutationVariables,
  CreateEmbedLinkMutationVariables,
  UpdateEmbedLinkMutationVariables,
} from '../API';
import {
  SignUpOptions,
  TLinkScheduleDate,
  TPaymentServiceType,
} from '../types';
import { CohortFilter } from '../types/cohorts';

import { IAuthContext } from './context';

export interface CarouselProps {
  containerClassName?: string;
}

export interface CarouselChevronsProps {
  carouselIndex: number;
  onChangeIndex: (index: number) => void;
}

export interface ConnectButtonProps {
  handleOnClick?: (event: MouseEvent<HTMLElement>) => void;
  href?: string;
  social?: boolean;
  connected?: boolean;
  service: TPaymentServiceType;
  children?: React.ReactNode;
}

export interface CropOverlayProps {
  closeOverlay: () => void;
  landscapeFile?: File;
  landscapeKey?: string | null;
  portraitFile?: File;
  portraitKey?: string | null;
  keychainFile?: File;
  keychainKey?: string | null;
  setLandscape?: (file: File) => void;
  setLandscapeFile?: (file: File | undefined) => void;
  setPortrait?: (file: File) => void;
  setPortraitFile?: (file: File | undefined) => void;
  setKeychain?: (file: File) => void;
  setKeychainFile?: (file: File | undefined) => void;
  setSquare?: (file: File) => void;
  setSquareFile?: (file: File | undefined) => void;
  setRectangle?: (file: File) => void;
  setRectangleFile?: (file: File | undefined) => void;
  shouldUsePageOverlay?: boolean;
  squareFile?: File;
  squareKey?: string | null;
  rectangleFile?: File;
  rectangleKey?: string | null;
  uploadLabel?: string;
}

export interface EditButtonProps {
  classes?: string | string[];
  onClick?: () => void;
  label?: string;
  style?: CSSProperties;
}

export interface ErrorCompProps {
  centered?: boolean;
  shouldWrapText?: boolean;
  shouldWrapTextMobile?: boolean;
  secondaryErrorStyling?: boolean;
  allowMultiline?: boolean;
  autoHeight?: boolean;
  children?: React.ReactNode;
}

export interface ImageBGDivProps {
  image: string;
  rotateDeg?: number;
}

export interface PageOverlayProps {
  closeOverlay?: () => void;
  closeText?: string;
  mainClass?: string;
  mainStyle?: CSSProperties;
  showBackBtn?: boolean;
  showKeyLogo?: boolean;
  headerClass?: string;
  overlayContentClass?: string;
  iconClass?: string;
  showProfile?: boolean;
  userAvatar?: string | null;
  talentDisplayName?: string;
  keychainProfileURL?: string;
  keychainCustomURL?: string;
  isRegisteredPage?: boolean;
  children?: React.ReactNode;
}

export interface ProgressBarProps {
  percentage: number;
}

export interface AddImageProps {
  imageLandscapeKey?: string;
  imagePortraitKey?: string;
  imageKeychainKey?: string;
  imageSquareKey?: string;
  imageRectangleKey?: string;
  justifySpaceAround?: boolean;
  label?: string;
  onLandscapeSelected?: (
    value: File,
    onUploadProgress?: (percentage: number, name: string) => void,
    onUploadFailure?: () => void,
  ) => void;
  onPortraitSelected?: (
    value: File,
    onUploadProgress?: (percentage: number, name: string) => void,
    onUploadFailure?: () => void,
  ) => void;
  onKeychainSelected?: (
    value: File,
    onUploadProgress?: (percentage: number, name: string) => void,
    onUploadFailure?: () => void,
  ) => void;
  onSquareSelected?: (
    value: File,
    onUploadProgress?: (percentage: number, name: string) => void,
    onUploadFailure?: () => void,
  ) => void;
  onRectangleSelected?: (
    value: File,
    onUploadProgress?: (percentage: number, name: string) => void,
    onUploadFailure?: () => void,
  ) => void;
  shouldUsePageOverlay?: boolean;
  shouldUseImageTypeLabel?: boolean;
  imageTypeLabel?: string;
}

export interface ExistingImagesProps {
  landscape?: string;
  portrait?: string;
  keychain?: string;
  rectangle?: string;
  redoCrop: () => void;
  setLandscape?: (file: File) => void;
  setPortrait?: (file: File) => void;
  setKeychain?: (file: File) => void;
  setSquare?: (file: File) => void;
  setRectangle?: (file: File) => void;
  square?: string;
  imageTypeLabel?: string;
  children?: React.ReactNode;
}

export interface UploadProgress {
  percentage: number;
  fileName: string;
}

export interface LoginButtonProps {
  disabled?: boolean;
  service: 'facebook' | 'twitter' | 'instagram';
  setDisabled: (disabled: boolean) => void;
  queryString?: string;
  className?: string;
  label?: string;
}

export interface PaginationProps {
  carouselIndex: number;
  totalNumber: number;
  animate?: boolean;
}

export interface ContentBoxProps {
  centerTitle?: boolean;
  className?: string;
  title: string;
  rightComponent?: JSX.Element;
}

export interface RoundPhotoPickerProps {
  onClick: () => void;
}

export enum SignUpStep {
  URL_SELECTION = 'URL_SELECTION',
  ACCOUNT_PAGE = 'ACCOUNT_PAGE',
}

export enum KeychainSetupSteps {
  SIGNING_IN = 'SIGNING_IN',
  BILLING = 'BILLING',
  LOADING = 'LOADING',
}

export enum SubmissionState {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  SUCCESSFUL = 'SUCCESSFUL',
}

export enum GuideModalSteps {
  SIGN_UP = 0,
  STEP_ONE = 1,
  STEP_TWO = 2,
  STEP_THREE = 3,
}

export enum FanManagerTabs {
  INSIGHTS = 'insights',
  MEDIA = 'media',
  FAN_GROUPS = 'groups',
}

export enum InsightsTabs {
  FIRST_PARTY = '1st Party',
  SOCIAL_ENGAGERS = 'Social Engagers',
  OVERVIEW = 'Overview',
}

export enum SocialHandles {
  INSTAGRAM = 'Instagram Handle',
  TIKTOK = 'TikTok Handle',
  TWITTER = 'Twitter Handle',
}

export enum QuestionnaireTabs {
  BRANDS = 'brands',
  CAUSES = 'causes',
}

export enum SocialHandlesQuestionID {
  FACEBOOK = 19,
  INSTAGRAM = 9,
  TIKTOK = 11,
  TWITTER = 10,
  YOUTUBE = 20,
}

export enum ExpandTalentTopBehaviorsTabs {
  CPG = 'CPG',
  Automotive = 'Automotive',
  Lifestyle = 'Lifestyle',
}

export enum ExpandTalentTopRegionsTabs {
  US_DMA = 'US DMA',
  Countries = 'Countries',
}

export enum NumberFilterRadio {
  MORE_THAN = 'More than',
  LESS_THAN = 'Less than',
  BETWEEN = 'Between',
}

export enum RadioFilterTypes {
  HOUSEHOLD_CHILDREN = 'Children in Household',
  GENDER = 'Gender',
  EDUCATION = 'Education',
}

export enum YesNoFilterRadio {
  Yes = 'Yes',
  No = 'No',
}

export enum HouseholdChildrenRadio {
  OptionOne = 'No Children in Household',
  OptionTwo = 'Has Children in Household',
}

export enum GenderRadio {
  OptionOne = 'Female',
  OptionTwo = 'Male',
}

export enum EducationRadio {
  OptionOne = 'No Higher Education',
  OptionTwo = 'Higher Education',
}

export enum FilterInputTypes {
  NUMBER_RANGE = 'NUMBER_RANGE',
  BOOLEAN = 'BOOLEAN',
  MULTI_SELECT = 'MULTISELECT',
  SEARCH_MULTI_SELECT = 'SERVER_SIDE_SEARCH_MULTISELECT',
  TEXT_WITH_INDEX = 'TEXT_WITH_INDEX',
  SERVER_SIDE_SEARCH_MULTISELECT = 'SERVER_SIDE_SEARCH_MULTISELECT',
  SERVER_SIDE_SEARCH_MULTISELECT_WITH_INDEX = 'SERVER_SIDE_SEARCH_MULTISELECT_WITH_INDEX',
}

export interface RenderProp {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: ChildrenFn<any> | undefined;
  vars?: object;
}

export interface UserDetails {
  displayProfilePicture?: boolean;
  isDark?: boolean;
  userAvatar: string;
  userFirstName: string;
  userID?: number;
  userIsVerified?: boolean;
  userIsTalent?: boolean;
}

export enum RequestBtnLabel {
  ADDED = 'Added',
  REQUEST = 'Request',
  PENDING = 'Pending',
}

export enum CreateSegmentDataItems {
  PROFILES = 'Users',
  CAPTURELINKS = 'Keylinks',
  BOTH = 'Both',
}

export interface SegmentSearchResultsProfilesRowDataType {
  profileID: number;
  imageURL: string;
  talentName: string;
  firstIndustryPath: string;
  secondIndustryPath: string;
  totalEmails: number;
  mediaOptIn: boolean;
  totalSocialReach: number;
  InstagramFollowers: number;
  TiktokFollowers: number;
  TwitterFollowers: number;
  FacebookFollowers: number;
  YoutubeSubscribers: number;
}

export interface SegmentSearchResultsCaptureLinksRowDataType {
  captureLinkID: number;
  imageURL: string;
  captureLinkName: string;
  talentName: string;
  industryPath: string;
  totalEmails: number;
  mediaOptIn: boolean;
  description: string;
}

export interface ReachCategory {
  source: string;
  altText: string;
  followers: number;
}

export interface RequestButtonData {
  disabled: boolean;
  label: RequestBtnLabel;
}

export interface OptionListProps {
  options: string[];
  onOptionSelected: (optionIndex: number) => void;
  selectedIndex: number;
}

export interface PaymentCard {
  brand: string;
  expMonth: string;
  expYear: string;
  last4: string;
  name: string;
}

export interface PaymentMethods {
  cards?: PaymentCard[] | null;
  account?: string | null;
}

export interface PaymentCardProps extends PaymentMethods {
  onCardDeleted?: () => Promise<void>;
}

export interface AccountProfileProps {
  userEmail: string | null;
  isFan?: boolean;
  isTalent?: boolean;
  isTeam?: boolean;
  temporaryEmail?: string | null;
  userID: number;
  userCognitoUID?: string | null;
  isAdmin: boolean;
  singleSection?: boolean;
  roundedCorners?: boolean;
  handleResendClick: () => void;
  handleCancelClick: () => void;
}

export interface PaymentProps {
  isTalent: boolean;
  userCognitoUID: string | null;
  showPaymentHeader?: boolean;
  showAddCard?: boolean;
  hideApplePayOrGooglePayButton?: boolean;
}

export interface TalentAdditionalInfoProps {
  goToNextStep: () => void;
}

export interface MetricCardProps {
  metricName: string;
  metricValue: string;
}

export interface AdminTabsProps {
  onSearch?: (value: string) => void;
  searchValue?: string;
  showSearch?: boolean;
}

export interface SearchBarProps {
  onSearch?: (value: string) => void;
  searchValue?: string;
  showSearch?: boolean;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  behaviorsSearch?: boolean;
  brandResultsOverride?: boolean;
  showSearchLabel?: boolean;
  onChange?: (value: string) => void;
  placeholder?: string;
  width?: string;
}

export interface AdminUserListItemProps {
  id: number;
  firstName: string;
  lastName: string;
  avatar: string;
  isTalent?: boolean;
  email?: string;
  userIsTeam?: string;
  organizationID?: number;
}

export interface AddonDropDownOption {
  date: string;
  description: string;
  optionID: number;
  price: string;
  actualPrice?: string;
}

export interface SocialMediaDropDownOption {
  optionID: string;
  optionName: string;
  optionDesc: string;
  optionSelected: boolean;
}

export interface EmbedLinksDropDownOption {
  optionID: number;
  optionName: string;
}

export interface TalentAdminDropDownOption {
  optionID: string;
  optionName: string;
  optionSelected: boolean;
}

export interface BrandCategory {
  BrandCategoryID: number;
  BrandCategoryName: string;
  DisplayRank: number;
}

export interface Brand {
  BrandID: number;
  BrandName: string;
}

export interface City {
  KeychainTalentPrimaryAnswerID: number;
  Description: string;
}

export interface ColorPickerProps {
  pickedColor: string;
  handleHexColorChange: (hexColor: string, fieldName: string) => void;
  handleBlur?: () => void;
  fieldName: string;
  label: string;
  header: string;
  error: string;
  showOpacity: boolean;
  disableError?: boolean;
  showColorPicker?: boolean;
}

export interface ColorPickerPluginProps {
  color: string;
  showOpacity: boolean;
  onChange: (color: string) => void;
  handleBlur?: () => void;
  setShowPlugin: React.Dispatch<React.SetStateAction<boolean>>;
  colorDisplayRef: React.RefObject<HTMLDivElement>;
}

export interface VerifyValues {
  email: string;
  password: string;
  eventID: string;
  t: any;
}

export interface VerifyProps extends RouteComponentProps {
  authContext?: IAuthContext;
  email: string | null;
  persona?: SignUpOptions;
  eventID?: string;
  isViewEvent?: boolean;
  userID?: number;
  t: any;
}

export interface CustomIconProps {
  color: string;
  url: string;
}

export interface Organization {
  OrganizationID: number;
  OrganizationName: string;
  MaximumLicensedSeats: number;
  OrganizationAdded: string;
  OrganizationUpdated: string;
}

export interface OrganizationAndActiveSeats extends Organization {
  ActiveSeats: number;
}

export interface OrganizationNameAndID {
  OrganizationID: number;
  OrganizationName: string;
}

export enum OrganizationAccountCycle {
  Annual = 'Annual',
  Monthly = 'Monthly',
}

export enum AdminOrgAndSingleTalentTabs {
  Active = 0,
  Inactive = 1,
}

export enum OrganizationSettingsTabs {
  ORGANIZATION_USERS = 'Organization Users',
  PROFILES = 'Profiles',
  DEACTIVATED_ACCOUNTS = 'Deactivated Accounts',
}
export enum OrganizationSettingsQueryParamTabs {
  ORGANIZATION_USERS = 'organization-users',
  PROFILES = 'Profiles',
  DEACTIVATED_ACCOUNTS = 'deactivated-accounts',
}
export interface OrganizationAccount {
  OrganizationID: number;
  OrganizationName: string;
  TotalOrgAdminsCount: number;
  TotalTalentCount: number;
}

export interface OrganizationContract {
  OrganizationContractID: number;
  OrganizationID: number;
  MaximumLicensedSeats: number;
  StartDate: string;
  Duration: number;
  Price: number;
  Cycle: string;
  TermStatus: string;
  OrganizationContractAdded: string;
  OrganizationContractAddedBy: string | null;
  SeatsInUse: number;
}

export interface SingleTalentAccount {
  UserID: number;
  TalentName: string;
  TalentEmail: string;
  StartDate: string;
}

export interface TalentAdmins {
  UserID: number;
  UserEmail: string;
  UserFirstName: string;
  UserLastName: string;
  UserIsAdmin: boolean;
  UserIsManager: boolean;
  OrganizationName?: string;
}

export interface TalentBrandAffinities {
  TalentBrandAffinityID: number;
  BrandName?: string | null | undefined;
  BrandCategoryName?: string | null | undefined;
  EngagementStatus: EngagementStatus;
}

export interface TalentProfile {
  UserID?: number;
  UserFirstName: string;
  UserLastName: string | null;
  TotalFans: number | null;
  DisplayEngagementStatus?: boolean;
  EngagementStatus: string | null;
}

export enum KeyLinkTypes {
  KEYLINK_CONTENT = 'KEYLINK_CONTENT',
  KEYLINK_GENERAL = 'KEYLINK_GENERAL',
  SOCIAL = 'SOCIAL',
  WEBSITE_LINK = 'WEBSITE_LINK',
  KEYLINK_COMBO = 'KEYLINK_COMBO',
  EMBED_LINK = 'EMBED_LINK',
  AFFILIATE_LINK = 'AFFILIATE_LINK',
}

export enum TalentManageProfileBoxType {
  PROFILE = 'profile',
  TOTAL_EMAILS = 'total emails',
  MEDIA_OPT_IN = 'media opt-in',
}

export enum BrandQuestionnaireTabs {
  BRANDS = 'Brands',
  CAUSES = 'Causes',
}

export interface KeychainAdditionalQuestions {
  KeychainAdditionalQuestionID: number;
  KeyLinkID: number;
  QuestionLabel: string;
  Enabled: boolean;
  Required: boolean;
  __typename?: string;
}

export interface KeychainCustomQuestion {
  KeyLinkID: number;
  Question: string;
  Required: boolean;
  CustomQuestionID: number;
}

export interface KeyLink {
  KeyLinkID: number;
  TalentID: number;
  KeyLinkType: KeyLinkTypes;
  KeyLinkName: string;
  KeyLinkDescription: string;
  KeyLinkImageURL: string;
  isHidden: boolean;
  StartDate: string | null;
  EndDate: string | null;
  DisplayRank: number;
  PhoneNumberRequired: boolean | null;
  KeyLinkAdded: string;
  LinkDetailsID: number;
  KeyLinkUpdated: string;
  UpdatedByUser: string | null;
  LinkDetailsDescription: string;
  LinkDetailsURL: string;
  KeyLinkPixel: string;
  CustomConsentHyperlinkLabel: string;
  CustomConsentURL: string;
  CustomConsentCallToAction: string;
  URLFriendlyName: string;
  RedirectURL: string;
  ProfilePageColor: string;
  BackgroundType: string | null;
  ProfilePageSecondColor: string | null;
  FontID: number | null;
  FontColor: string;
	BackgroundImageURL: string;
}

export interface KeyCaptureLink {
  KeyCaptureLinkID: number;
  UserID: number | null;
  CaptureLinkURL: string | null;
  CaptureLinkCustomDomain: string | null;
  RebrandlyLinkID: string;
  RedirectURL: string | null;
  AudienceID: string | null;
  CapturePagePixel: string | null;
  KeyCaptureLinkAdded: string | null;
  KeyCaptureLinkAddedBy: string | null;
  KeyCaptureLinkDeleted: string | null;
  KeyCaptureLinkDeletedBy: string | null;
}

export interface KeychainTalent {
  KeychainTalentID: number;
  UserID: number;
  KeychainProfileURL: string;
  KeychainCustomURL: string;
  AudienceID: number;
  TalentPagePixel: string;
  TalentDisplayName: string;
  TalentDisplayBio: string;
  IsTrialUser: boolean;
  HasFinishedQuestions: boolean;
  ProfilePageColor: string;
  UserAvatar: string;
  UserFirstName: string;
  IsDarkMode: boolean;
  KeyLinkRegistrationEmail: boolean;
  KeyLinkHeader: string;
  WebpageLinkHeader: string;
}

export interface TalentKeychainProfile {
  __typename: 'KeychainTalentProfile';
  KeychainTalentID: number;
  UserID: number;
  KeychainProfileURL?: string | null;
  TalentPagePixel?: string | null;
  TalentDisplayBio?: string | null;
  TalentDisplayName: string;
  ProfilePageColor?: string | null;
  UserAvatar?: string | null;
  OrganizationID?: number | null;
  KeyLinks?: Array<{
    __typename: 'KeyLink';
    KeyLinkID: number;
    TalentID: number;
    KeyLinkType?: KeyLinkTypes | null;
    KeyLinkName: string | null;
    KeyLinkDescription: string | null;
    KeyLinkImageURL?: string | null;
    isHidden?: boolean | null;
    StartDate?: string | null;
    EndDate?: string | null;
    DisplayRank: number | null;
    PhoneNumberRequired?: boolean | null;
    KeyLinkAdded?: string | null;
    KeyLinkUpdated?: string | null;
    LinkDetailsID?: number | null;
    LinkDetailsDescription?: string | null;
    LinkDetailsURL?: string | null;
    KeyLinkPixel?: string | null;
    CustomConsentHyperlinkLabel?: string | null;
    CustomConsentURL?: string | null;
    CustomConsentCallToAction?: string | null;
    CustomConsentUpdated?: string | null;
  }> | null;
  KeychainSocialMedia?: Array<{
    __typename: 'KeychainSocialMedia';
    KeychainSocialMediaID: number;
    UserID: number;
    SocialMediaType: SocialMediaTypes;
    SocialMediaUserID: string;
    DisplayRank?: number | null;
  }> | null;
  IsDarkMode?: boolean | null;
  KeyLinkRegistrationEmail?: boolean | null;
  KeyLinkHeader?: string | null;
  WebpageLinkHeader?: string | null;
}

export interface KeyLinks {
  __typename: 'KeyLink';
  KeyLinkID: number;
  TalentID: number;
  KeyLinkType?: KeyLinkTypes | null;
  KeyLinkName: string;
  KeyLinkDescription: string;
  KeyLinkImageURL?: string | null;
  isHidden?: boolean | null;
  StartDate?: string | null;
  EndDate?: string | null;
  DisplayRank: number;
  PhoneNumberRequired?: boolean | null;
  KeyLinkAdded?: string | null;
  KeyLinkUpdated?: string | null;
  LinkDetailsID?: number | null;
  LinkDetailsDescription?: string | null;
  LinkDetailsURL?: string | null;
  KeyLinkPixel?: string | null;
  CustomConsentHyperlinkLabel?: string | null;
  CustomConsentURL?: string | null;
  CustomConsentCallToAction?: string | null;
  CustomConsentUpdated?: string | null;
}

export interface CustomDomain {
  Rank: number;
  DomainURL: string;
}

export enum SocialMediaTypes {
  SPOTIFY = 'SPOTIFY',
  TWITTER = 'TWITTER',
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
  INSTAGRAM = 'INSTAGRAM',
  FACEBOOK = 'FACEBOOK',
  TWITCH = 'TWITCH',
  APPLEMUSIC = 'APPLEMUSIC',
  SNAPCHAT = 'SNAPCHAT',
  AMAZONSTORE = 'AMAZONSTORE',
  EMAIL = 'EMAIL',
  THREADS = 'THREADS',
  PINTEREST = 'PINTEREST',
  LINKEDIN = 'LINKEDIN',
}

export enum EmbedTypes {
  AUDIO = 'Audio',
  VIDEO = 'Video',
}

export enum FanGroupTypes {
  'KEYLINK' = 'KEYLINK',
  'EVENT' = 'EVENT',
  'UPLOADED' = 'UPLOADED',
}

export interface KeychainSocialMedia {
  KeychainSocialMediaID: number;
  UserID: number;
  SocialMediaType: SocialMediaTypes;
  SocialMediaUserID: string;
  DisplayRank: number;
}

export interface KeychainEmbedLink {
  KeychainEmbedsID: number;
  KeychainTalentID: number;
  KeychainEmbedSourceID: number;
  URL: string;
  Label: string;
  StartDate: string;
  EndDate: string;
  isHidden: boolean;
  DisplayRank: number;
  MediaType: string;
  Description: string;
}

export interface LinkScheduleFormValues {
  startDate: TLinkScheduleDate;
  endDate: TLinkScheduleDate;
  hasStartDate: boolean | undefined;
  hasEndDate: boolean | undefined;
}

export interface KeyLinkFormValues extends LinkScheduleFormValues {
  keyLinkName: string;
  keyLinkDescription: string;
  thumbnailFile: File | null;
  hasCustomConsentEnabled: boolean;
  customConsentHyperlinkLabel: string;
  customConsentURL: string;
  customConsentCallToAction: string;
  isHidden: boolean;
  phoneNumberRequired: boolean;
}

export interface CaptureLinkFormValues {
  captureLinkCustomDomain: string;
  captureLinkURL: string;
  redirectURL: string;
}

export interface KeyLinkFormInnerProps {
  talentID: number;
  keylinkID?: number | null;
  isFromTalentAdmin?: boolean;
  talentDisplayName: string | null;
  currentUserID: number | null;
  orgID: number | null;
}

export interface ManageKeyLinkProps {
  createKeyLinkMutation: (
    variables: CreateKeyLinkMutationVariables,
    client: any,
  ) => Promise<any>;
  addToActivityLogMutation: (
    variables: AddToActivityLogMutationVariables,
    client: any,
  ) => Promise<void>;
  createLinkDetailsMutation: (
    variables: CreateLinkDetailsMutationVariables,
    client: any,
  ) => Promise<void>;
  createSocialMediaMutation: (
    variables: CreateSocialMediaMutationVariables,
    client: any,
  ) => Promise<void>;
  updateKeyLinkMutation: (
    variables: UpdateKeyLinkMutationVariables,
    client: any,
  ) => Promise<void>;
  updateLinkDetailsMutation: (
    variables: UpdateLinkDetailsMutationVariables,
    client: any,
  ) => Promise<void>;
  updateSocialMediaMutation: (
    variables: UpdateSocialMediaMutationVariables,
    client: any,
  ) => Promise<void>;
  upsertAdditionalQuestionsMutation: (
    variables: UpsertKeychainAdditionalQuestionsMutationVariables,
    client: any,
  ) => Promise<void>;
  upsertCustomQuestionMutation: (
    variables: UpsertKeychainCustomQuestionMutationVariables,
    client: any,
  ) => Promise<void>;
}

export interface ManageWebpageLinkProps {
  createKeyLinkMutation: (
    variables: CreateKeyLinkMutationVariables,
    client: any,
  ) => Promise<any>;
  addToActivityLogMutation: (
    variables: AddToActivityLogMutationVariables,
    client: any,
  ) => Promise<void>;
  createLinkDetailsMutation: (
    variables: CreateLinkDetailsMutationVariables,
    client: any,
  ) => Promise<void>;
  updateKeyLinkMutation: (
    variables: UpdateKeyLinkMutationVariables,
    client: any,
  ) => Promise<void>;
  updateLinkDetailsMutation: (
    variables: UpdateLinkDetailsMutationVariables,
    client: any,
  ) => Promise<void>;

}

export interface ManageLinkSchedulerProps {
  updateKeyLinkMutation: (
    variables: UpdateKeyLinkMutationVariables,
    client: any,
  ) => void;
}
export interface SocialMediaFormInnerProps {
  talentID: number;
  socialLinks: KeychainSocialMedia[] | undefined;
  talentIDParam: string | null;
}

export interface ManageAllSocialLinksProps {
  talentID: number;
  socialLinks: KeychainSocialMedia[] | undefined;
  talentIDParam: string | null;
}

export interface EmbedLinkFormInnerProps {
  talentID: number;
  embedLinks: KeychainEmbedLink[] | undefined;
  keychainTalentID: number;
}

export interface ManageSocialMediaProps {
  createSocialMediaMutation: (
    variables: CreateSocialMediaMutationVariables,
    client: any,
  ) => Promise<void>;
  updateSocialMediaMutation: (
    variables: UpdateSocialMediaMutationVariables,
    client: any,
  ) => Promise<void>;
}

export interface ManageEmbedLinksProps {
  createEmbedLinkMutation: (
    variables: CreateEmbedLinkMutationVariables,
    client: any,
  ) => Promise<void>;
  updateEmbedLinkMutation: (
    variables: UpdateEmbedLinkMutationVariables,
    client: any,
  ) => Promise<void>;
}

export interface CreateKeyLinkSidebarMutation extends RouteComponentProps {
  createKeyLinkMutation: (
    variables: CreateKeyLinkMutationVariables,
    client: any,
  ) => Promise<any>;
  createLinkDetailsMutation: (
    variables: CreateLinkDetailsMutationVariables,
    client: any,
  ) => Promise<void>;
  talentID: number;
}

export interface KeychainThumbnailUploadProps {
  setThumbnailFile: (thumbnailFile: File) => void;
  isKeyLink: boolean;
  isWebLink?: boolean;
  uploadedThumbnailURL?: string;
  headerText?: string;
  subheaderText?: string;
  imageFrameClass?: string;
  isForProfilePicture?: boolean;
  pdfMode: boolean;
  isInverted?: boolean;
}

export interface WebpageLinkImageUploadProps {
  setThumbnailFile: (thumbnailFile: File) => void;
  uploadedThumbnailURL?: string;
  headerText?: string;
  subheaderText?: string;
  imageFrameClass?: string;
  isForProfilePicture?: boolean;
  pdfMode: boolean;
  isInverted?: boolean;
  isFromMetaData?: boolean;
  metaDataURL?: string;
}
export interface KeychainLinksProps {
  talentID: number;
  talentDisplayName: string | null;
  currentUserID: number | null;
  orgID: number | null;
  isDisabled?: boolean;
  addToActivityLogMutation: (
    variables: AddToActivityLogMutationVariables,
    client: any,
  ) => Promise<void>;
  deleteKeyLinkMutation: (
    variables: DeleteKeyLinkMutationVariables,
    client: any,
  ) => Promise<void>;
  deleteSocialMediaMutation: (
    variables: DeleteSocialMediaMutationVariables,
    client: any,
  ) => Promise<void>;
  deleteEmbedLinkMutation: (
    variables: DeleteEmbedLinkMutationVariables,
    client: any,
  ) => Promise<void>;
  isFromTalentAdmin?: boolean;
  keychainPageOrder?: string[];
  keychainTalentID?: number;
  handleUpdateKeychainPreview?: () => void;
  selectedLinksType?: KeyLinkTypes;
  setCurrentSocialLinks?: React.Dispatch<
    React.SetStateAction<KeychainSocialMedia[]>
  >;
  setShowManageSocialLinks?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ShowHiddenIconProps {
  keylinkID: number | undefined;
  isHidden: boolean | undefined;
}

export interface KeyLinkActionDropdownProps extends RouteComponentProps {
  keyLink?: KeyLink;
  onMove: (linkID: number, moveUp: boolean) => void;
  socialMedia?: KeychainSocialMedia;
  maxDisplayRank: number;
  minDisplayRank: number;
  talentID: number;
  orgID: number | null;
  talentDisplayName: string;
  currentUserID: number | null;
  isDisabled?: boolean;
  deleteKeyLinkMutation?: (
    variables: DeleteKeyLinkMutationVariables,
    client: any,
  ) => Promise<void>;
  addToActvityLogMutation: (
    variables: AddToActivityLogMutationVariables,
    client: any,
  ) => Promise<void>;
  deleteSocialMediaMutation: (
    variables: DeleteSocialMediaMutationVariables,
    client: any,
  ) => Promise<void>;
  isFromTalentAdmin?: boolean;
  reloadLinks: () => Promise<void>;
  showHiddenIcon?: (showHiddenIconObject: ShowHiddenIconProps) => void;
}

export interface FanGroupProps {
  customStyles?: object[];
  startDate?: string | null;
  endDate?: string | null;
  dateCreated?: string | null;
  dateUpdated?: string | null;
  dateDeleted?: string;
  fanGroupName?: string;
  fansAdded?: number | null;
  groupType?: string;
  isHidden?: boolean;
  hasInfoButton?: boolean;
  infoContent?: string;
  infoTitle?: string;
  isHome?: boolean;
  objectID: number; // can be keyLink or event
  objectType: FanGroupTypes;
  toggleExportConfirmationModal?: (isOpen: boolean) => void;
  currentUserIdentityId: string;
}
export interface GetUploadedFanGroupDataInterface {
  FanGroupName: string;
  EmailUploadGroupID: number;
  FansAdded: number | null;
  DateCreated: string;
  DateUpdated: string;
  SignedUpForKeychain: boolean | null;
  UserIsTalent: boolean | null;
  userIsVerifiedForEventCreationStatus: UserTalentStatus;
}
export interface UploadedFanGroupProps {
  setHasFanGroups: React.Dispatch<React.SetStateAction<boolean>>;
  setUploadedEmailFanGroups: React.Dispatch<
    React.SetStateAction<GetUploadedFanGroupDataInterface[]>
  >;
  splice?: boolean;
  maxLimit?: number;
  toggleExportConfirmationModal?: (isOpen: boolean) => void;
}

export interface CreateALinkOrEventProps {
  type?: string;
  buttonLarge?: boolean;
  buttonText?: string;
  captureLinkDestination?: string;
  descriptionFirstParagraph?: string;
  descriptionSecondParagraph?: string;
  descriptionThirdParagraph?: string;
  destination?: string;
  disableButton?: boolean;
  displayNoGroupsYet?: boolean;
  isFanManager?: boolean;
  secondButtonText?: string;
  thirdButtonText?: string;
  uploadDestination?: string;
  welcomeMessageOne?: string;
  welcomeMessageTwo?: string;
}

export interface GoodPurposeInitiative {
  GoodPurposeInitiativeID: number;
  GoodPurposeInitiativeName: string;
}

export interface BehaviorReport {
  fileName: string;
  lastModified: any;
  url: string;
  title?: string;
  audienceID?: number;
  talentID?: number;
}

export interface BehaviorExportProps {
  title: string;
  fileName: string;
  lastModified: any;
  url: string;
  talentName?: string;
}

export interface FanGroupReport {
  fileName: string;
  lastModified: any;
  url: string;
  title?: string;
  keyLinkID?: number;
  uploadedListID?: number;
  talentID?: number;
}

export interface FanGroupExportProps {
  title: string;
  fileName: string;
  lastModified: any;
  url: string;
  talentName?: string;
}
export interface SocialAudienceOverlapReport {
  fileName: string;
  lastModified: any;
  url: string;
  title?: string;
  requestID?: number;
  requestorID?: number;
}

export interface SocialAudienceOverlapExportProps {
  title: string;
  fileName: string;
  lastModified: any;
  url: string;
  talentName?: string;
}

export interface BreadCrumbsInfo {
  previousLabel: string;
  previousRoute: string;
  currentLabel: string;
}

export interface SocialMediaReachReport {
  fileName: string;
  lastModified: any;
  url: string;
  talentID?: number;
}

export interface SocialMediaReachExportProps {
  fileName: string;
  lastModified: any;
  url: string;
  title: string;
  talentName?: string;
}

export interface LotamePixelProps {
  KeyLinkPixel: string;
}

export type StripePromocode = {
  valid: boolean;
  percentOff?: number | null;
  promocodeID?: string;
  durationInMonths?: number | null;
  loading?: boolean;
  isChecked?: boolean;
};
export interface KeychainSignUpProps extends RouteComponentProps {
  keychainTalentID: number;
  talentEmail: string;
}

export interface KeychainTalentAnswer {
  KeychainTalentAnswerID: number | null | undefined;
  KeychainTalentPrimaryAnswerID: number | null | undefined;
  KeychainTalentSecondaryAnswerID: number | null | undefined;
  OtherAnswer: string | null | undefined;
}

export type KeychainTalentQuestionInputType =
  | 'Autocomplete'
  | 'AutocompleteWithServerSideSearch'
  | 'Dropdown'
  | 'Text'
  | 'Radio'
  | 'Date';

export interface KeychainTalentQuestion {
  KeychainTalentQuestionID: number;
  Description: string;
  InputType: KeychainTalentQuestionInputType;
  Placeholder?: string;
  DisplayRank?: number;
  IsHidden: boolean;
}

export interface FullKeychainTalentAnswer {
  KeychainTalentAnswerID: number;
  TalentID: number;
  KeychainTalentPrimaryAnswerID: number;
  OtherPrimaryAnswer?: string;
  KeychainTalentSecondaryAnswerID?: number;
  OtherSecondaryAnswer?: string;
  KeychainTalentTertiaryAnswerID?: number;
  OtherTertiaryAnswer?: string;
}
export interface KeychainTalentPrimaryAnswer {
  KeychainTalentPrimaryAnswerID: number;
  Description: string;
}

export interface KeychainTalentSecondaryAnswer {
  KeychainTalentSecondaryAnswerID: number;
  KeychainTalentPrimaryAnswerID: number;
  Description: string;
}

export interface KeychainTalentTertiaryAnswer {
  KeychainTalentTertiaryAnswerID: number;
  KeychainTalentSecondaryAnswerID: number;
  Description: string;
}

export interface KeyLinkFormAdditionalQuestions {
  KeyLinkID: number;
  QuestionLabel: string;
  Enabled: boolean;
  Required: boolean;
}

export interface KeyLinkFormCustomQuestion {
  CustomQuestionID: number;
  Question: string;
  Required: boolean;
  DeletedAt: string | null;
}

export interface CreateTalentProps {
  OrganizationID: number;
  OrganizationName: string;
}

export interface AggregationPageBrandData {
  TalentID: number;
  BrandID: number;
  BrandCategoryID: number;
  BrandName: string;
  Category: string;
  BrandTotal: number;
}

export interface BrandQuestionnaireCompletionPercentageData {
  Percentage: string;
}

export interface ProfileCompletionPercentageData {
  Percentage: number;
}
export interface SocialBrandSearchRequest {
  SocialBrandSearchRequestID: number;
  OrganizationID: number;
  RequestName?: string | null;
  Description?: string | null;
  Status: string;
}

export interface SocialBrandSearchResult {
  SocialBrandSearchResultID: number;
  BehaviorName?: string | null;
  IndustryVertical?: string | null;
  Path?: string | null;
  BrandIndex?: number | null;
}
export interface SocialOverlapInsightSearchResult {
  SocialOverlapInsightID: number;
  BehaviorName?: string | null;
  BehaviorIndex?: number | null;
  Percentage?: number | null;
  subCategory?: string | null;
}

export interface Cohort {
  CohortID: number;
  Name: string | null;
  ExportName: string | null;
  TotalSocialMediaReach: number | null;
  InstagramFollowers: number | null;
  InstagramLikes: number | null;
  InstagramTotalPosts: number | null;
  TiktokFollowers: number | null;
  TiktokTotalShares: number | null;
  TiktokTotalVideos: number | null;
  TwitterFollowers: number | null;
  TwitterLikes: number | null;
  TwitterTotalTweets: number | null;
  FacebookFollowers: number | null;
  YoutubeSubscribers: number | null;
  TotalEmails: number | null;
  ProjectedMaxMediaSpend: number | null;
  EstimatedTakeHomeProfit: number | null;
  ConfidenceScore: number | null;
  DateAdded: string | null;
  DateLastUpdated: string | null;
  TotalAudienceReach: number | null;
  Description: string | null;
  CohortImage: string | null;
}

export interface CohortSection {
  id: COHORT_SECTION;
  isHidden: boolean;
}

export enum COHORT_SECTION {
  APPLIED_FILTERS = 'APPLIED_FILTERS',
  AUDIENCE_REACH = 'AUDIENCE_REACH',
  FEATURED_TALENT = 'FEATURED_TALENT',
  SOCIAL_METRICS = 'SOCIAL_METRICS',
}

export type RequestStatus = 'PENDING' | 'COMPLETE';
export interface OrganizationRequestsQueryData {
  SocialBrandSearchRequestID: number;
  OrganizationID: number;
  RequestName: string;
  Description: string;
  Status: RequestStatus;
  DateAdded: string;
  Talents: string | null;
  AddedBy: number;
}

export enum SocialNetwork {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
}

export interface SocialMediaReachResult {
  payload: string | null;
  timestamp: string | null;
}

export enum TalentProfileSection {
  DEMOGRAPHICS_QUESTIONS = 'DEMOGRAPHICS_QUESTIONS',
  BRAND_QUESTIONNAIRE = 'BRAND_QUESTIONNAIRE',
  ABOUT_TALENT = 'ABOUT_TALENT',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
}

export enum BrandCategories {
  GPI = 'GPI',
  Automotive = 'Automotive',
  CPG = 'CPG',
}

export interface TalentProfileSectionCompletion {
  ProfileSection: TalentProfileSection;
  StepsCompleted: number;
  RequiredSteps: number;
}

export enum PlatformUserType {
  KEY_ADMIN = 'KEY_ADMIN',
  TALENT_ADMIN = 'TALENT_ADMIN',
  MANAGER = 'MANAGER',
  TALENT = 'TALENT',
  SALES = 'SALES',
}

export enum UserType {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  TALENT = 'TALENT',
}

export enum OrganizationUserType {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
}

export interface TalentProfileAccessProps {
  TalentID: number;
  AccountManagerID: number;
  UserFirstName: string;
  UserLastName: string;
  UserEmail: string | null;
  ProfileCompletion: number;
}

export interface AudienceFilter {
  FilterID: number;
  FilterLabel: string;
  SourceID: number;
  SourceType: string;
  InputType: string;
}

export enum AudienceFilterSourceType {
  BRAND = 'BRAND',
  DATA = 'DATA',
  PROFILE = 'PROFILE',
}

export interface SelectedAudienceFilter extends AudienceFilter {
  isFilterApplied: boolean;
  values?: any;
  valuesLabel?: string;
}
export interface TalentRosterFilter {
  FilterID: number;
  TalentSearchFieldName?: string | null;
  FilterLabel: string;
  SourceID: number;
  SourceType: string;
  DisplayType: string;
  SummaryTableColumnName?: string | null;
  InputType: string;
}

export enum TalentRosterFilterSourceType {
  BRAND = 'BRAND',
  DATA = 'DATA',
  PROFILE = 'PROFILE',
  AUDIENCE = 'AUDIENCE',
}

export interface SelectedTalentRosterFilter extends TalentRosterFilter {
  isFilterApplied: boolean;
  values?: any;
  valueLabels?: any;
  index?: number;
  include?: string;
}

export interface TalentRosterFilterValue {
  category: string;
  label: string;
}
export interface BehaviorSearchFilterValue extends TalentRosterFilterValue {
  values: number[] | null;
}
export interface TalentRosterNumberFilterValue extends TalentRosterFilterValue {
  values: [number | null, number | null];
}

export interface TalentRosterYesNoFilterValue extends TalentRosterFilterValue {
  values: [boolean | null];
}

export interface TalentRosterMultiSelectFilterValue
  extends TalentRosterFilterValue {
  values: number[];
}

export interface TalentRosterSearchMultiSelectFilterValue
  extends TalentRosterFilterValue {
  values: number[];
}

export interface FreeFormFilterValue
  extends TalentRosterFilterValue {
  values: string;
}

export interface IndustryFilterValue {
  primary: FilterOption;
  hasSecondaryOptions?: boolean;
  secondary?: FilterOption[];
  tertiary?: FilterOption[];
}

export interface TalentRosterIndustryFilterValue
  extends TalentRosterFilterValue {
  values: IndustryFilterValue[];
}

export interface Filter {
  FilterID: number;
  FilterLabel: string;
}

export interface SelectedFilter extends Filter {
  isApplied: boolean;
  selectedValues: FilterOption[];
}

export interface FilterProps {
  filterID: number;
  filterLabel: string;
  selectedValues: FilterOption[];
  showPopover: boolean;
  onRemove: () => void;
  onClosePopover: (isFilterApplied: boolean) => void;
  onApply: (selectedValues: FilterOption[]) => void;
}

export interface FilterOption {
  OptionID: number | string;
  OptionLabel: string;
}
export interface ActivityLogFilter extends Filter {
  FilterType: ActivityLogFilterType;
  ActivityLogSourceColumnName: string;
}
export interface CaptureLinksFilter extends Filter {
  FilterType: CaptureLinksFilterType;
  CaptureLinksSourceColumnName: string;
}
export interface NotificationCenterFilter extends Filter {
  FilterType: NotificationCenterFilterType;
  NotificationCenterSourceColumnName: string;
}

export interface SelectedActivityLogFilter
  extends ActivityLogFilter,
    SelectedFilter {}

export enum ActivityLogFilterType {
  ORGANIZATION = 'ORGANIZATION',
  TALENT = 'TALENT',
  ACTION_DONE_BY = 'ACTION_DONE_BY',
  ACTIVITY_TYPE = 'ACTIVITY_TYPE',
  DATE = 'DATE',
}

export interface SelectedCaptureLinksFilter
  extends CaptureLinksFilter,
    SelectedFilter {}

export interface SelectedNotificationCenterFilter
  extends NotificationCenterFilter,
    SelectedFilter {}

export enum NotificationCenterFilterType {
  DATE = 'DATE',
  NOTIFICATION_TYPE = 'NOTIFICATION_TYPE',
  TALENT = 'TALENT',
}

export enum CaptureLinksFilterType {
  DATE = 'DATE',
  NUMBER_OF_EMAILS = 'NUMBER_OF_EMAILS',
  HAS_INDUSTRY_TAGS = 'HAS_INDUSTRY_TAGS',
  HAS_SUPPLEMENT_TAGS = 'HAS_SUPPLEMENT_TAGS',
  TALENT = 'TALENT',
  ORGANIZATION = 'ORGANIZATION',
}

export const DateFilterOption = {
  Today: 'Today',
  SevenDays: '7 Days',
  LastFourWeeks: 'Last 4 Weeks',
  LastThreeMonths: 'Last 3 Months',
  Last12Months: 'Last 12 Months',
  MonthToDate: 'Month-to-Date',
  QuarterToDate: 'Quarter-to-Date',
  YearToDate: 'Year-to-Date',
};

export interface TopBrandProfilesProps {
  BrandID: number;
  BrandName: string;
  Logo: string;
  ContactCount: number;
}

export interface CohortTalentsProps {
  UserID: number | null;
  UserFullName: string | null;
  SegmentID: number | null;
  SegmentName: string | null;
  IsMediaOptIn: boolean | null;
  UserAvatar: string | null;
  TotalEmails: number | null;
  TotalSocialMediaReach: number | null;
  ProfileCompletion: number | null;
  InstagramFollowers: number | null;
  TiktokFollowers: number | null;
  TwitterFollowers: number | null;
  FacebookFollowers: number | null;
  YoutubeSubscribers: number | null;
  HeroStatus: boolean | null;
  SegmentIndustries: string | null;
}

export interface CohortTalentsDataProps {
  getCohortTalents: CohortTalentsProps[];
  getCohortTalentsCount: number;
}

export interface FilterErrors {
  filterLabel: string;
  error: string;
}

export interface CohortFiltersPayload<TFilter> {
  cohortID: number;
  filters: TFilter[];
}

export interface ReportingDashboardChartData {
  Date: string;
  ChartProperty: number;
}

export interface EmailsReportingDashboardChartData {
  Date: string;
  ChartProperty: number;
}

export enum AdminOrgAndSingleTalentQueryParamTabs {
  Active = 'Active',
  Inactive = 'Deactivated',
}

export interface CategoryOption {
  CategoryID: number | string;
  CategoryLabel: string;
}

export interface UploadHeaderOption {
  UploadHeaderOptionID: number;
  UploadHeaderOptionLabel: string;
}

export interface KeychainTalentBio {
  TalentDisplayBio: string;
}

export interface IndustryAndTagsFilter extends CohortFilter {
  tagValues: string[];
}

export interface MailchimpExportData {
  MailchimpExportID: number;
  UserID: number;
  ExportID: number;
  UploadedFanGroupID: number | null;
  FanGroupName: string | null;
  DownloadURL: string | null;
  DateAdded: string;
  DateDownloaded: string | null;
}

export enum MailchimpExportStatus {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
}

export enum KlaviyoExportStatus {
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  PENDING = 'PENDING',
  SHOW_LISTS = 'SHOW_LISTS',
}

export interface TKlaviyoList {
  ListID: string;
  ListName: string;
}

interface ReferralDetail {
	ReferralDomain: string;
	ReferralImpressions: number;
}
export interface CaptureLinkDashboard  {
  UserID: number;
  CaptureLinkID: number;
  CaptureLinkName: string;
  Date: string;
  Impressions: number;
  UniqueImpressions: number;
  Signups: number;
  UniqueSignups: number;
  Phones: string;
	ReferralDetails: ReferralDetail[]
};

export type BehaviorsEntry = {
  behaviorName: string;
  compositeIndex: string;
};

export type BehaviorsInsights = {
  CPG: BehaviorsEntry[];
  Automotive: BehaviorsEntry[];
  Lifestyle: BehaviorsEntry[];
};

export type TopRegionsInsights = {
  behaviorName: string;
  percentage: string;
};

export type ExpandTalentInsights = {
  behaviors: BehaviorsInsights;
  topRegionsCountries: TopRegionsInsights[];
  topRegionsUSDMA: TopRegionsInsights[];
  uniques: number;
};

export enum TalentDiscoveryFilterInputTypes {
	TOPICS = 'TOPICS',
	BIO_KEYWORDS = 'BIO_KEYWORDS',
	POST_CAPTION_KEYWORDS = 'POST_CAPTION_KEYWORDS',
	LOCATION = 'LOCATION',
	AGE = 'AGE',
	GENDER = 'GENDER',
	LANGUAGE = 'LANGUAGE',
	BRAND_AFFINITY = 'BRAND_AFFINITY',
	INTEREST_AFFINITY = 'INTEREST_AFFINITY',
	ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
	FOLLOWER_GROWTH = 'FOLLOWER_GROWTH',
	SUBSCRIBER_GROWTH = 'SUBSCRIBER_GROWTH',
	BRAND_SPONSORS = 'BRAND_SPONSORS',
}

export type BrandConquestFilterType = {
	FilterLabel: string,
	InputType: string,
	DisplayType: string,
	isFilterApplied: boolean,
}

export interface BrandConquestFilter extends BrandConquestFilterType {
	isFilterApplied: boolean;
  id: string;
  values?: any;
  valuesLabel?: any;
  index?: number;
  include?: string;
}


