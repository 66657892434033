import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import React, { FC } from 'react';

import styles from './Loading.module.scss';

interface LoadingProps {
  additionalClass?: string;
  shouldInheritColor?: boolean;
  limitHeight?: boolean;
}

const Loading: FC<LoadingProps> = ({
  additionalClass = styles.submitLoader,
  limitHeight,
}): JSX.Element => (
  <div
    className={clsx(
      styles.loading,
      additionalClass,
      limitHeight && styles.limitHeight,
    )}
  >
    <CircularProgress color="inherit" />
  </div>
);

export default Loading;
