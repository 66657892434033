import { getUrl, list } from 'aws-amplify/storage';
import  {fetchAuthSession, getCurrentUser} from 'aws-amplify/auth';
import { S3 } from 'aws-sdk';
import { TaggingHeader } from 'aws-sdk/clients/s3';

import AWSExports from '../../src/aws-exports';
import {
  BehaviorReport,
  FanGroupReport,
  SocialAudienceOverlapReport,
  SocialMediaReachReport,
} from '../interfaces/props';

import { getDateSlashes } from './date';
import { getImageExtension } from './images';
import { doubleToInt } from './number';
import S3Storage from './s3Storage';


const getKey = (filePath: string, fileExtension?: string | null) => {
  if (fileExtension) {
    return `${filePath}.${fileExtension}`;
  }

  return filePath;
};

export const uploadContent = async (
  fullS3FilePath: string,
  file: File,
  progressCallback?: (percentage: number, name: string) => void,
  fileExtension?: string | null,
  tag?: TaggingHeader,
): Promise<string> => {
  const key = getKey(fullS3FilePath, fileExtension);

  const putResult = await S3Storage.put(key, file, {
    contentType: file.type,
    level: 'public',
    bucket: AWSExports.aws_user_files_s3_bucket,
    region: AWSExports.aws_project_region,
    tagging: tag,
    progressCallback: (progress): void => {
      if (progressCallback) {
        progressCallback(
          doubleToInt((progress.loaded / progress.total) * 100),
          file.name,
        );
      }
    },
  });

  return putResult['key'];
};

export const uploadImage = async (
  fullS3FilePath: string,
  file: File,
  progressCallback?: (percentage: number, name: string) => void,
): Promise<string> => {
  const publicURL = await uploadContent(
    fullS3FilePath,
    file,
    progressCallback,
    getImageExtension(file),
  );

  return new Promise((resolve, reject) => {
    if (publicURL) {
      resolve(publicURL.replace('public/', ''));
    } else {
      reject('Could not get the file url');
    }
  });
};

export const uploadEmailList = async (
  fullS3FilePath: string,
  file: File,
  progressCallback?: (percentage: number, name: string) => void,
): Promise<string> => {
  return uploadContent(fullS3FilePath, file, progressCallback);
};

export const uploadCSVFile = async (
  fullS3FilePath: string,
  file: File,
  tag?: string,
): Promise<string> => {
  return uploadContent(fullS3FilePath, file, undefined, undefined, tag);
};

export const getAssetUrl = async (
  key: string | null,
  notFound: string,
): Promise<string> => {
  if (key) {
    const url = await getUrl({
      key, 
      options: {
        accessLevel: 'guest', // TODO -changed from 'public' to 'guest' ? is this okay
    }});

    return url.url.href.toString();
  }

  return notFound;
};

export const getFanGroupReports = async (): Promise<FanGroupReport[]> => {
  try {
    const res = await list({
      prefix: `fan-group-reports/`,
      options: {
        accessLevel: 'private',
      },
      
    });

    const S3StorageObject = S3Storage;

    const credentials = (await getCurrentUser()).signInDetails?.loginId; // TODO changed currentAuthenticatedUser() to getCurrentUser (is this accurate)

    const S3ServiceObject = await S3StorageObject.createS3ServiceObject(
      `${AWSExports.aws_user_files_s3_bucket}/private/${credentials || ''}`,
      AWSExports.aws_user_files_s3_bucket_region,
    );

    return await Promise.all(
      res.items
        .sort((fg1: any, fg2: any) => fg2.lastModified - fg1.lastModified)
        .map(
          async (fg: any): Promise<FanGroupReport> => {
            const url = await getUrl( {
              key: fg.key, options: {
              expiresIn: 900,
            }});

            const result: FanGroupReport = {
              url: url.toString(),
              lastModified: getDateSlashes(fg.lastModified),
              fileName: fg.key.replace('fan-group-reports/', ''),
            };

            const tagset = await S3StorageObject.getObjectTagging(
              fg.key,
              S3ServiceObject,
            );

            const fanGroupNameTag: S3.Tag | undefined = tagset.find(
              tag => tag.Key === 'fanGroupName',
            );

            if (
              fanGroupNameTag &&
              (fanGroupNameTag.Value === 'Total Fan Pool' ||
                fanGroupNameTag.Value === 'Key Events')
            ) {
              const talentIDTag: S3.Tag | undefined = tagset.find(
                tag => tag.Key === 'userID',
              );

              return {
                ...result,
                title: fanGroupNameTag.Value,
                talentID: talentIDTag ? +talentIDTag.Value : undefined,
              };
            }

            const keyLinkIDTag: S3.Tag | undefined = tagset.find(
              tag => tag.Key === 'keyLinkID',
            );

            if (keyLinkIDTag) {
              return {
                ...result,
                keyLinkID: +keyLinkIDTag.Value,
              };
            }

            const uploadedListIDTag: S3.Tag | undefined = tagset.find(
              tag => tag.Key === 'uploadedListID',
            );

            if (uploadedListIDTag) {
              return {
                ...result,
                uploadedListID: +uploadedListIDTag.Value,
              };
            }

            return {
              ...result,
              title: result.fileName,
            };
          },
        ),
    );
  } catch (error) {
    window.Rollbar.error('Could not fetch export files:', {
      error,
    });

    return [];
  }
};

export const getBehaviorReports = async (): Promise<BehaviorReport[]> => {
  try {
    const res = await list({
      prefix: `behavior-report/`,
      options: {
        accessLevel: 'private',
      },
    });

    const S3StorageObject = S3Storage;

    const credentials = (await fetchAuthSession())?.credentials?.accessKeyId;

    const S3ServiceObject = await S3StorageObject.createS3ServiceObject(
      `${AWSExports.aws_user_files_s3_bucket}/private/${credentials || ''}`,
      AWSExports.aws_user_files_s3_bucket_region,
    );

    return await Promise.all(
      res
        .items
        .sort((fg1: any, fg2: any) => fg2.lastModified - fg1.lastModified)
        .map(
          async (fg: any): Promise<BehaviorReport> => {
            const url = await getUrl({
              key: fg.key, 
              options: {
                expiresIn: 900,
              }
            });

            const result: BehaviorReport = {
              url: url.toString(),
              lastModified: getDateSlashes(fg.lastModified),
              fileName: fg.key.replace('behavior-reports/', ''),
            };

            const tagset = await S3StorageObject.getObjectTagging(
              fg.key,
              S3ServiceObject,
            );

            const talentIDTag: S3.Tag | undefined = tagset.find(
              tag => tag.Key === 'userID',
            );

            if (talentIDTag) {
              return {
                ...result,
                talentID: talentIDTag ? +talentIDTag.Value : undefined,
              };
            }

            const talentDisplayNameTag: S3.Tag | undefined = tagset.find(
              tag => tag.Key === 'talentDisplayName',
            );

            if (talentDisplayNameTag) {
              const audienceIDTag: S3.Tag | undefined = tagset.find(
                tag => tag.Key === 'audienceID',
              );

              return {
                ...result,
                audienceID: audienceIDTag ? +audienceIDTag.Value : undefined,
                title: talentDisplayNameTag.Value,
              };
            }

            return {
              ...result,
              title: result.fileName,
            };
          },
        ),
    );
  } catch (error) {
    window.Rollbar.error('Could not fetch export files:', {
      error,
    });

    return [];
  }
};

export const getSocialAudienceOverlapReports = async (): Promise<SocialAudienceOverlapReport[]> => {
  try {
    const res = await list( {
      prefix: `social-audience-overlap-reports`,
      options: {
        accessLevel: 'private',
      },
    });

    const S3StorageObject = S3Storage;

    const credentials = (await fetchAuthSession())?.userSub;

    const S3ServiceObject = await S3StorageObject.createS3ServiceObject(
      `${AWSExports.aws_user_files_s3_bucket}/private/${credentials}`,
      AWSExports.aws_user_files_s3_bucket_region,
    );

    return await Promise.all(
      res
        .items
        .sort((fg1: any, fg2: any) => fg2.lastModified - fg1.lastModified)
        .map(
          async (fg: any): Promise<SocialAudienceOverlapReport> => {
            const url = await getUrl({
              key: fg.key, 
              options: {
                expiresIn: 900,
              }
            });

            const result: SocialAudienceOverlapReport = {
              url: url.toString(),
              lastModified: getDateSlashes(fg.lastModified),
              fileName: fg.key.replace('social-audience-overlap-reports/', ''),
            };

            const tagset = await S3StorageObject.getObjectTagging(
              fg.key,
              S3ServiceObject,
            );

            const requestorIDTag: S3.Tag | undefined = tagset.find(
              tag => tag.Key === 'requestorID',
            );

            const requestNameTag: S3.Tag | undefined = tagset.find(
              tag => tag.Key === 'requestName',
            );

            if (requestorIDTag) {
              if (requestNameTag) {
                return {
                  ...result,
                  requestorID: +requestorIDTag.Value,
                  title: requestNameTag.Value,
                };
              } else {
                return {
                  ...result,
                  requestorID: +requestorIDTag.Value,
                };
              }
            }

            return {
              ...result,
              title: result.fileName,
            };
          },
        ),
    );
  } catch (error) {
    window.Rollbar.error('Could not fetch export files:', {
      error,
    });

    return [];
  }
};

export const getSocialMediaReachReports = async (): Promise<SocialMediaReachReport[]> => {
  try {
    const res = await list({
      prefix: `social-media-reach-reports`,
      options: {
        accessLevel: 'private',
      },
    });

    const S3StorageObject = S3Storage;

    const credentials = (await fetchAuthSession())?.credentials?.accessKeyId;

    const S3ServiceObject = await S3StorageObject.createS3ServiceObject(
      `${AWSExports.aws_user_files_s3_bucket}/private/${credentials || ''}`,
      AWSExports.aws_user_files_s3_bucket_region,
    );

    return await Promise.all(
      res
        .items
        .sort((fg1: any, fg2: any) => fg2.lastModified - fg1.lastModified)
        .map(
          async (fg: any): Promise<SocialMediaReachReport> => {
            const url = await getUrl({
              key: fg.key,
              options: {
                expiresIn: 900,
              }
            });

            const fileName = fg.key.replace('social-media-reach-reports/', '');

            const result: SocialMediaReachReport = {
              url: url.toString(),
              lastModified: getDateSlashes(fg.lastModified),
              fileName,
            };

            const tagset = await S3StorageObject.getObjectTagging(
              fg.key,
              S3ServiceObject,
            );

            const talentID: S3.Tag | undefined = tagset.find(
              tag => tag.Key === 'talentID',
            );

            if (talentID) {
              return {
                ...result,
                talentID: +talentID.Value,
              };
            }

            return result;
          },
        ),
    );
  } catch (error) {
    window.Rollbar.error('Could not fetch social media reach export files:', {
      error,
    });

    return [];
  }
};
