import React, { ComponentType, lazy, Suspense } from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

import Loading from '../ui/Loading/Loading';

import AdminRoute from './AdminRoute';
import AuthedRoute from './AuthedRoute';
import UnauthedRoute from './UnauthedRoute';
import { WithApolloClient } from '@apollo/client/react/hoc';
import NewLookAlikeSearch from 'pages/TalentDiscovery/LookAlike/NewLookAlikeSearch';
import LookAlikeResult from 'pages/TalentDiscovery/LookAlike/LookAlikeResult';

const Error404 = lazy(
  (): Promise<{ default: ComponentType<{}> }> =>
    import('../pages/Error404/Error404'),
);
const ForgotPassword = lazy(
  (): Promise<{ default: ComponentType<{}> }> =>
    import('../pages/ForgotPassword/ForgotPassword'),
);

const AdminTalentsHome = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<WithApolloClient<any>>;
  }> => import('../pages/AdminHome/AdminHome'),
);

const Home = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<any>;
  }> => import('../pages/Home/HomeContainer'),
);
const Login = lazy(
  (): Promise<{ default: ComponentType<{}> }> => import('../pages/Login/Login'),
);

const LoggingIn = lazy(
  (): Promise<{
    default: ComponentType<any>;
  }> => import('../pages/LoggingIn/LoggingIn'),
);

const ResetPassword = lazy(
  (): Promise<{ default: ComponentType<{}> }> =>
    import('../pages/ResetPassword/ResetPassword'),
);

const ViewCohort = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<any>;
  }> => import('../pages/ViewCohort/ViewCohort'),
);

const LoggingOut = lazy(
  (): Promise<{
    default: ComponentType<{}>;
  }> => import('../pages/LoggingOut/LoggingOut'),
);

const OrganizationAccounts = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<WithApolloClient<any>>;
  }> => import('../pages/OrganizationAccounts/OrganizationAccounts'),
);

const SingleTalentAccounts = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<WithApolloClient<any>>;
  }> => import('../pages/OrganizationAccounts/SingleTalentAccounts'),
);

const TalentAdminSignup = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<WithApolloClient<any>>;
  }> => import('../pages/TalentAdminSignup/TalentAdminSignup'),
);

const TalentAdmins = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<WithApolloClient<any>>;
  }> => import('../pages/TalentAdmins/TalentAdmins'),
);

const OrganizationRequests = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<WithApolloClient<any>>;
  }> => import('../pages/OrganizationRequests/OrganizationRequests'),
);

const CohortTalents = lazy(
  (): Promise<{
    default: ComponentType<WithApolloClient<any>>;
  }> => import('../pages/Cohorts/CohortTalents'),
);

const CreateCohort = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<WithApolloClient<any>>;
  }> => import('../pages/CreateCohort/CreateCohort'),
);

const OrganizationSignup = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<WithApolloClient<any>>;
  }> => import('../pages/OrganizationSignup/OrganizationSignup'),
);

const Cohorts = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<WithApolloClient<any>>;
  }> => import('../pages/Cohorts/Cohorts'),
);

const AllBehaviors = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<WithApolloClient<any>>;
  }> => import('../pages/CreateCohort/FiltersTab/AllBehaviors'),
);

const ReportingDashboard = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<any>;
  }> => import('../pages/ReportingDashboard/ReportingDashboard'),
);

const EmailsDrillIn = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<any>;
  }> => import('../pages/ReportingDashboard/EmailDrillIn/EmailsDrillin'),
);

const KeychainEmailDrillIn = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<any>;
  }> =>
    import(
      '../pages/ReportingDashboard/KeychainEmailDrillIn/KeychainEmailDrillIn'
    ),
);

const UploadedEmailDrillIn = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<any>;
  }> =>
    import(
      '../pages/ReportingDashboard/UploadedEmailDrillIn/UploadedEmailDrillIn'
    ),
);

const ProfilesDrillIn = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<any>;
  }> => import('../pages/ReportingDashboard/ProfilesDrillIn/ProfilesDrillin'),
);

const ActivityLog = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<any>;
  }> => import('../pages/ActivityLog/ActivityLog'),
);

const AllCaptureLinks = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<any>;
  }> => import('../pages/AllCaptureLinks/AllCaptureLinks'),
);

const ViewSegment = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<any>;
  }> => import('../pages/ViewSegment/ViewSegment'),
);

const Segments = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<WithApolloClient<any>>;
  }> => import('../pages/Segments/Segments'),
);


const CreateSegment = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<WithApolloClient<any>>;
  }> => import('../pages/Segments/CreateSegment'),
);

const TalentDiscovery = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<WithApolloClient<any>>;
  }> => import('../pages/TalentDiscovery/TalentDiscovery'),
);


const routing = (): WithApolloClient<JSX.Element> => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <UnauthedRoute exact path="/talent/signup" component={Login} />
        <UnauthedRoute exact path="/admin/signup" component={Login} />
        <UnauthedRoute exact path="/manager/signup" component={Login} />
        <UnauthedRoute exact path="/login" component={Login} />
        <Route exact path="/login/auth" component={LoggingIn} />
        <UnauthedRoute
          exact
          path="/forgot-password"
          component={ForgotPassword}
        />
        <Route exact path="/logout" component={LoggingOut} />
        <UnauthedRoute exact path="/reset-password" component={ResetPassword} />
        <AdminRoute exact path="/cohorts/:cohortID" component={ViewCohort} />
        <AdminRoute exact path="/talents" component={AdminTalentsHome} />
        <AdminRoute path="/organizations" component={OrganizationAccounts} />
        <AdminRoute path="/singletalents" component={SingleTalentAccounts} />
        <AdminRoute path="/talentadmin/signup" component={TalentAdminSignup} />
        <AdminRoute
          path="/:organizationName/talentadmins"
          component={TalentAdmins}
        />
        <AdminRoute
          path="/organization/requests"
          component={OrganizationRequests}
        />
        <AdminRoute path="/cohorts/:id/talents" component={CohortTalents} />
        <AdminRoute path="/cohorts/:id/edit" component={CreateCohort} />
        <AdminRoute
          path="/organization/signup"
          component={OrganizationSignup}
        />
        <AdminRoute path="/cohorts" component={Cohorts} />
        <AdminRoute path="/create/cohort" component={CreateCohort} />
        <AdminRoute path="/create/cohort-behaviors" component={AllBehaviors} />
        <AdminRoute
          exact
          path="/reporting-dashboard"
          component={ReportingDashboard}
        />
        <AdminRoute
          path="/reporting-dashboard/emails"
          component={EmailsDrillIn}
        />
        <AdminRoute
          path="/reporting-dashboard/profiles"
          component={ProfilesDrillIn}
        />
        <AdminRoute
          path="/reporting-dashboard/keychain-emails"
          component={KeychainEmailDrillIn}
        />
        <AdminRoute
          path="/reporting-dashboard/uploaded-emails"
          component={UploadedEmailDrillIn}
        />
        <AdminRoute path="/activity-log" component={ActivityLog} />

        <AdminRoute path="/all-capture-links" component={AllCaptureLinks} />
        <AdminRoute path="/segments/:id" component={ViewSegment} />
        <AdminRoute path="/segments" component={Segments} />
        <AdminRoute path="/talent-discovery/talent-lookalike/new" component={NewLookAlikeSearch} />
        <AdminRoute path="/talent-discovery/talent-lookalike/:id" component={LookAlikeResult} />
        <AdminRoute path="/talent-discovery" component={TalentDiscovery} />
        <AdminRoute path="/create-segment" component={CreateSegment} />
        <AuthedRoute exact path="/" component={Home} />
        <Route component={Error404} />
      </Switch>
    </Suspense>
  );
};

export default routing;
