import numeral from 'numeral';

export const doubleToInt = (value: number): number => value | 0;

export const formatMetricCurrency = (num: number): string =>
  numeral(num).format('$0,0.[00]');

export const largeNumberFormatter = (
  number: number,
  options?: { upperCase?: boolean },
): string => {
  if (!number) {
    return '0';
  } else if (number > 1000) {
    const formattedNumber = new Intl.NumberFormat('en', {
      //@ts-ignore
      notation: 'compact',
      maximumFractionDigits: 1,
    }).format(number);

    if (options?.upperCase) {
      return formattedNumber;
    }

    return formattedNumber.toLowerCase();
  } else return number.toLocaleString();
};
