import React, { createContext, useContext } from 'react';

interface LookAlikeSettings {
    showAdditionalInfo: boolean;
    setShowAdditionalInfo: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LookAlikeSettingContext = createContext<LookAlikeSettings | undefined>(undefined);

export const useLookAlikeSettings = () => {
    const context = useContext(LookAlikeSettingContext);
    if (!context) {
        throw new Error('useLookAlikeSettings must be used within a PageSettingsProvider');
    }
    return context;
};