// eslint-disable-next-line import/named
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { ImageBGDivProps } from '../interfaces/props';

type TBreakpoints = {
  phoneOnly: (args: TemplateStringsArray) => FlattenSimpleInterpolation;
  tabletOnly: (args: TemplateStringsArray) => FlattenSimpleInterpolation;
  tabletAndAbove: (args: TemplateStringsArray) => FlattenSimpleInterpolation;
  tabletAndBelow: (args: TemplateStringsArray) => FlattenSimpleInterpolation;
  desktopOnly: (args: TemplateStringsArray) => FlattenSimpleInterpolation;
};

const sizes = {
  phoneOnly: 'max-width: 480px',
  tabletOnly: 'min-width: 481px) and (max-width: 1024px',
  tabletAndAbove: 'min-width: 481px',
  tabletAndBelow: 'max-width: 1024px',
  desktopOnly: 'min-width: 1025px',
};

export const breakpoints: TBreakpoints = Object.keys(sizes).reduce(
  (acc, label): TBreakpoints => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    acc[label] = (...args: [any]): FlattenSimpleInterpolation => css`
      @media only screen and (${sizes[label]}) {
        ${css(...args)};
      }
    `;

    return acc as TBreakpoints;
  },
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as TBreakpoints,
);

export const ImageDiv = styled.div<ImageBGDivProps>`
background-image: url("${(props): string => props.image}");
background-size: cover;
`;

export const ImageDivEmpty = styled.div<ImageBGDivProps>`
background-image: url("${(props): string => props.image}");
background-size: cover;
background-color: rgba(255, 242, 123, 0.25);
border: 2px solid rgba(255, 242, 123, 1);
`;
