import loadImage from 'blueimp-load-image/js';
import Resizer from 'react-image-file-resizer';

import { uploadImage } from './storage';

export type TImageCompressionDetails = {
  height: number;
  width: number;
  quality: 50 | 60 | 70 | 80 | 90 | 100;
  fileType: 'jpg' | 'png' | 'webp';
};

export const convertBlobToFile = (blob: Blob, file?: File): File => {
  const fileName = file ? file.name : 'image';

  return new File([blob], fileName, { ...(file || { type: 'image/jpeg' }) });
};

const blobURLs = new WeakMap<Blob, string>();

export const getBlobURL = (blob: Blob): string => {
  if (blobURLs.has(blob)) {
    return blobURLs.get(blob) || '';
  }

  const url = URL.createObjectURL(blob);

  blobURLs.set(blob, url);

  return url;
};

export const getBlobUrlWithCorrectedOrientation = (
  fileOrBlob: Blob,
  cb: (blobUrl: string) => void,
): void => {
  if (blobURLs.has(fileOrBlob)) {
    cb(blobURLs.get(fileOrBlob) || '');
  }

  loadImage(
    fileOrBlob,
    (img, data): void => {
      if (data && data.imageHead && data.exif) {
        loadImage.writeExifData(data.imageHead, data, 'Orientation', 1);

        img.toBlob((blob): void => {
          loadImage.replaceHead(blob, data.imageHead, (newBlob): void => {
            const url = URL.createObjectURL(newBlob);

            blobURLs.set(blob, url);
            cb(url);
          });
        }, 'image/jpeg');
      } else {
        const url = URL.createObjectURL(fileOrBlob);

        blobURLs.set(fileOrBlob, url);
        cb(url);
      }
    },
    { meta: true, orientation: true, canvas: true },
  );
};

export const cleanupBlobURL = (blob: Blob): void => {
  let url: string;

  if (blobURLs.has(blob)) {
    url = blobURLs.get(blob) || '';

    URL.revokeObjectURL(url);
    blobURLs.delete(blob);
  }
};

const roundForImage = (value: number, min = 1): number =>
  Math.max(min, Math.floor(value));

export const getCroppedImg = async (
  image: HTMLImageElement | undefined,
  { height = 0, width = 0, x = 0, y = 0 },
  fileToCrop: File,
): Promise<File | null> => {
  if (!image) return null;

  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const originWidth = width * scaleX;
  const originHeight = height * scaleY;
  const maxWidth = 1200;
  const maxHeight = 1200 / (16 / 10);
  let targetWidth = originWidth;
  let targetHeight = originHeight;

  if (originWidth > maxWidth || originHeight > maxHeight) {
    if (originWidth / originHeight > maxWidth / maxHeight) {
      targetWidth = maxWidth;
      targetHeight = roundForImage(maxWidth * (originHeight / originWidth));
    } else {
      targetHeight = maxHeight;
      targetWidth = roundForImage(maxHeight * (originWidth / originHeight));
    }
  }

  canvas.width = targetWidth;
  canvas.height = targetHeight;

  const ctx = canvas.getContext('2d');

  if (!ctx) return null;

  try {
    ctx.drawImage(
      image,
      roundForImage(x * scaleX),
      roundForImage(y * scaleY),
      roundForImage(width * scaleX),
      roundForImage(height * scaleY),
      0,
      0,
      roundForImage(targetWidth),
      roundForImage(targetHeight),
    );
  } catch (error) {
    window.Rollbar.error('Failed to drawImage', { error });

    return null;
  }

  return new Promise((resolve): void => {
    canvas.toBlob(
      (blob): void => {
        resolve(blob ? convertBlobToFile(blob, fileToCrop) : null);
      },
      fileToCrop.type,
      1,
    );
  });
};

export const getImageExtension = (file: File): 'jpg' | 'png' | null => {
  const JPEGs = /jpeg|jpg/i;
  const PNGs = /png/i;
  const { name, type } = file;

  if (JPEGs.test(type)) return 'jpg';

  if (PNGs.test(type)) return 'png';

  if (JPEGs.test(name)) return 'jpg';

  if (PNGs.test(name)) return 'png';

  return null;
};

export const validateImageType = (file: File): boolean => {
  return !!getImageExtension(file);
};

export const getExtensionFromName = (name: string): string => {
  return name.substring(name.lastIndexOf('.') + 1);
};

export const getImageURL = (path: string, fileExtension: string): string =>
  `${path}.${fileExtension}`;

const resizeImage = (
  file: File,
  compressionDetails: TImageCompressionDetails,
): Promise<string | File | Blob | ProgressEvent<FileReader>> =>
  new Promise(resolve => {
    const { height, width, quality, fileType } = compressionDetails;

    let compressFormat = fileType.toUpperCase();

    if (compressFormat === 'JPG') {
      compressFormat = 'JPEG';
    }

    Resizer.imageFileResizer(
      file,
      width,
      height,
      compressFormat,
      quality,
      0,
      uri => {
        resolve(uri);
      },
      'file',
    );
  });

export const compressAndUploadImage = async (
  path: string,
  image: File,
  compressionDetails: TImageCompressionDetails,
  progressCallback?: (percentage: number, name: string) => void,
): Promise<string> => {
  const resizedImage = await resizeImage(image, compressionDetails);

  const url = await uploadImage(path, resizedImage as File, progressCallback);

  return url;
};
