import {
  addHours,
  addMinutes,
  addMonths,
  differenceInCalendarDays,
  differenceInSeconds,
  format,
  formatDistanceToNow,
  getHours,
  getMinutes,
  getSeconds,
  isAfter,
  isBefore,
  parse,
  parseISO,
  roundToNearestMinutes,
} from 'date-fns';

import {
  MILLISECONDS_IN_A_SECOND,
  MINUTES_IN_AN_HOUR,
  SECONDS_IN_A_MINUTE,
} from './constants';

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getDateFromTS = (ts: string, formatString?: string): Date =>
  parse(
    `${ts}Z`,
    formatString ? formatString : 'yyyy-MM-dd HH:mm:ssX',
    new Date(),
  );

export const formatDate = (ts: string): string => {
  const date = getDateFromTS(ts);

  return format(date, `MM/dd/yyyy`);
};

export const formatDateAndTime = (
  ts: string,
  formatString?: string,
): string => {
  const date = getDateFromTS(ts, formatString);

  return format(date, formatString ? formatString : `MM/dd/yyyy HH:mma`);
};

export const getDateForPickers = (ts: string): string =>
  format(new Date(`${ts}Z`), 'MMMM d, yyyy hh:mm a');

export const getDateFromTSWithSafariSupport = (ts: string): Date =>
  new Date(ts.replace(/-/g, '/'));

export const getDateSlashes = (dt: Date): string => {
  return format(dt, `MM/dd/yyyy HH:mm:ss`);
};

export const getSQLDateTime = (dt: Date): string => {
  const timezoneOffset =
    dt.getTimezoneOffset() * SECONDS_IN_A_MINUTE * MILLISECONDS_IN_A_SECOND;
  const correctedDate = new Date(dt.getTime() + timezoneOffset);

  return format(correctedDate, `yyyy-MM-dd HH:mm:ss`);
};

export const getDateFromSQLDateTime = (SQLdateTime: string): Date => {
  const date = new Date(getDateFromTSWithSafariSupport(SQLdateTime));

  const timezoneOffset =
    date.getTimezoneOffset() * SECONDS_IN_A_MINUTE * MILLISECONDS_IN_A_SECOND;

  return new Date(date.getTime() - timezoneOffset);
};

export const getDateFromTSSlashes = (ts: string): string => {
  const dt = getDateFromTS(ts);

  return getDateSlashes(dt);
};

export const addDaysToDate = (days: number, date: Date): Date => {
  const otherDate = new Date(date.getTime());

  otherDate.setDate(date.getDate() + days);

  return otherDate;
};

export const addMinutesToDate = (minutes: number, date: Date): Date => {
  const otherDate = new Date(date.getTime());

  otherDate.setMinutes(date.getMinutes() + minutes);

  return otherDate;
};

export const getUSDate = (dt: Date): string => {
  return format(new Date(dt), 'MM/dd/yyyy');
};

export const getUSTime = (dt: Date): string => {
  return format(new Date(dt), 'h:mm a');
};

export const formatUSDate = (ts: string): string =>
  format(getDateFromTS(ts), 'MM/dd/yy');

export const formatTS = (ts: string): string =>
  format(getDateFromTS(ts), `h:mm a MM/dd/yy`);

export const getDateFormattedForCalendar = (
  ts: string,
  offset?: number,
): string => {
  let dt = getDateFromTS(ts);

  if (offset) dt = addHours(dt, offset);

  return format(dt, "yyyyMMdd'T'HHmmss");
};

export const getDateShort = (ts: string): string =>
  format(getDateFromTS(ts), 'MMMM dd');

export const getDateShortWithSuffix = (ts: string): string =>
  format(getDateFromTS(ts), 'MMMM do');

export const formatToMMDDYYYY = (date: string): string => {
  let parts: string[] = [];

  if (date) parts = date.split('-');

  let result = '';

  if (parts.length > 0 && parts.length < 4) {
    result = `${parts[1]}/${parts[2]}/${parts[0]}`;
  }

  return result;
};

export const isBeforeEndDate = (endTs: string): boolean => {
  const now = new Date();
  const end = getDateFromTS(endTs);

  return isBefore(end, now);
};

export const getHumanTimeToDate = (ts: string): string =>
  formatDistanceToNow(getDateFromTS(ts));

const normalizeTime = (time: number | string): string =>
  `${time}`.length === 1 ? `0${time}` : `${time}`;

export const getDuration = (
  start: Date,
  end: Date,
  withOffset = true,
): Date => {
  const diffInSeconds = differenceInSeconds(end, start);
  const milliseconds = diffInSeconds * MILLISECONDS_IN_A_SECOND;
  const date = new Date(milliseconds);

  if (!withOffset) return date;

  const timezoneDiff = date.getTimezoneOffset() / MINUTES_IN_AN_HOUR;

  return addHours(date, timezoneDiff);
};

export const formatDurationWithHours = (interval: Date): string => {
  const hours = getHours(interval);
  const minutes = normalizeTime(getMinutes(interval));
  const seconds = normalizeTime(getSeconds(interval));

  return `${hours}:${minutes}:${seconds}`;
};

interface TimeZoneData {
  abbreviation: string;
  location: string;
  name?: string;
  offset: number;
}

export const getTimeZoneInfo = (): TimeZoneData => {
  const date = new Date();
  const offset = date.getTimezoneOffset() * -1;
  const location = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const time = date.toTimeString();
  const timeParts = time.match(/\(([^)]+)\)/i);
  const result: TimeZoneData = { abbreviation: '', location, offset };

  if (!timeParts) return result;

  const name = timeParts[1];

  if (name.search(/\W/) >= 0) {
    const timeZoneMatch = name.match(/\b\w/g);

    if (timeZoneMatch) {
      result.abbreviation = timeZoneMatch.join('').toUpperCase();
    }

    result.name = name;
  } else {
    result.abbreviation = name;
  }

  return result;
};

export const getNextInterval = (
  d: Date | string | null | undefined,
  interval: number,
  minutesToAdd?: number,
): Date => {
  let now = new Date();
  let date: Date;

  if (d == null) {
    d = now;
  } else if (typeof d === 'string') {
    d = parseISO(d);
  }

  if (minutesToAdd) {
    d = addMinutes(d, minutesToAdd);
    now = addMinutes(now, minutesToAdd);
  }

  date = roundToNearestMinutes(d, { nearestTo: interval });

  if (isAfter(now, date)) date = addMinutes(date, interval);

  return date;
};

export const getDayDifferenceFromNow = (ts: string): number =>
  differenceInCalendarDays(new Date(), getDateFromTS(ts));

export const getHoursAndMinutesFromTS = (ts: string): string => {
  return format(getDateFromTS(ts), `h:mm a`);
};

export const getIsAfterCurrentTimestamp = (dateTS: string): boolean => {
  const date = getDateFromTS(dateTS);

  return isAfter(new Date(), date);
};

export const getNextFullSubscription = (months: number): string => {
  const nextMonths = addMonths(new Date(), months);

  return nextMonths.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
};

export const getLastThreeMonths = (): string[] => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const today = new Date();
  const lastThreeMonths: string[] = [];
  let currentMonth = today.getMonth() - 2;
  let count = 3;

  while (count > 0) {
    if (currentMonth < 0) currentMonth += 12;

    if (currentMonth >= 12) currentMonth -= 12;

    lastThreeMonths.push(monthNames[currentMonth]);
    currentMonth++;
    count--;
  }

  return lastThreeMonths;
};

/**
 * Returns the latest date that a person could be born on and have the provided age
 * @param {number} age
 * @param {number} [refDate] - Date to be taken as reference instead of the current date
 * @returns {string} The date in the format %m/%d/%Y, e.g. 3/10/2022
 */
export const getDateFromAge = (age: number, refDate?: Date): string => {
  const date = refDate ? new Date(refDate) : new Date();

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  date.setFullYear(year - age, month, day);

  return date.toLocaleDateString();
};

export const getDateDifference = (dt1, dt2) => {
  const oneDay = 86400000;

  return Math.round(Math.abs((dt1 - dt2) / oneDay));
};

export const getTimeStampOnly = (dt: Date): string => {
  const hours = normalizeTime(getHours(dt));
  const minutes = normalizeTime(getMinutes(dt));
  const seconds = normalizeTime(getSeconds(dt));

  return `${hours}:${minutes}:${seconds}`;
};

export const padZero = num => {
  return num < 10 ? `0${num}` : num;
};

export const formatTime = (hours, minutes, seconds) => {
  // Format the time as "HH:mm:ss"
  const formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(
    seconds,
  )}`;

  return formattedTime;
};

export const formatDateTime = (ts: string): string => {
  const date = getDateFromTS(ts);

  return format(date, `MM/dd/yyyy HH:mm:ss`);
};

export const formatFullDate = dateString => {
  const inputDate = new Date(dateString);
  const currentDate = new Date();

  // Check if the input date is today
  if (
    inputDate.getDate() === currentDate.getDate() &&
    inputDate.getMonth() === currentDate.getMonth() &&
    inputDate.getFullYear() === currentDate.getFullYear()
  ) {
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
    const seconds = inputDate.getSeconds();

    return `Today ${formatTime(hours, minutes, seconds)}`;
  }

  // Calculate yesterday's date
  const yesterday = new Date(currentDate);

  yesterday.setDate(currentDate.getDate() - 1);

  // Check if the input date is yesterday
  if (
    inputDate.getDate() === yesterday.getDate() &&
    inputDate.getMonth() === yesterday.getMonth() &&
    inputDate.getFullYear() === yesterday.getFullYear()
  ) {
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
    const seconds = inputDate.getSeconds();

    return `Yesterday ${formatTime(hours, minutes, seconds)}`;
  }

  return formatDateTime(dateString);
};

export const secondsToMinutesAndSeconds = seconds => {
  // Calculate minutes and remaining seconds
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Add leading zero if needed
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

  // Combine minutes and seconds with ':' separator
  const formattedTime = `${formattedMinutes}:${formattedSeconds}`;

  return formattedTime;
};
