import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import React, { FC } from 'react';

import { displayToastMessage } from '../../utils/notifications';

interface QueryErrorProps {
  defaultMessage?: string;
  error?: ApolloError | GraphQLError;
  parentName: string;
  privateMessage?: string;
  hideToast?: boolean;
}

const QueryError: FC<QueryErrorProps> = ({
  defaultMessage = 'Something went wrong, please refresh the page and try again',
  error,
  parentName,
  privateMessage,
  hideToast,
}): JSX.Element => {
  if (!hideToast) {
    displayToastMessage(defaultMessage, { type: 'error' });
  }

  window.Rollbar.error(`From ${parentName}`, { error });
  console.error({ error, parentName, privateMessage });

  if (privateMessage) {
    window.Rollbar.error(`From ${parentName}`, privateMessage);
  }

  return <></>;
};

export default QueryError;
